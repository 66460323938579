<template>

  <skeleton-loading>
    <div class='border-card p-3 mb-3 '>
      <skeleton-square
        :box-properties="{
            height: '160px',
          }"
        :count='1'
      ></skeleton-square>

      <skeleton-square
        :box-properties="{
            height: '16px',
            top:'10px'
          }"
        :count='2'
      ></skeleton-square>
      <div class='d-flex mt-2 skeleton-course-container'>
        <div>
          <skeleton-square
            :box-properties="{
            height: '16px',
            width: '70px',
          }"
            :count='1'
          ></skeleton-square>
        </div>
        <div>
          <skeleton-square
            :box-properties="{
            height: '16px',
            width: '70px',
          }"
            :count='1'
          ></skeleton-square>
        </div>

      </div>
    </div>
  </skeleton-loading>
</template>

<script>
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';

export default {
  name: 'SkeletonAdminCourse',
  components: {
    SkeletonLoading,
    SkeletonSquare,
  },
};
</script>

<style scoped>
.border-card {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
}
.skeleton-course-container{
  justify-content: space-between;
}
</style>
