<template>
  <div class='row'>
    <div class='col-md-6'>
      <div class='form-group row mb-0'>
        <div class='col-md-12'>
          <label class='style-text-config'>Título</label>
          <base-input
            v-model='formData.title'
            class='name-course-input border-name'
            placeholder='Titulo de la lección'
          >
            <template #infoBlock>
              <small
                id='emailHelp'
                class='form-text text-muted'
              >{{ formData.title.length }} caracteres</small
              >
            </template>
          </base-input>
        </div>
      </div>
      <div class='form-group row mb-0'>
        <div class='col-md-12'>
          <label class='style-text-config'>Descripción</label>
          <base-input
            v-model='formData.description'
            class='name-course-input border-name'
            placeholder='Titulo de la lección'
          >
            <template #infoBlock>
              <small
                id='emailHelp'
                class='form-text text-muted'
              >{{ formData.description.length }} caracteres</small
              >
            </template>
          </base-input>
        </div>
      </div>
      <div class='form-group row mb'>
        <div class='col-md-12'>
          <label class='style-text-config'>Palabra de enfoque</label>
          <voerro-tags-input
            v-model='formData.keyPhrases'
            placeholder='Presione enter para agregar'
            wrapper-class='form-control tags-input-seo'
          />
        </div>
      </div>
      <base-button
        class='primary btn-maquillate py-2 px-3'
        type='primary'
        :loading='isLoading'
        :disabled='disabled'
        @click='handleClick'
      >
        Guardar
      </base-button>
    </div>
    <div class='col-md-6'>
      <div
        class='mb-2 text-error'
        :class="{ 'text-success': formData.title.length }"
      >
        Debe ingresar el titulo
      </div>
      <div
        class='mb-2 text-error'
        :class="{
          'text-success':
            formData.title.length >= 40 && formData.title.length <= 60,
        }"
      >
        El titulo debe tener al menos de 40 a 60 caracteres
      </div>
      <div
        class='mb-2 text-error'
        :class="{ 'text-success': formData.description.length }"
      >
        Debe ingresar la descripción
      </div>
      <div
        class='mb-2 text-error'
        :class="{
          'text-success':
            formData.description.length >= 80 &&
            formData.description.length <= 150,
        }"
      >
        La descripción debe tener al menos de 80 a 150 caracteres
      </div>
      <div
        class='mb-2 text-error'
        :class="{ 'text-success': formData.keyPhrases.length }"
      >
        Debe ingresar palabra de enfoque
      </div>
      <div
        class='mb-2 text-error'
        :class="{
          'text-success':
            formData.keyPhrases.length >= 1 && formData.keyPhrases.length <= 4,
        }"
      >
        Debe ingresar palabra de enfoque máximo de 4 palabras
      </div>
      <div
        class='mb-2 text-error'
        :class="{ 'text-success': checkTitleKeyPhrases() }"
      >
        Debe ingresar palabra de enfoque en el titulo
      </div>
      <div
        class='mb-2 text-error'
        :class="{ 'text-success': checkDescriptionKeyPhrases() }"
      >
        Debe ingresar palabra de enfoque en la descripción
      </div>
    </div>
  </div>
</template>

<script>
import VoerroTagsInput from '@voerro/vue-tagsinput';

export default {
  name: 'SeoView',
  components: {
    VoerroTagsInput,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    seoData: {
      type: Object,
      default: () => ({
        id: '',
        title: '',
        description: '',
        keyPhrases: [],
        titleKeyPhrases: [],
        descriptionKeyPhrases: [],
      }),
    },
  },
  emits: ['update-seo', 'create-seo'],
  data () {
    return {
      formData: {
        id: '',
        title: '',
        description: '',
        keyPhrases: [],
        titleKeyPhrases: [],
        descriptionKeyPhrases: [],
      },
    };
  },
  computed: {
    disabled: function () {
      return this.handleValidate();
    },
  },
  watch: {
    seoData: function (params) {
      if (params) {
        this.formData = {
          ...params,
        };
      }
    },
  },
  methods: {
    handleClick () {
      if (this.formData.id) {
        this.$emit('update-seo', this.formData);
      } else {
        this.$emit('create-seo', this.formData);
      }
    },
    handleValidate () {
      return !(
        this.formData.title.length &&
        this.formData.title.length >= 40 &&
        this.formData.title.length <= 60 &&
        this.formData.description.length &&
        this.formData.description.length >= 80 &&
        this.formData.description.length <= 150 &&
        this.formData.keyPhrases.length &&
        this.formData.keyPhrases.length >= 1 &&
        this.formData.keyPhrases.length <= 4 &&
        this.checkTitleKeyPhrases() &&
        this.checkDescriptionKeyPhrases()
      );
    },
    checkTitleKeyPhrases () {
      let isInclude = false;
      for (let i = 0; i < this.formData.keyPhrases.length; i++) {
        if (this.formData.title.toLowerCase().includes(this.formData.keyPhrases[i].value.toLowerCase())) {
          isInclude = true;
        }
      }

      return isInclude;
    },
    checkDescriptionKeyPhrases () {
      let isInclude = false;
      for (let i = 0; i < this.formData.keyPhrases.length; i++) {
        if (
          this.formData.description.toLowerCase().includes(this.formData.keyPhrases[i].value.toLowerCase())
        ) {
          isInclude = true;
        }
      }
      return isInclude;
    },
  },
};
</script>

<style>
.tags-input-seo {
  font-size: 1rem;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  height: auto;
}

.tags-input-seo > input {
  border: none !important;
  width: 100%;
}

.text-error {
  color: #cc0000;
}
</style>
