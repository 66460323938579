<template>
  <md-dialog :md-active="open" class="modalEdit__instructors">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">EDITAR INSTRUCTOR</h3>
        <h4 class="text-bold mb-3">INFORMACIÓN DEL INSTRUCTOR</h4>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row">
          <div class="col-md-6">
            <base-input
              v-model="formData.firstname"
              name="nombre"
              placeholder="Nombre"
              type="text"
            ></base-input>
          </div>

          <div class="col-md-6">
            <base-input
              v-model="formData.lastname"
              name="apellido"
              placeholder="Apellido"
              type="text"
            ></base-input>
          </div>
          <div class="col-md-6">
            <!-- <i class="fab fa-facebook input-icon-social-media"></i> -->
            <base-input
              v-model="formData.facebook"
              class="input-social-media"
              name="facebook"
              placeholder="Facebook"
              type="text"
            ></base-input>
          </div>

          <div class="col-md-6">
            <!-- <i class="fab fa-instagram input-icon-social-media"></i> -->
            <base-input
              v-model="formData.instagram"
              class="input-social-media"
              name="instagram"
              placeholder="Instagram"
              type="text"
            ></base-input>
          </div>
          <div class="col-md-6">
            <!-- <i class="fab fa-facebook input-icon-social-media"></i> -->
            <base-input
              v-model="formData.whatsapp"
              class="input-social-media"
              name="whatsapp"
              placeholder="Whatsapp"
              type="text"
            ></base-input>
          </div>

          <div class="col-md-6">
            <!-- <i class="fab fa-instagram input-icon-social-media"></i> -->
            <base-input
              v-model="formData.website"
              name="website"
              placeholder="Website"
              type="text"
            ></base-input>
          </div>
        </div>
        <div class="row mb-4 mt-3">
          <div class="col-md-12">
            <!--Select Countries-->
            <multiselect
              v-model="formData.country"
              :close-on-select="true"
              :options="getAllCountries"
              :searchable="true"
              :show-labels="false"
              class="select-danger details-country"
              label="name"
              placeholder="Selecciona un pais"
              track-by="id"
            >
              <template #option="props">
                <div class="option__desc">
                  <span class="option__title">{{
                    `(${props.option.code}) ${props.option.name}`
                  }}</span>
                  <span
                    v-if="
                      props.values &&
                      props.values.length &&
                      !props.values.isOpen
                    "
                    class="multiselect__single"
                    >{{ props.values.length }} options selected</span
                  >
                </div>
              </template>
              <template #noResult>
                <div>Sin resultados</div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <textarea
              id="exampleFormControlTextarea3"
              v-model="formData.bio"
              class="form-control"
              placeholder="Biografía del instructor"
              rows="4"
              style=""
            ></textarea>
          </div>
        </div>
        <h4 class="mt-4 text-bold text-left">
          FOTO DEL ENCABEZADO DEL INSTRUCTOR
        </h4>
        <p class="text-muted">
          Para el mejor resultado, usar imagen rectangular 6720 x 2801 px.
        </p>

        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
            <div class="imageContainer">
              <div class="dropzone-profile-instructor seo-instructor">
                <md-field
                  class="pt-0 d-flex"
                  style="margin: 0; justify-content: flex-start"
                >
                  <md-file
                    :style="{
                      backgroundImage: `url('${formData.poster.url}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }"
                    accept="image/*"
                    class="design-dropzone instructor-modal col-12 px-0 border-0"
                    @md-change="changePoster($event)"
                  />

                  <div class="textUploadImage">
                    <p>Sube la imagen del encabezado</p>
                  </div>
                </md-field>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <h4 class="pt-4 text-bold text-left">
              FOTO DEL PERFIL DEL INSTRUCTOR
            </h4>

            <div class="imageContainer">
              <div class="dropzone-profile-instructor seo-instructor">
                <md-field
                  class="pt-0 d-flex"
                  style="margin: 0; justify-content: flex-start"
                >
                  <md-file
                    :style="{
                      backgroundImage: `url('${formData.avatar.url}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }"
                    accept="image/*"
                    class="design-dropzone instructor-modal col-12 px-0 border-0"
                    @md-change="changeAvatar($event)"
                  />

                  <div class="textUploadImage">
                    <p>Sube la imagen principal</p>
                  </div>
                </md-field>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="text-left pt-4">
              <h4 class="text-bold">FOTO PRINCIPAL SEO</h4>
            </div>
            <div class="imageContainer">
              <div class="dropzone-profile-instructor seo-instructor">
                <md-field
                  class="pt-0 d-flex"
                  style="margin: 0; justify-content: flex-start"
                >
                  <md-file
                    :style="{
                      backgroundImage: `url('${formData.seoPoster.url}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }"
                    accept="image/*"
                    class="design-dropzone instructor-modal col-12 px-0 border-0"
                    @md-change="changeSeoPoster($event)"
                  />

                  <div class="textUploadImage">
                    <p>Sube la imagen principal</p>
                  </div>
                </md-field>
              </div>
            </div>
          </div>

          <div class="col-12">
            <p class="text-muted pt-4">
              Para el mejor resultado, usar imagen cuadrada máxima de 1000 x
              1199 px.
            </p>
          </div>
        </div>

        <div class="text-left mt-3">
          <h4 class="text-bold mb-0">TITULO SEO</h4>
        </div>
        <div class="col-md-12 px-0">
          <base-input
            v-model="formData.seoTitle"
            name="name"
            placeholder="Coloca el Titulo"
          ></base-input>
        </div>
        <div class="text-left mt-3">
          <h4 class="text-bold mb-0">DESCRIPCIÓN SEO</h4>
        </div>
        <div class="col-md-12 px-0">
          <textarea
            v-model="formData.seoDescription"
            class="form-control mt-3"
            placeholder="Descripción"
            rows="10"
          ></textarea>
        </div>
      </div>
      <md-dialog-actions
        class="modal-footer"
        style="flex-direction: inherit !important"
      >
        <base-button
          class="md-primary w-auto"
          type="secondary"
          @click="closeModal"
          >CANCELAR
        </base-button>
        <base-button
          :loading="isLoading"
          class="md-primary w-auto"
          type="primary"
          @click="updateCourseInstructor"
          >ACTUALIZAR INSTRUCTOR
        </base-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_INSTRUCTOR } from '../../../../store/actions/instructor';
import Multiselect from 'vue-multiselect';
import validator from 'validator';

export default {
  name: 'UpdateCourseInstructorDialog',
  components: {
    Multiselect,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedCourseInstructor: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      isLoading: false,
      formData: {
        firstname: '',
        bio: '',
        lastname: '',
        facebook: '',
        instagram: '',
        whatsapp: '',
        website: '',
        seoTitle: '',
        seoDescription: '',
        avatar: {
          url: '',
        },
        poster: {
          url: '',
        },
        seoPoster: {
          url: '',
        },

        country: '',
      },

      poster: undefined,
      avatar: undefined,
      seoPoster: undefined,
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
    ...mapGetters(['getAllCountries']),
  },
  watch: {
    selectedCourseInstructor: function (newValue) {
      if (newValue)
        this.formData = {
          id: newValue.id,
          firstname: newValue.firstname ?? '',
          bio: newValue.bio ?? '',
          lastname: newValue.lastname ?? '',

          facebook: newValue.facebook ?? '',
          instagram: newValue.instagram ?? '',
          whatsapp: newValue.whatsapp ?? '',
          website: newValue.website ?? '',
          seoTitle: newValue.seo_title ?? '',
          seoDescription: newValue.seo_description ?? '',
          avatar: {
            url:
              newValue.avatar && newValue.avatar.url
                ? newValue.avatar.url
                : require('@/assets/images/logo/placeholder.png'),
          },
          poster: {
            url:
              newValue.poster && newValue.poster.url
                ? newValue.poster.url
                : require('@/assets/images/logo/placeholder.png'),
          },
          seoPoster: {
            url:
              newValue.seo_poster && newValue.seo_poster.url
                ? newValue.seo_poster.url
                : require('@/assets/images/logo/placeholder.png'),
          },
          country: newValue.country ?? '',
        };
      else this.reset();
    },
  },

  methods: {
    validateCreateCourseInstructor() {
      const errors = [];

      if (!this.formData.firstname) {
        errors.push('El nombre es requerido');
      }

      if (!this.formData.lastname) {
        errors.push('El apellido es requerido');
      }

      if (!this.formData.country) {
        errors.push('El país es requerido');
      }

      if (
        this.formData.facebook &&
        !validator.default.isURL(this.formData.facebook)
      )
        errors.push('La direccion de facebook no es validado');

      if (
        this.formData.website &&
        !validator.default.isURL(this.formData.website)
      )
        errors.push('La direccion de la pagina web no es validada');

      if (
        this.formData.instagram &&
        !validator.default.isURL(this.formData.instagram)
      )
        errors.push('La direccion de instagram no es validada');

      if (!(this.formData.country && this.formData.country.id))
        errors.push('El pais es requerido');

      return errors;
    },
    updateCourseInstructor() {
      const validations = this.validateCreateCourseInstructor();
      console.log('validations', validations);

      if (validations.length) {
        this.notify('danger', validations.join('</br>'));
        return;
      }

      this.isLoading = true;

      const data = new FormData();
      Object.keys(this.formData).forEach((key) => {
        if (key !== 'seoDescription' || key !== 'seoTitle') {
          data.append(key, this.formData[key]);
        }
      });
      data.append('seo_description', this.formData.seoDescription);
      data.append('seo_title', this.formData.seoTitle);
      data.append('country_id', this.formData.country.id);
      if (this.avatar) {
        data.append('avatar', this.avatar);
      }

      if (this.poster) {
        data.append('poster', this.poster);
      }

      if (this.seoPoster) {
        data.append('seo_poster', this.seoPoster);
      }

      this.$store
        .dispatch(UPDATE_INSTRUCTOR, data)
        .then(() => {
          this.$emit('success');
          this.notify(
            'success',
            'Se ha actualizado el instructor correctamente',
          );
          this.isLoading = false;
          this.closeModal();
        })
        .catch(() => {
          this.isLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar el instructor',
          );
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    reset() {
      this.formData = {
        firstname: '',
        bio: '',
        lastname: '',
        facebook: '',
        instagram: '',
        whatsapp: '',
        website: '',
        seoTitle: '',
        seoDescription: '',
        avatar: {
          url: '',
        },
        poster: {
          url: '',
        },
        seoPoster: {
          url: '',
        },
        country: '',
      };
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    changePoster(files) {
      console.log('changePoster: ', files);
      this.formData.poster.url = URL.createObjectURL(files[0]);
      this.poster = files[0];
    },
    changeAvatar(files) {
      console.log('changeAvatar: ', URL.createObjectURL(files[0]));
      this.formData.avatar.url = URL.createObjectURL(files[0]);
      this.avatar = files[0];
    },
    changeSeoPoster(files) {
      this.formData.seoPoster.url = URL.createObjectURL(files[0]);
      this.seoPoster = files[0];
    },
  },
};
</script>

<style scoped>
.seo-instructor .md-field .md-file {
  width: 100%;
  min-height: 300px;
  cursor: pointer;
}

.imageContainer img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  align-items: center;
}

.multiselect__option--highlight {
  background: #000;
  outline: none;
  outline: none;
  color: #fff !important;
}

.multiselect {
  outline: none;
  border: 0px;
  border-radius: 5px;
}
</style>
