<template>
  <md-dialog :md-active="isOpen" class="modalEdit__instructors">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeDialog"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">EDITAR VIDEO</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="col-md-12 px-0">
          <base-input v-model="video.title"></base-input>
        </div>
        <h4 class="mt-4 text-bold text-left">FOTO DEL VIDEO</h4>
        <div class="dropzone-header-instructor">
          <md-field class="pt-0">
            <md-file
              :style="{
                backgroundImage: `url('${video.thumbnail}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                borderRadius: '0.25rem',
              }"
              accept="image/*"
              class="design-dropzone"
              @md-change="changeThumbnail($event)"
            />
          </md-field>
        </div>
        <div>
          <h4 class="mt-4 text-bold text-left">VIDEO</h4>
          <div class="progress progress-show-courses">
            <div
              v-if="this.progress > 0 && this.progress < 100"
              :aria-valuenow="this.progress"
              :style="{ width: this.progress + '%' }"
              aria-valuemax="100"
              aria-valuemin="0"
              class="progress-bar"
              role="progressbar"
            ></div>
            <div
              v-else-if="this.progress === 100 || this.progress === 0"
              :aria-valuenow="'0'"
              :style="{ width: '0%' }"
              aria-valuemax="100"
              aria-valuemin="0"
              class="progress-bar"
              role="progressbar"
            ></div>
          </div>
          <vue-dropzone
            id="updateVideoDropzone"
            ref="updateVideoDropzone"
            :options="dropzoneOptions"
            @vdropzone-success="successUpload"
          ></vue-dropzone>
        </div>
        <md-dialog-actions
          class="modal-footer"
          style="flex-flow: inherit !important"
        >
          <base-button
            class="md-primary w-auto"
            type="secondary"
            @click="closeDialog"
            >CANCELAR
          </base-button>
          <base-button
            :loading="isLoading"
            class="md-primary w-auto"
            type="primary"
            @click="updateVideo"
            >ACTUALIZAR
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import config from '@/config/config';
import vue2Dropzone from 'vue2-dropzone';
import { DEFAULT_VIDEO_VALUES } from '../../../../util/constants';
import { UPDATE_VIDEO } from '../../../../store/actions/video';

export default {
  name: 'UpdateVideoDialog',

  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    selectedVideo: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success', 'close-dialog'],
  data() {
    return {
      isLoading: false,
      progress: 0,
      video: DEFAULT_VIDEO_VALUES,
      dropzoneOptions: {
        url: `${config.data.apiUrl}/admin/video/upload/${this.selectedVideo.id}`,
        thumbnailWidth: 150,
        maxFilesize: 9999999,
        timeout: 9999999,
        addRemoveLinks: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
        },
      },
    };
  },
  mounted() {
    this.video = this.selectedVideo ?? DEFAULT_VIDEO_VALUES;
  },
  updated() {
    this.video = this.selectedVideo ?? DEFAULT_VIDEO_VALUES;
  },
  methods: {
    successUpload() {
      this.$refs.updateVideoDropzone.removeAllFiles();
    },

    closeDialog() {
      this.$emit('close-dialog');
    },
    updateVideo() {
      const data = {
        title: this.video.title,
        id: this.video.id,
      };

      if (this.file) {
        data.file = this.file;
      }

      const formData = new FormData();

      Object.keys(data).map((key) => {
        formData.append(key, data[key]);
      });

      this.isLoading = true;

      this.$store
        .dispatch(UPDATE_VIDEO, formData)
        .then(() => {
          this.notify('success', 'Se video se actualizo correctamente!');
          this.$emit('success');
          this.isLoading = false;
          this.closeDialog();
        })
        .catch(() => {
          this.notify('success', 'Tenemos problema para actualizar el video');
          this.isLoading = false;
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    changeThumbnail(files) {
      this.video.thumbnail = URL.createObjectURL(files[0]);
      this.file = files[0];
    },
  },
};
</script>

<style scoped></style>
