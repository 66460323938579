<template>
  <div class="auth-layout-container bg-default">
    <div class="main-content login-app">
      <div class="auth-super-container container">
        <div class="row justify-content-center">
          <div class="xs-size">
            <div class="auth-container">
              <div class="auth-container__logo">
                <router-link :to="'/'">
                  <img
                    :src="
                      require(`@/assets/images/logo/logo-maquillate-white.png`)
                    "
                    alt="Maquillate logo"
                    class=""
                  />
                </router-link>
              </div>

              <div class="bg-white mt-4 rounded mb-0 auth-container__form">
                <div class="card-body">
                  <div class="text-center text-muted mb-0">
                    <div class="auth-container__form__title text-default">
                      <h2 class="text-default">INGRESE A SU CUENTA</h2>
                    </div>
                    <div class="auth-container__form__description text-default">
                      <h3 class="text-default">
                        Inicia sesión ingresando tu información
                      </h3>
                    </div>
                  </div>
                  <validation-observer
                    ref="formValidator"
                    v-slot="{ handleSubmit }"
                  >
                    <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                      <md-field class="mb-0">
                        <label class="auth-container__label"
                          >Correo electrónico</label
                        >
                        <md-input
                          v-model="model.email"
                          :rules="{ email: true }"
                          alternative
                          class="border-color"
                          name="Email"
                        ></md-input>
                      </md-field>

                      <md-field class="mt-0">
                        <label class="auth-container__label">Contraseña</label>
                        <md-input
                          v-model="model.password"
                          alternative
                          class="border-color"
                          name="Password"
                          type="password"
                        ></md-input>
                      </md-field>

                      <div class="row forgotpassword">
                        <div class="remember-user col-6">
                          <base-switch
v-model="model.remember_me"
type="dark"
                            >Recuerdame
                          </base-switch>
                          <span class="text-default">Recuerdame</span>
                        </div>
                        <div class="col-6 remember-user">
                          <router-link to="/recordar" class="forgot-password">
                            <div class="col-12">
                              <span class="text-default"
                                >Contraseña olvidada</span
                              >
                            </div>
                          </router-link>
                        </div>
                        <div class="col-12">
                          <div class="text-center">
                            <base-button
                              block
                              :loading="isLoading"
                              class="mt-4"
                              native-type="submit"
                              type="dark"
                              >Continuar
                            </base-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </validation-observer>
                </div>
              </div>

              <div>
                <div class="col-12 text-center text-white mt-3">
                  <router-link class="auth-link text-white" to="/register">
                    <small class="text-white"
                      >¿No tienes cuenta? Crea tu cuenta</small
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AUTH_REQUEST } from '@/store/actions/login';
import { mapGetters } from 'vuex';
import BaseButton from '../../components/BaseButton';

export default {
  name: 'LogIn',
  components: {
    BaseButton,
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        remember_me: false,
      },
      isLoading: false,
      error: false,
    };
  },
  metaInfo() {
    return {
      title:  'Inicia sesión - Maquillate.com'
    }
  },
  computed: {
    ...mapGetters(['getCart', 'getProfile', 'getProfile']),
  },
  methods: {
    onSubmit() {
      this.isLoading = true;

      let data = {
        email: this.model.email,
        password: this.model.password,
      };
      this.$store
        .dispatch(AUTH_REQUEST, data)
        .then(() => {
          const urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has('redirect_url')) {
            window.location = urlParams.get('redirect_url');
          } else {
            window.location = window.location.origin;
          }
        })
        .catch(() => {
          this.error = true;
          this.notify(
            'El correo o la contraseña ingresados son incorrectos.',
            'danger',
          );
        })
        .finally(() => (this.isLoading = false));
    },
    notify(message, type) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style scoped>
.forgotpassword {
  font-size: 12px;
}

.auth-layout-container {
  background: #000;
}
.auth-layout-container .md-field .md-input,
.auth-layout-container .md-field .md-textarea,
.auth-layout-container .select-country {
  box-shadow: none !important;
}
.auth-layout-container .md-field .md-input,
.auth-layout-container .md-field .md-textarea,
.auth-layout-container .border-color {
  border: 1px solid #d4d4d4 !important;
}

.md-field .md-input,
.md-field .md-input:focus,
.md-field .md-textarea,
.md-field .md-textarea:focus,
.border-color {
  border: 1px solid #d4d4d4 !important;
}

</style>
