<template>
  <md-dialog :md-active="open" class="modalEdit__instructors modal-categories">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">CREAR PRODUCTO</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row">
          <div class="col-md-6">
            <base-input
              v-model="formData.title"
              name="name"
              placeholder="Coloca el nombre"
            ></base-input>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <currency-input
                v-model="formData.price"
                currency="USD"
                locale="en"
                placeholder="Precio (Opcional)"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12">
            <base-input
              v-model="formData.url"
              name="name"
              placeholder="Url para comprar el producto"
              requeried
            ></base-input>
          </div>
          <div class="form-group col-md-12 mb-2">
            <textarea
              v-model="formData.description"
              class="form-control"
              name="description"
              placeholder="Coloca la Descripción"
            ></textarea>
          </div>
          <div class="col-12 my-2 text-left">
            <label class="style-text-config">Categoria</label>

            <multiselect
              v-model="formData.categories"
              :loading="isLoadingCategories"
              :multiple="true"
              :option-height="104"
              :options="getProductsCategories.items"
              :show-labels="true"
              :taggable="true"
              class="border-0 select-config"
              label="title"
              placeholder="Categorías"
              track-by="id"
            >
              <template #option="props">
                <div class="option__desc">
                  <span class="option__title">{{ props.option.title }}</span>
                  <span
                    v-if="
                      props.values &&
                      props.values.length &&
                      !props.values.isOpen
                    "
                    class="multiselect__single"
                    >{{ props.values.length }} options selected</span
                  >
                </div>
              </template>
              <template #noOptions>La lista esta vacía</template>
            </multiselect>
          </div>
        </div>
        <h4 class="mt-4 text-bold text-left">FOTO DEL PRODUCTO</h4>
        <p class="text-muted">
          Para el mejor resultado, usar imagen rectangular 1000 x 1000 px.
        </p>
        <md-field class="d-none">
          <label>Sube imagen del producto</label>
          <input
            id="product-file-input"
            accept="image/*"
            type="file"
            @change="uploadProductImage"
          />
        </md-field>

        <div class="imageContainer mb-4 mt-2">
          <img
            v-if="this.formData.poster.url"
            :src="this.formData.poster.url"
            alt="productImage"
            class="imageToUpload"
          />
          <img
            v-else
            alt="productImage"
            class="imageToUpload"
            src="@/assets/images/logo/placeholder.png"
          />
          <div class="textUploadImage" @click="openProductFileInput">
            <p>Sube la imagen</p>
          </div>
        </div>
        <md-dialog-actions
          class="modal-footer"
          style="flex-flow: inherit !important"
        >
          <base-button
            class="md-primary"
            style="width: 150px"
            @click="closeModal"
            >CANCELAR
          </base-button>
          <base-button
            :loading="isLoading"
            class="md-primary"
            style="width: 150px"
            @click="createProduct"
            >CREAR
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import {
  CREATE_PRODUCT,
  FETCH_PRODUCT_CATEGORIES,
} from '../../../../store/actions/products';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import BaseButton from '../../../../components/BaseButton';

export default {
  name: 'CreateProductDialog',
  components: {
    Multiselect,
    BaseButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      formData: {
        title: '',
        price: '',
        url: '',
        poster: {
          url: '',
        },
        categories: [],
      },
      productImageFile: undefined,
      isLoading: false,
      isLoadingCategories: false,
    };
  },
  computed: {
    open: {
      get: function () {
        return this.isOpen;
      },
      set: function () {
        this.closeModal();
      },
    },
    ...mapGetters(['getProductsCategories']),
  },
  async mounted() {
    await this.fetchProductCategories();
  },
  methods: {
    validateCreateProduct() {
      const errors = [];

      if (!this.formData.title) {
        errors.push('El titulo es requerido');
      }

      return errors;
    },
    async fetchProductCategories(
      filter = { per_page: 12, search: this.searchValue },
    ) {
      await this.$store.dispatch(FETCH_PRODUCT_CATEGORIES, filter);
      this.isLoadingCategories = false;
    },
    createProduct() {
      const data = new FormData();
      data.append('title', this.formData.title);
      data.append('price', this.formData.price);
      data.append('url', this.formData.url);
      data.append('description', this.formData.description);
      data.append(
        'categories',
        JSON.stringify(this.formData.categories.map((category) => category.id)),
      );
      const validations = this.validateCreateProduct();
      if (validations.length) {
        this.notify('danger', validations.join('</br>'));
        return;
      }

      if (this.productImageFile) {
        data.append('poster', this.productImageFile);
      }
      this.isLoading = true;
      this.$store
        .dispatch(CREATE_PRODUCT, data)
        .then(() => {
          this.isLoading = false;
          this.$emit('success');
          this.notify('success', 'Se ha creado el producto correctamente');
          this.closeModal();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para crear el producto');
          this.isLoading = false;
        });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    uploadProductImage(event) {
      const files = event.target.files;
      this.formData.poster.url = URL.createObjectURL(files[0]);
      this.productImageFile = files[0];
    },
    openProductFileInput() {
      document.getElementById('product-file-input').click();
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    reset() {
      this.formData = {
        title: '',
        price: 0,
        url: '',
        poster: {
          url: '',
        },
        categories: [],
      };
      this.productImageFile = undefined;
    },
  },
};
</script>

<style scoped></style>
