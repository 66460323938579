<template>
  <md-dialog :md-active="open" class="modalEdit__instructors">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">PREVIEW VIDEO</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div v-if="selectedVideo.url">
          <div class="w-100">
            <video-player ref="previewplayer" :options="videoOptions" />
          </div>
        </div>
        <md-dialog-actions class="modal-footer">
          <base-button
            class="md-primary w-auto"
            type="primary"
            @click="closeModal"
            >CERRAR
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import VideoPlayer from '../../../../components/VideoPlayer';
import { DEFAULT_VIDEO_VALUES } from '../../../../util/constants';

export default {
  name: 'PreviewVideoDialog',
  components: {
    VideoPlayer,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
    selectedVideo: {
      type: Object,
      default: DEFAULT_VIDEO_VALUES,
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      videoOptions: {},
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  watch: {
    isOpen: function (newValue) {
      if (newValue) {
        this.videoOptions = {
          autoplay: false,
          controls: true,
          muted: false,
          sources: [
            {
              withCredentials: false,
              type: 'application/x-mpegURL',
              src:
                this.selectedVideo && this.selectedVideo.url
                  ? this.selectedVideo.url
                  : '',
            },
          ],
          //playbackRates: [1, 1.5, 2],
          fluid: true,
          aspectRatio: '16:9',
          poster: this.selectedVideo.thumbnail,
          hls: true,
          plugins: {
            airPlay: {
              addButtonToControlBar: true, // defaults to `true`
            },
          },
        };
      } else {
        this.videoOptions = {};
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped></style>
