<template>
    <md-table-row
      class='container-categories__cell pt-2 pb-2'
    >
      <md-table-cell class='skeleton-category-name-cell'>
        <skeleton-loading>
          <div class='d-flex'>
            <div class='mr-2'>
              <skeleton-circle
                :box-properties="{
                  height: '50px',
                  width:'50px'
                  }"
                :count='1'
              />
            </div>
            <div class='mt-3'>
              <skeleton-square
                :box-properties="{
                  height: '20px',
                  width:'200px'
                  }"
                :count='1'
              />
            </div>
          </div>
        </skeleton-loading>
  
  
      </md-table-cell>
      <md-table-cell>
        <skeleton-loading>
          <skeleton-square
            :box-properties="{
                  height: '20px',
                  width:'60px'
                  }"
            :count='1'
          />
        </skeleton-loading>
      </md-table-cell>
      <md-table-cell>
        <skeleton-loading>
          <skeleton-square
            :box-properties="{
                  height: '20px',
                  width:'100px'
                  }"
            :count='1'
          />
        </skeleton-loading>
  
      </md-table-cell>
      <md-table-cell class='skeleton-category-action-cell'>
        <skeleton-loading>
          <skeleton-circle
            :box-properties="{
                  height: '20px',
                  width:'20px'
                  }"
            :count='1'
          />
        </skeleton-loading>
      </md-table-cell>
    </md-table-row>
  </template>
  
  <script>
  import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
  import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
  import SkeletonCircle from '../../../components/Skeleton/components/SkeletonCircle';
  
  export default {
    name: 'SkeletonCategoryCourse',
    components: {
      SkeletonLoading,
      SkeletonSquare,
      SkeletonCircle,
    },
  };
  </script>
  
  <style scoped>
  .skeleton-category-name-cell {
    max-width: 450px;
    width: 450px;
  }
  
  .skeleton-category-action-cell {
    text-align: -webkit-right;
  }
  </style>
  