<template>

  <skeleton-loading>
    <div class='single-chapter row mb-3 p-3'>
      <div class='col-12 chapter-title handle px-0'>
        <div class='col-2 icon-section pl-0'>

          <skeleton-circle
            :box-properties="{
                height: '20px',
                width:'20px'
                }"
            :count='1'
          />
        </div>
        <div class='col-10 px-0'>
          <div class='chapter-top'>
            <skeleton-square
              :box-properties="{
                height: '20px',
                width:'350px'
                }"
              :count='1'
            />
            <div class='right-icons'>
              <div
                class='fast-buttons mr-3'
              >
                <skeleton-circle
                  :box-properties="{
                height: '20px',
                width:'20px'
                }"
                  :count='1'
                />
              </div>
              <div
                class='fast-buttons mr-3'
              >
                <skeleton-circle
                  :box-properties="{
                height: '20px',
                width:'20px'
                }"
                  :count='1'
                />
              </div>
              <div
                class='fast-buttons mr-3'
              >
                <skeleton-circle
                  :box-properties="{
                height: '20px',
                width:'20px'
                }"
                  :count='1'
                />
              </div>
              <div
                class='fast-buttons mr-3'
              >
                <!-- <i class="fas fa-chevron-right"></i> -->
                <skeleton-circle
                  :box-properties="{
                height: '20px',
                width:'20px'
                }"
                  :count='1'
                />
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </skeleton-loading>
</template>

<script>

import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
import SkeletonCircle from '../../../components/Skeleton/components/SkeletonCircle';

export default {
  name: 'SkeletonCourseVideo',
  components: {
    SkeletonLoading,
    SkeletonSquare,
    SkeletonCircle,
  },
};
</script>

<style scoped>

</style>
