<template>
  <div class="container-fluid main-container__librery">
    <div v-if="getCurrentCourse" class="float-button pr-5">
      <a :href="`/admin/cursos/curso/preview/${getCurrentCourse.id}`">
        <i class="far fa-eye"></i>
      </a>
    </div>
    <div class="container-styles p-3">
      <vue-tabs>
        <v-tab title="VIDEOS">
          <div class="mt-4">
            <div class="row course-container">
              <div class="col-12 chapter-section">
                <div class="chapters-container">
                  <div v-if="isLoading">
                    <skeleton-course-video v-for="index in 4" :key="index" />
                  </div>
                  <draggable
                    v-else
                    v-bind="dragOptions"
                    :group="{ name: 'courseVideos' }"
                    handle=".handle"
                    :force-fallback="true"
                    @end="onEnd"
                    @start="drag = true"
                  >
                    <transition-group
                      :name="!drag ? 'flip-list' : null"
                      type="transition"
                    >
                      <div
                        v-for="element in this.getCurrentCourseVideos"
                        :key="element.position"
                        :class="{
                          activebox:
                            getCourseVideo &&
                            element.position === getCourseVideo.position,
                        }"
                        :data-box="element.position"
                        :data-id="element.id"
                        class="single-chapter row mb-3 p-3"
                      >
                        <div
                          class="col-2 icon-section pl-0 chapter-title handle px-0"
                        >
                          <i class="fas fa-grip-horizontal"></i>
                        </div>
                        <div class="col-10 align-self-center">
                          <div class="chapter-top">
                            <h2
                              class="mb-0 mx-3 tab-title-video"
                              @click.prevent="
                                isOpenCourseVideoForm &&
                                getCourseVideo &&
                                element.position === getCourseVideo.position
                                  ? closeCourseVideoForm(element)
                                  : openCourseVideoForm(element)
                              "
                            >
                              {{ element.title.toUpperCase() || 'Nuevo video' }}
                            </h2>

                            <div class="right-icons">
                              <div class="fast-buttons mr-3">
                                <i
                                  class="fas fa-chart-line"
                                  @click="openCourseVideoSeo(element)"
                                ></i>
                              </div>
                              <div class="fast-buttons mr-3">
                                <i
                                  v-if="element.featured"
                                  class="fas fa-star color-yellow"
                                  @click="changeCourseVideoFeatured(element)"
                                ></i>
                                <i
                                  v-else
                                  class="far fa-star color-yellow"
                                  @click="changeCourseVideoFeatured(element)"
                                ></i>
                              </div>

                              <div
                                class="fast-buttons mr-3"
                                @click="openDeleteCourseModal(element)"
                              >
                                <i class="fas fa-trash"></i>
                              </div>
                              <div
                                class="fast-buttons mr-3"
                                @click.stop="duplicateCourseVideo(element)"
                              >
                                <i class="far fa-copy"></i>
                              </div>

                              <div
                                v-if="
                                  isOpenCourseVideoForm &&
                                  getCourseVideo &&
                                  element.position === getCourseVideo.position
                                "
                                class="fast-buttons"
                                @click.prevent="closeCourseVideoForm(element)"
                              >
                                <!-- <i class="fas fa-chevron-right"></i> -->
                                <i
                                  :data-id="element.position"
                                  class="fas fa-angle-up"
                                ></i>
                              </div>
                              <div
                                v-else
                                @click.prevent="openCourseVideoForm(element)"
                              >
                                <i
                                  :data-id="element.position"
                                  class="fas fas fa-angle-down"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div class="chapter-bot">
                            <div
                              v-if="
                                getCourseVideo &&
                                getCourseVideo.position === element.position
                              "
                              class="col-12 edit-section mx-0"
                            >
                              <video-form
                                :course-video="getCourseVideo"
                                @success="refetchCourse"
                              ></video-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                </div>

                <base-button
                  :loading="isCreateCourseVideo"
                  class="primary btn-maquillate py-2 px-3"
                  type="primary"
                  @click="createCourseVideo"
                >
                  Agregar video
                </base-button>
              </div>
            </div>
          </div>
        </v-tab>

        <v-tab title="Configuración">
          <div class="mt-2">
            <div class="course-configuration">
              <course-config-form></course-config-form>
            </div>
          </div>
        </v-tab>
        <v-tab title="SEO">
          <seo-view
            :seo-data="seoCourse"
            :is-loading="isSeoLoading"
            @create-seo="createSeo"
            @update-seo="updateSeo"
          />
        </v-tab>
      </vue-tabs>
    </div>

    <delete-dialog
      :is-open="isOpenDeleteCourseModal"
      @deleted="deleteCourseVideo"
      @close-modal="closeDeleteCourseModal"
    />
    <seo-dialog
      :is-open="isOpenCourseVideoSeoDialog"
      :seo-data="selectedCourseVideoSeo"
      :is-loading="isCourseVideoSeoLoading"
      @update-seo="updateCourseVideoSeo"
      @create-seo="createCourseVideoSeo"
      @close-modal="closeCourseVideoSeo"
    />
  </div>
</template>

<script>
import SeoDialog from './Seo/components/SeoDialog.vue';
//local registration
import { VueTabs, VTab } from 'vue-nav-tabs';
import draggable from 'vuedraggable';
//you can also import this in your style tag
import 'vue-nav-tabs/themes/vue-tabs.css';

import { mapGetters } from 'vuex';

import DeleteDialog from './components/DeleteDialog';
import SeoView from './Seo/SeoView.vue';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { FETCH_COURSE } from '../../../store/actions/course';
import SkeletonCourseVideo from '../../Components/skeletons/SkeletonCourseVideo';
import {
  CHANGE_COURSE_VIDEO_FEATURE,
  CLOSE_COURSE_VIDEO_FORM,
  CREATE_COURSE_VIDEO,
  DELETE_COURSE_VIDEO,
  OPEN_COURSE_VIDEO_FORM, UPDATE_COURSE_VIDEO,
} from '../../../store/actions/courseVideo';
import { CREATE_SEO, UPDATE_SEO } from '../../../store/actions/seo';

export default {
  name: 'AdminCourseDetails',
  components: {
    VueTabs,
    VTab,
    draggable,
    SkeletonCourseVideo,
    DeleteDialog,
    SeoView,
    SeoDialog,
    //quillEditor,
  },
  data() {
    return {
      isOpenDeleteCourseModal: false,
      isLoading: true,
      isCreateCourseVideo: false,
      selectedDeleteCourse: undefined,
      seoCourse: undefined,
      isSeoLoading: false,
      isCourseVideoSeoLoading: false,
      drag: false,
      drag2: false,
      selectedCourseVideoSeo: undefined,
      isOpenCourseVideoSeoDialog: false,
      selectedCourseVideo: undefined,
    };
  },
  computed: {
    ...mapGetters([
      'getInstructors',
      'getProducts',
      'getCurrentCourse',
      'getCurrentCourseVideos',
      'getCourseVideo',
      'isOpenCourseVideoForm',
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  watch: {
    getCurrentCourse: function (params) {
      if (params && params.seo) {
        this.seoCourse = params.seo;
      }
    },
  },
  async mounted() {
    await this.fetchCourse();
    this.isLoading = false;
  },
  methods: {

    /* SEO DATA */
    createSeo(data) {
      this.isSeoLoading = true;
      this.$store
        .dispatch(CREATE_SEO, {
          seo: {
            ...data,
          },
          entity: {
            relation: 'course',
            relation_id: this.$route.params.id,
          },
        })
        .then(() => {
          this.isSeoLoading = false;
          this.fetchCourse();
          this.notify(
            'success',
            'Se ha actualizado la información SEO del curso',
          );
          ``;
        })
        .catch(() => {
          this.isSeoLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la información SEO del curso',
          );
        });
    },

    updateSeo(data) {
      this.isSeoLoading = true;
      this.$store
        .dispatch(UPDATE_SEO, {
          ...data,
        })
        .then(() => {
          this.isSeoLoading = false;
          this.fetchCourse();
          this.notify(
            'success',
            'Se ha actualizado la información SEO del curso',
          );
        })
        .catch(() => {
          this.isSeoLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la información SEO del curso',
          );
        });
    },
    openCourseVideoSeo(courseVideo) {
      this.selectedCourseVideo = courseVideo;
      this.selectedCourseVideoSeo = courseVideo.seo;
      this.isOpenCourseVideoSeoDialog = true;
    },
    closeCourseVideoSeo() {
      this.selectedCourseVideoSeo = undefined;
      this.isOpenCourseVideoSeoDialog = false;
    },

    updateCourseVideoSeo(data) {
      this.isCourseVideoSeoLoading = true;
      this.$store
        .dispatch(UPDATE_SEO, {
          ...data,
        })
        .then(() => {
          this.isCourseVideoSeoLoading = false;
          this.closeCourseVideoSeo();
          this.fetchCourse();
          this.notify(
            'success',
            'Se ha actualizado la información SEO del video',
          );
        })
        .catch(() => {
          this.isSeoLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la información SEO del video',
          );
        });
    },
    createCourseVideoSeo(data) {
      this.isCourseVideoSeoLoading = true;
      this.$store
        .dispatch(CREATE_SEO, {
          seo: {
            ...data,
          },
          entity: {
            relation: 'course_video',
            relation_id: this.selectedCourseVideo.id,
          },
        })
        .then(() => {
          this.isCourseVideoSeoLoading = false;
          this.closeCourseVideoSeo();
          this.fetchCourse();
          this.notify(
            'success',
            'Se ha actualizado la información SEO del video',
          );
          ``;
        })
        .catch(() => {
          this.isCourseVideoSeoLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la información SEO del video',
          );
        });
    },

    /*COURSE ACTIONS*/
    async fetchCourse() {
      this.isLoading = true;
      await this.$store.dispatch(FETCH_COURSE, this.$route.params.id);
      this.isLoading = false;
    },

    async createCourseVideo() {
      this.isCreateCourseVideo = true;
      const data = {
        course_id: this.$route.params.id,
        title: 'nuevo video',
        content: '',
        position: this.getCurrentCourseVideos.length + 1,
      };

      try {
        await this.$store.dispatch(CREATE_COURSE_VIDEO, data);
        this.isLoading = true;
        this.isCreateCourseVideo = false;
        this.notify('success', 'Video creado con éxito!');
      } catch (err) {
        return this.notify('danger', err);
      }

      try {
        await this.fetchCourse();
        this.isLoading = false;
      } catch (err) {
        return this.notify('danger', err);
      }
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    async updateCourseVideo(video) {
      delete video.video;
      delete video.seo;
      delete video.files;
      await this.$store.dispatch(UPDATE_COURSE_VIDEO, video)

    },
    async onEnd(value) {
      const oldVideo = this.getCurrentCourseVideos[value.oldIndex];
      const newVideo = this.getCurrentCourseVideos[value.newIndex];

      await this.updateCourseVideo({ ...oldVideo,position:newVideo.position })
      await this.updateCourseVideo({ ...newVideo,position:oldVideo.position })
      this.notify('success', 'Se ha actualizado la posición de los videos con éxito');

      this.drag = false;
    },
    async refetchCourse() {
      this.closeCourseVideoForm();
      await this.fetchCourse();
    },
    openCourseVideoForm(courseVideo) {
      this.$store.dispatch(OPEN_COURSE_VIDEO_FORM, courseVideo);
    },
    closeCourseVideoForm() {
      this.$store.dispatch(CLOSE_COURSE_VIDEO_FORM);
    },
    openDeleteCourseModal(courseVideo) {
      this.selectedDeleteCourse = courseVideo;
      this.isOpenDeleteCourseModal = true;
    },
    closeDeleteCourseModal() {
      this.selectedDeleteCourse = undefined;
      this.isOpenDeleteCourseModal = false;
    },

    async duplicateCourseVideo(courseVideo) {
      const oldCourse = courseVideo;
      delete oldCourse.id;
      oldCourse.position = this.getCurrentCourseVideos.length + 1;

      try {
        await this.$store.dispatch(CREATE_COURSE_VIDEO, oldCourse);
        this.isLoading = true;
        this.notify('success', 'Video creado con éxito!');
      } catch (err) {
        return this.notify('danger', err);
      }

      try {
        await this.fetchCourse();
        this.isLoading = false;
      } catch (err) {
        return this.notify('danger', err);
      }
    },

    async deleteCourseVideo() {
      try {
        await this.$store.dispatch(
          DELETE_COURSE_VIDEO,
          this.selectedDeleteCourse.id,
        );
        this.isLoading = true;
        this.notify('success', 'Se ha eliminado el video con éxito');
        this.closeDeleteCourseModal();
      } catch (e) {
        return this.notify('danger', 'Tenemos problemas al eliminar el curso');
      }

      try {
        await this.fetchCourse();
        this.isLoading = false;
      } catch (err) {
        return this.notify('danger', err);
      }
    },
    changeCourseVideoFeatured(courseVideo) {
      const featured = !courseVideo.featured;

      this.$store
        .dispatch(CHANGE_COURSE_VIDEO_FEATURE, {
          id: courseVideo.id,
          featured: !courseVideo.featured,
        })
        .then(() => {
          if (featured) {
            this.notify(
              'success',
              `Sea asigno el video ${courseVideo.title} como destacado`,
            );
          } else {
            this.notify(
              'success',
              `Sea removido el video ${courseVideo.title} como destacado`,
            );
          }
          this.fetchCourse();
        })
        .catch(() => {
          this.notify(
            'danger',
            'Tenemos problemas para asignar/remover el video como destacado',
          );
        });
    },
  },
};
</script>

<style lang="scss">
.chapter-section {
  width: 100%;
}

.chapter-section .chapters-container .single-chapter {
  border: 1px solid #e1e1e1;
  padding: 12px;
  cursor: pointer;
  margin: 8px 0;
  border-radius: 3px;
}

.chapter-section .chapters-container .single-chapter:hover {
  box-shadow: none;
}

.icon-section {
  display: flex;
  align-items: flex-start;
  justify-content: start;
}

.sorteable-chosen {
  border: 3px dotted #000;
}

.chapter-title i,
.chapter-title h2 {
  font-size: 18px;
}

.chapter-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* edit-section
*/

.chapter-title {
  height: 40px;
  display: flex;
  align-items: center;
}

.edit-section .button-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.delete-container {
  display: flex;
  align-items: center;
}

.hide {
  display: none !important;
}

/*
* Lesson grid
*/
.lessons-type {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  padding: 20px 0 5px 0;
}

.grid-single {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.grid-single:hover {
  background: #eee;
}

.grid-single:hover i,
.grid-single:hover span {
  color: #333;
}

.lessons-box {
  margin: 15px 0;
}

.lessons-container {
  border: 3px dotted #ccc;
  padding: 12px;
  cursor: pointer;
  margin: 8px 0;
}

.lesson-top h2 {
  font-size: 16px;
  margin: 0;
}

.container-courses {
  max-width: 1300px;
  margin: auto;
  position: relative;
}

/* Buttons */
.right-icons {
  display: flex;
  align-items: center;
}

.lessonIcons {
  display: flex;
  align-items: center;
}

.lessonIcons > div {
  margin: 0 8px;
}

.float-button {
  float: right;
  padding: 26px 15px 0;

  a {
    i {
      font-size: 26px;
      color: #b7227e;
    }
  }
}

.container-styles {
  background: white;
  border-radius: 4px;
}

.fa-angle-double-left {
  font-size: 22px;
  color: #b7227e;
}

.style-button {
  background: #b7227e;
  color: #fff;
  font-weight: 500;
  padding: 10px 13px;
  font-size: 13px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  border-color: #b7227e;
}

.style-button:hover {
  background: #b7227e;
  color: #fff;
  border-color: #b7227e;
}

.vue-tabs .nav-tabs > li.active > a,
.vue-tabs .nav-tabs > li.active > a:hover,
.vue-tabs .nav-tabs > li.active > a:focus {
  background-color: #fff;
  border: none;
  cursor: default;
  border-bottom: 4px solid #000;
  color: #000;
}

.vue-tabs .nav-tabs > li {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.vue-tabs .nav-tabs > li > a {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid white;
}

.vue-tabs .nav > li > a:hover,
.vue-tabs .nav > li > a:focus {
  background-color: transparent;
  border-bottom: 4px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
}

.vue-tabs .nav-tabs {
  border-bottom: none;
}

.color-yellow {
  color: #f7d917;
}

.fast-buttons {
  color: #000;
}

.fa-angle-down {
  color: #000;
}

.tab-title-video {
  height: 40px !important;
  align-items: center !important;
  line-height: 2;
}
</style>
