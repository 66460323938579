<template>
 <a :href="data.url" target="_blank">
    <div class="card-course-width mx-auto mx-2 mt-0 card-border-product">
      <div class="mx-auto text-right position-absolute pr-4 pt-2 w-product-badge">
        <md-avatar class="button-price-circle-product">
          <span class="button-price-product-text">${{ data.price }}</span>
        </md-avatar>
      </div>

      <img :alt="data.title" :src="data.poster.url"
        class="borderless w-100 size-img-product show-courses__img-products img-shadow-none pt-0" />


      <h4 class="text-uppercase text-decoration-none py-3 px-2 product-title-text">
        {{ data.title }}
      </h4>
    </div>
  </a> 
</template>
  
<script>
export default {
  name: "CardProductCourse",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};

</script>
  
<style scoped>
.card-border-product {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.button-price-circle-product {
  background-color: #b7227e !important;
  width: 35px;
  min-width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: none !important;
  margin-right: 10px;
}

.w-product-badge {
  max-width: 300px !important;
  width: 100%;
}

.button-price-product-text {
  border-radius: 50%;
  color: #fff !important;
  padding: 0px;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01rem;
  border: none;
  padding-bottom: 1px;
}

.product-title-text {
  max-width: 250px !important;
  display: relative;
}

.size-img-product {
  height: 200px;
  width: auto;
  border: none !important;
  border-radius: 10px 10px 0px 0px !important;
  margin-top: 0px !important;
}


.show-courses__img-products {
  border: none !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 0px !important;
}

.img-shadow-none {
  box-shadow: none !important;
}


@media screen and (max-width: 576px) {
  .w-product-badge {
    max-width: 100% !important;
    width: 100%;
  }
}
</style>