<template>
  <div>
    <div class="container-fluid container-category">
      <div class="row">
        <div class="col-sm-8">
          <form class="filter-flex">
            <i class="fas fa-search fa-flip-horizontal"></i>
            <input
              v-model="searchValue"
              aria-label="Search"
              class="form-control filter-input__category inputFilter me-2"
              placeholder="Buscar curso por nombre"
              type="text"
              @keydown="searchProductCategories"
            />
            <div class="md-layout md-gutter">
              <div class="md-layout-item" style="padding: 0 !important">
                <md-field class="sort-box" style="">
                  <md-select
                    v-model="sortType"
                    class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                  >
                    <md-option hidden selected value="">Filtrar por</md-option>
                    <md-option value="DESC">Nuevo</md-option>
                    <md-option value="ASC">Antiguo</md-option>
                    <md-option value="ASC_title">A-Z</md-option>
                    <md-option value="DESC_title">Z-A</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-4">
          <button
            class="btn btn-course btn-course__librery m-0 float-right"
            @click="openCreateProductCategoryDialog"
          >
            Crear Categoria
          </button>
        </div>
      </div>

      <card class="mt-3">
        <div style="overflow-x: auto">
          <table class="table table-category">
            <thead>
              <tr class="container-instructors__head">
                <th class="md-table-head text-center">Imagen</th>
                <th class="pl-0 md-table-head text-center">Nombre</th>
                <th class="pl-0 md-table-head text-center">Categoria Padre</th>
                <th class="md-table-head text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in this.getProductsCategories.items"
                :key="item.id"
              >
                <td class="md-table-cell text-center">
                  <img
                    v-if="
                      item.productCategoryFile && item.productCategoryFile.url
                    "
                    :alt="item.title"
                    :src="item.productCategoryFile.url"
                    width="100px"
                  />
                  <img
                    v-else
                    alt="productImage"
                    src="@/assets/images/logo/placeholder.png"
                    width="100px"
                  />
                </td>
                <td class="md-table-cell vertical-align text-center">
                  {{ item.title }}
                </td>
                <td class="md-table-cell vertical-align text-center">
                  {{
                    `${item.category_parent ? item.category_parent.title : '-'}`
                  }}
                </td>
                <td class="md-table-cell vertical-align text-center">
                  <span class="float-right mt-1">
                    <md-menu
                      :md-offset-x="-127"
                      :md-offset-y="-20"
                      md-size="auto"
                    >
                      <md-button md-menu-trigger>•••</md-button>

                      <md-menu-content>
                        <md-menu-item>
                          <span
                            class="pr-5 cursor"
                            href
                            @click="openUpdateProductCategoryDialog(item)"
                          >
                            Editar
                          </span>
                        </md-menu-item>
                        <md-menu-item>
                          <span
                            class="pr-5 cursor"
                            href
                            @click="openDeleteProductCategoryDialog(item)"
                          >
                            Eliminar
                          </span>
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </card>
    </div>
    <div
      v-if="totalPages > 1"
      class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
    >
      <base-pagination
        :value="page"
        class="pagination-style"
        :page-count="totalPages"
        @input="paginationChange"
      ></base-pagination>
    </div>
    <create-product-category-dialog
      :is-open="isOpenCreateProductCategoryDialog"
      @success="refetchProductCategories"
      @close-modal="closeCreateProductCategoryDialog"
    />
    <update-product-category-dialog
      :is-open="isOpenUpdateProductCategoryDialog"
      :selected-product-category="selectedProductCategory"
      @success="refetchProductCategories"
      @close-modal="closeUpdateProductCategoryDialog"
    />
    <delete-dialog
      :is-open="isOpenDeleteProductCategoryDialog"
      @deleted="deleteCourseCategory"
      @close-modal="closeDeleteCourseCategoryDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  DELETE_PRODUCT_CATEGORY,
  FETCH_PRODUCT_CATEGORIES,
} from '../../../store/actions/products';
import CreateProductCategoryDialog from './components/CreateProductCategoryDialog';
import UpdateProductCategoryDialog from './components/UpdateProductCategoryDialog';
import DeleteDialog from './components/DeleteDialog';
import BasePagination from '../../../components/BasePagination';

export default {
  components: {
    CreateProductCategoryDialog,
    UpdateProductCategoryDialog,
    DeleteDialog,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data: function () {
    return {
      isLoading: true,
      isOpenCreateProductCategoryDialog: false,
      isOpenUpdateProductCategoryDialog: false,
      isOpenDeleteProductCategoryDialog: false,
      selectedProductCategory: undefined,
      searchValue: '',
      sortType: '',
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getProductsCategories']),
    totalPages() {
      return Math.ceil(this.getProductsCategories.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.fetchProductCategories({
        search: this.searchValue,
        per_page: 20,
        order_by: newValue,
      });
    },
  },
  async mounted() {
    await this.fetchProductCategories();
  },

  methods: {
    async fetchProductCategories(
      filter = {
        per_page: 20,
        order_by: this.sortType,
        search: this.searchValue,
      },
    ) {
      await this.$store.dispatch(FETCH_PRODUCT_CATEGORIES, filter);
      this.isLoading = false;
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    searchProductCategories(e) {
      if (e.key === 'Enter') {
        this.fetchProductCategories();
      }
    },
    openCreateProductCategoryDialog() {
      this.isOpenCreateProductCategoryDialog = true;
    },
    closeCreateProductCategoryDialog() {
      this.isOpenCreateProductCategoryDialog = false;
    },
    openUpdateProductCategoryDialog(productCategory) {
      this.selectedProductCategory = productCategory;
      this.isOpenUpdateProductCategoryDialog = true;
    },
    closeUpdateProductCategoryDialog() {
      this.isOpenUpdateProductCategoryDialog = false;
    },
    openDeleteProductCategoryDialog(productCategory) {
      this.selectedProductCategory = productCategory;
      this.isOpenDeleteProductCategoryDialog = true;
    },
    closeDeleteProductCategoryDialog() {
      this.isOpenDeleteProductCategoryDialog = false;
      this.isOpenDeleteProductCategoryDialog = false;
    },
    deleteCourseCategory() {
      this.$store
        .dispatch(DELETE_PRODUCT_CATEGORY, this.selectedProductCategory.id)
        .then(() => {
          this.notify('success', 'Se ha eliminado la categoria con éxito');
          this.fetchCategories();
          this.closeDeleteProductCategoryDialog();
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.notify(
              'danger',
              'No se puede eliminar una categoria principal que tenga categorías secundarias dependientes',
            );
          } else {
            this.notify(
              'danger',
              'Tenemos problemas para eliminar la categoria',
            );
          }
        });
    },
    closeDeleteCourseCategoryDialog() {
      this.selectedProductCategory = undefined;
      this.isOpenDeleteProductCategoryDialog = false;
    },
    refetchProductCategories() {
      this.fetchProductCategories();
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_PRODUCT_CATEGORIES, {
        page: this.page,
        per_page: this.perPage,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.md-field .md-input:focus,
.md-field .md-textarea:focus,
.md-select-value,
.md-select-value:active {
  border: none !important;
}
.filter-input__category {
  max-width: 100%;
}

.form-control {
  height: 50px;
  margin-bottom: 10px;
}

.md-sort-select {
  margin-right: 0;
}

textarea.form-control {
  height: 250px !important;
}
</style>
