<template>
  <div class="container-fluid container-courses">
    <div class="container-courses__search pb-4">
      <div class="row">
        <div
          class="col-12 row justify-content-between container-courses__header"
        >
          <div
            class="col-xs-courses col-sm-6 col-md-6 col-lg-6 col-lg-6 col-xl-6"
          >
            <form class="filter-flex">
              <i class="fas fa-search fa-flip-horizontal"></i>
              <input
                v-model="searchValue"
                aria-label="Search"
                class="form-control inputFilter md-filter-input me-2"
                placeholder="Buscar curso por nombre"
                type="text"
                @keydown="searchCourses"
              />

              <div class="md-layout md-gutter">
                <div class="md-layout-item" style="padding: 0 !important">
                  <md-field class="sort-box">
                    <md-select
                      v-model="sortType"
                      class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                    >
                      <md-option
hidden
selected
value=""
                        >Filtrar por
                      </md-option>
                      <md-option value="DESC">Nuevo</md-option>
                      <md-option value="ASC">Antiguo</md-option>
                      <md-option value="ASC_name">A-Z</md-option>
                      <md-option value="DESC_name">Z-A</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </form>
          </div>
          <div
            class="col-xs-courses col-sm-4 col-md-4 col-lg-4 col-lg-4 col-xl-4"
          >
            <div>
              <button
                class="btn btn-course float-right"
                @click="isOpenCreateCourseDialog = true"
              >
                + NUEVO CURSO
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 container-courses__courses">
          <md-tabs>
            <md-tab id="tab-module" md-icon="view_module">
              <template v-if="isLoadingCourses">
                <div class="col-12 card p-3">
                  <div class="videos">
                    <div
                      v-for="index in 8"
                      :key="index"
                      class="d-flex flex-column justify-content-between"
                    >
                      <skeleton-admin-course />
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <template
                  v-if="getAllCourses.items && getAllCourses.items.length"
                >
                  <div class="col-12 card">
                    <div class="videos">
                      <div
                        v-for="(course, index) in getAllCourses.items"
                        :key="index"
                        class="p-3 border-card d-flex flex-column justify-content-between"
                      >
                        <div>
                          <div class="container-img w-100">
                            <router-link
                              :to="'/admin/cursos/curso/' + course.id"
                              style="text-decoration: none"
                            >
                              <img
                                v-if="course.thumbnail_url"
                                :src="course.thumbnail_url"
                                alt="thumbnail"
                                class="borderless h-12 img-object-fit w-100"
                              />
                              <img
                                v-else
                                alt="imgmaquillate"
                                class="borderless h-12 img-object-fit w-100"
                                src="@/assets/images/logo/placeholder.png"
                              />
                            </router-link>
                            <i
                              v-if="course.featured"
                              class="fas fa-star course-featured"
                              @click="changeCourseFeatured(course)"
                            ></i>
                            <i
                              v-else
                              class="far fa-star course-featured"
                              @click="changeCourseFeatured(course)"
                            ></i>

                            <md-avatar
                              v-if="course.free"
                              class="md-avatar-icon md-primary price-course"
                              style="
                                background: white;
                                color: #000;
                                font-size: 8px;
                                font-weight: bold;
                              "
                            >
                              <span>GRATIS</span>
                            </md-avatar>
                            <md-avatar
                              v-else
                              class="md-avatar-icon md-primary price-course"
                              style="
                                background: white;
                                color: #000;
                                font-weight: bold;
                              "
                            >
                              {{ '$' + course.price }}
                            </md-avatar>
                            <md-avatar
                              class="md-avatar-icon md-primary price-course"
                              style="
                                background: white;
                                color: #000;
                                font-weight: bold;
                                margin-left: -92px;
                              "
                            >
                              <span>{{ course.course_videos.length }}</span>
                            </md-avatar>
                          </div>
                          <div class="py-2">
                            <router-link
                              :to="'/admin/cursos/curso/' + course.id"
                              style="text-decoration: none"
                            >
                              <h3
                                class="fw-bold m-0 p-0"
                                style="text-transform: uppercase"
                              >
                                {{ course.name }}
                              </h3>
                            </router-link>
                            <small class="mb-0 text-muted">
                              {{ getInstructors(course.instructors) }}
                            </small>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-7 pr-0">
                            <span
                              :class="
                                course.status === 'PUBLISH'
                                  ? 'bagde-publish'
                                  : 'bagde-draft'
                              "
                              >{{
                                course.status === 'PUBLISH'
                                  ? 'Publicado'
                                  : 'Borrador'
                              }}</span
                            >
                          </div>
                          <div class="col-5 pl-0">
                            <span class="float-right">
                              <md-menu
                                :md-offset-x="-127"
                                :md-offset-y="-20"
                                md-size="auto"
                              >
                                <a
                                  :href="`/admin/cursos/curso/preview/${course.id}`"
                                  target="_blank"
                                  ><i class="fas fa-eye eye-course"></i
                                ></a>
                                <md-button md-menu-trigger>•••</md-button>

                                <md-menu-content>
                                  <md-menu-item>
                                    <router-link
                                      :to="`/admin/cursos/curso/${course.id}`"
                                      class="text-decoration-none text-000 pr-5"
                                    >
                                      Editar
                                    </router-link>
                                  </md-menu-item>
                                  <md-menu-item>
                                    <span
                                      class="pr-5 cursor"
                                      href
                                      @click="replicateCourse(course.id)"
                                    >
                                      Duplicar
                                    </span>
                                  </md-menu-item>
                                  <md-menu-item>
                                    <span
                                      class="pr-5 cursor"
                                      href
                                      @click="confirmDeleteCourse(course)"
                                    >
                                      Eliminar
                                    </span>
                                  </md-menu-item>
                                </md-menu-content>
                              </md-menu>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="col-12 text-center">No hay cursos creados</div>
                </template>
              </template>
            </md-tab>

            <md-tab id="tab-list-courses" md-icon="list">
              <template v-if="getAllCourses.items.length">
                <div class="col-12 card mb-4">
                  <div class="row padding-courses">
                    <div
                      v-for="(course, index) in getAllCourses.items"
                      :key="index"
                      class="col-12 p-3 container-courses__info-course"
                      style="margin-bottom: 15px"
                    >
                      <div class="row">
                        <div class="col container-img pr-0">
                          <router-link
                            :to="'/admin/cursos/curso/' + course.id"
                            style="text-decoration: none"
                          >
                            <img
                              v-if="course.thumbnail_url"
                              :src="course.thumbnail_url"
                              alt="thumbnail"
                              class="borderless h-12 img-object-fit w-100"
                            />
                            <img
                              v-else
                              alt="imgmaquillate"
                              class="borderless h-12 img-object-fit w-100"
                              src="@/assets/images/logo/placeholder.png"
                            />
                          </router-link>
                          <i
                            v-if="course.featured"
                            class="fas fa-star course-featured"
                            style="
                              position: absolute;
                              margin-top: 10px;
                              margin-left: -26px;
                              color: yellow;
                            "
                            @click="removeFeaturedCourse(course)"
                          ></i>
                          <i
                            v-else
                            class="far fa-star"
                            style="
                              position: absolute;
                              margin-top: 10px;
                              margin-left: -26px;
                              color: yellow;
                            "
                            @click="addFeaturedCourse(course)"
                          ></i>

                          <md-avatar
                            v-if="course.free"
                            class="md-avatar-icon md-primary price-course"
                            style="
                              background: white;
                              color: #000;
                              font-size: 8px;
                              font-weight: bold;
                            "
                          >
                            <span>GRATIS</span>
                          </md-avatar>
                          <md-avatar
                            v-else
                            class="md-avatar-icon md-primary price-course"
                            style="
                              background: white;
                              color: #000;
                              font-weight: bold;
                            "
                          >
                            {{ '$' + course.price }}
                          </md-avatar>
                          <md-avatar
                            class="md-avatar-icon md-primary price-course"
                            style="
                              background: white;
                              color: #000;
                              font-weight: bold;
                              margin-left: -92px;
                            "
                          >
                            <span>{{ course.course_videos.length }}</span>
                          </md-avatar>
                        </div>
                        <div class="col-8-info info my-auto">
                          <span
                            :class="
                              course.status === 'PUBLISH'
                                ? 'bagde-publish'
                                : 'bagde-draft'
                            "
                            >{{
                              course.status == 'PUBLISH'
                                ? 'Publicado'
                                : 'Borrador'
                            }}</span
                          >
                          <router-link
                            :to="'/admin/cursos/curso/' + course.id"
                            style="text-decoration: none"
                          >
                            <h3
                              class="fw-bold py-2 mb-0"
                              style="text-transform: uppercase"
                            >
                              {{ course.name }}
                            </h3>
                          </router-link>
                          <small class="mb-0 text-muted">
                            <span v-if="index">, </span>
                            {{ getInstructors(course.instructors) }}
                          </small>
                        </div>
                        <div class="col-1-options options align-items-start">
                          <div class="status-mobile">
                            <span
                              :class="
                                course.status === 'PUBLISH'
                                  ? 'bagde-publish'
                                  : 'bagde-draft'
                              "
                              >{{
                                course.status === 'PUBLISH'
                                  ? 'Publicado'
                                  : 'Borrador'
                              }}</span
                            >
                          </div>
                          <span class="float-right">
                            <md-menu
                              :md-offset-x="-100"
                              :md-offset-y="-10"
                              md-size="auto"
                            >
                              <a
                                :href="`/admin/cursos/curso/preview/${course.id}`"
                                target="_blank"
                                ><i class="fas fa-eye eye-course"></i
                              ></a>
                              <md-button md-menu-trigger>•••</md-button>

                              <md-menu-content>
                                <md-menu-item>
                                  <router-link
                                    :to="'/admin/cursos/curso/' + course.id"
                                    class="dropdown-item-edit p-0"
                                  >
                                    Editar
                                  </router-link>
                                </md-menu-item>
                                <md-menu-item>
                                  <span
                                    class="dropdown-item p-0"
                                    href
                                    style="color: #000"
                                    @click="replicateCourse(course.id)"
                                  >
                                    Duplicar
                                  </span>
                                </md-menu-item>
                                <md-menu-item>
                                  <span
                                    class="dropdown-item p-0"
                                    href
                                    style="color: #000"
                                    @click="confirmDeleteCourse(course)"
                                  >
                                    Eliminar
                                  </span>
                                </md-menu-item>
                              </md-menu-content>
                            </md-menu>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-12 text-center">No hay cursos creados</div>
              </template>
            </md-tab>
          </md-tabs>
          <div
            v-if="totalPages > 1"
            class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
          >
            <base-pagination
              :value="page"
              class="pagination-style"
              :page-count="totalPages"
              @input="paginationChange"
            ></base-pagination>
          </div>
        </div>
      </div>
    </div>
    <create-course-dialog
      :is-open="isOpenCreateCourseDialog"
      @success="refetchCourses"
      @close-modal="closeCreateCourseDialog"
    />

    <delete-dialog
      :is-open="isOpenDeleteCourseDialog"
      @deleted="deleteCourse"
      @close-modal="closeDeleteCourseDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { COURSE_REPLICATE_COURSE } from '@/store/actions/course';
import {
  CHANGE_COURSE_FEATURE,
  DELETE_COURSE,
  FETCH_COURSES,
} from '../../../store/actions/course';
import SkeletonAdminCourse from '../../Components/skeletons/SkeletonAdminCourse';
import CreateCourseDialog from './components/CreateCourseDialog';
import DeleteDialog from './components/DeleteDialog';
import BasePagination from '../../../../src/components/BasePagination.vue';

export default {
  name: 'AdminCourses',
  components: {
    SkeletonAdminCourse,
    CreateCourseDialog,
    DeleteDialog,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      isOpenCreateCourseDialog: false,
      isLoadingCourses: true,
      isOpenDeleteCourseDialog: false,
      selectedCourse: undefined,
      isChangeEmail: false,
      searchValue: null,
      sortType: 'DESC',
      isFilter: false,
      formData: {
        email: '',
      },
      model: {
        course_name: null,
      },
      modals: {
        createCourse: false,
      },
      page: 1,
    };
  },

  computed: {
    ...mapGetters(['getAllCourses']),
    totalPages() {
      return Math.ceil(this.getAllCourses.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.fetchCourses({
        search: this.searchValue,
        per_page: 20,
        order_by: newValue,
      });
    },
  },
  mounted() {
    this.fetchCourses().then(() => (this.isLoadingCourses = false));
  },
  methods: {
    refetchCourses() {
      this.fetchCourses();
    },
    searchCourses(e) {
      if (e.key === 'Enter') {
        this.fetchCourses();
      }
    },
    fetchCourses(
      filter = {
        search: this.searchValue,
        per_page: 20,
        order_by: this.sortType,
      },
    ) {
      return this.$store.dispatch(FETCH_COURSES, filter);
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    changeCourseFeatured(course) {
      const featured = !course.featured;

      this.$store
        .dispatch(CHANGE_COURSE_FEATURE, {
          id: course.id,
          featured: !course.featured,
        })
        .then(() => {
          if (featured) {
            this.notify('success', `Sea asigno ${course.name} como destacado`);
          } else {
            this.notify(
              'success',
              `Sea removido ${course.name} como destacado`,
            );
          }
          this.refetchCourses();
        })
        .catch(() => {
          this.notify(
            'danger',
            'Tenemos problemas para asignar/remover el curso como destacado',
          );
        });
    },
    clearFilter() {
      this.searchValue = '';
    },
    getInstructors(instructors) {
      if (!instructors.length) return 'Sin instructores asignados';

      return instructors
        .map(
          ({ instructor }) => `${instructor.firstname} ${instructor.lastname}`,
        )
        .join(' , ');
    },
    async replicateCourse(id) {
      await this.$store.dispatch(COURSE_REPLICATE_COURSE, { id });
    },
    confirmDeleteCourse(course) {
      this.selectedCourse = course;
      this.isOpenDeleteCourseDialog = true;
    },
    deleteCourse() {
      this.$store.dispatch(DELETE_COURSE, this.selectedCourse.id).then(() => {
        this.notify('success', 'Se ha eliminado el curso con éxito ');
        this.closeDeleteCourseDialog();
        this.refetchCourses();
      });
    },
    closeDeleteCourseDialog() {
      this.isOpenDeleteCourseDialog = false;
      this.selectedCourse = undefined;
    },

    closeCreateCourseDialog() {
      this.isOpenCreateCourseDialog = false;
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_COURSES, {
        page: this.page,
        per_page: this.perPage,
        featured: 1,
      });
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.course-featured {
  position: absolute;
  margin-top: 10px;
  margin-left: -26px;
  color: #f7d917;
}

.md-menu-content {
  border-radius: 5px !important;
  z-index: 10;
  max-height: none !important;
}

.dropdown-item {
  color: #000;
}
a.dropdown-item-edit {
  font-size: 14px !important;
  color: #000 !important;
  text-decoration: none;
}

.md-field.md-theme-default.md-focused .md-input:focus {
  border: none !important;
}
.md-menu-content.md-select-menu {
  z-index: 111 !important;
}
</style>
