<template>
  <div class="view-home__part7 allClasses user-view-config">
    <md-tabs
      :md-active-tab="getCurrentTab()"
      class="tab-user-config style-config container-fluid size p-2"
      @md-changed="handleTab"
    >
      <md-tab id="cuenta" md-label="MI CUENTA">
        <div class="mb-4 ml-1 p-1">
          <h6 class="heading-small text-color-title mb-3">PERFIL</h6>
          <my-account />
        </div>
      </md-tab>
      <md-tab id="pagos" md-label="PAGOS">
        <div class="mb-4 ml-1 p-1">
          <h6 class="heading-small text-color-title mb-3">PAGOS</h6>
          <credit-card-list />
        </div>

        <hr class="mt-0 mb-4" />
        <div class="mb-4">
          <h6 class="heading-small text-color-title mb-3">Últimas compras</h6>
          <user-invoices />
        </div>
      </md-tab>
      <md-tab id="ayuda" md-label="AYUDA" class="pl-1">
        <h2>¡Estamos aquí para ayudarte!</h2>
        <div class="form mt-4">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <textarea
                v-model="email.content"
                class="description-help"
                placeholder="Describe cómo te podemos ayudar."
                rows="10"
              >
              </textarea>
              <base-button
                :loading="isLoading"
                class="btn btn-maquillate py-2 mt-4"
                @click="sendEmailHelp()"
              >
                Enviar
              </base-button>
            </div>
            <div class="col-12">
              <p class="style-text-config mt-3">
                Se puede comunicar con nuestro servicio al cliente de lunes a
                viernes de 9 am a 5 pm EST envíando un correo a
                <span>ayuda@maquillate.com</span>.
              </p>
            </div>
          </div>
        </div>
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
import { SEND_EMAIL_HELP } from '@/store/actions/user';

import { mapGetters } from 'vuex';
import MyAccount from './components/settings/UserAccount.vue';
import CreditCardList from './components/settings/CreditCardList';
import UserInvoices from './components/settings/UserInvoices';
import BaseButton from '../../../components/BaseButton';

export default {
  components: {
    UserInvoices,
    MyAccount,
    CreditCardList,
    BaseButton,
  },
  data() {
    return {
      isLoading: false,
      currentTab: '',
      email: {
        content: null,
      },
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getInvoices', 'getCreditCardList']),
    bankAccountComputed: function () {
      return this.getProfile.bank_accounts.data;
    },
  },
  created() {
    if (this.$route.params.option) {
      this.currentTab = this.$route.params.option;
    }
  },

  methods: {
    handleTab(tabName) {
      const targetRoute = `/user/configuracion/${tabName}`;
      if (this.$route.path === targetRoute) {
        return;
      }
      this.$router.push(targetRoute);
    },
    getCurrentTab() {
      return this.currentTab ? this.$route.params.option : null;
    },
    notifyAlert(message = '', type = '') {
      this.$notify({
        type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message,
      });
    },
    sendEmailHelp() {
      const data = {
        content: this.email.content,
      };
      this.isLoading = true;
      this.$store
        .dispatch(SEND_EMAIL_HELP, data)
        .then(() => {
          this.isLoading = false;
          this.email.content = '';
          this.notifyAlert('Se ha enviado el correo con éxito', 'success');
        })
        .catch(() => {
          this.isLoading = false;
          this.notifyAlert('No se pudo enviar el correo con éxito', 'danger');
        });
    },
  },
};
</script>

<style>
.tab-user-config.md-tabs.md-theme-default .md-tabs-navigation {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.tab-user-config.md-tabs.md-alignment-left .md-tabs-navigation {
  justify-content: flex-start;
  width: fit-content;
}

.tab-user-config.md-tabs.md-theme-default
  .md-tabs-navigation
  .md-button.md-active {
  background: #b7227e;
  color: #fff !important;
  border-radius: 10px;
}

.tab-user-config.md-button.md-active {
  color: #fff !important;
}

.tab-user-config.md-button.md-tab-nav-button.md-theme-default.md-active {
  color: #fff !important;
  opacity: 1;
  transition: none;
  transform: none;
}
.tab-user-config .md-active .md-button-content {
  color: #fff !important;
}
.tab-user-config.style-config .md-tabs-indicator,
.tab-user-config.style-config .md-tabs-indicator.md-tabs-indicator-left,
.tab-user-config.style-config .md-tabs-indicator.md-tabs-indicator-right {
  background-color: transparent !important;
}

.tab-user-config.md-tabs.md-alignment-left .md-tabs-navigation {
  padding: 5px;
}

.multiselect__content-wrapper {
  position: absolute !important;
  top: 100% !important;
  bottom: auto !important;
}

.md-button,
.md-button-clean {
  -webkit-transition: none !important;
  transition: none !important;
}

@media screen and (max-width: 992px) {
  .user-view-config {
    padding-top: 100px !important;
  }
}
</style>
