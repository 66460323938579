<template>
  <div class="container-fluid">
    <div
      class="col-12 row justify-content-between container-instructors__search pr-0 pl-0 ml-0 mb-3"
    >
      <div class="col-xs-courses col-sm-6 col-md-6 col-lg-6 col-lg-6 col-xl-6">
        <form class="filter-flex" style="margin-left: -15px">
          <i class="fas fa-search fa-flip-horizontal"></i>
          <input
            v-model="searchValue"
            aria-label="Search"
            class="form-control inputFilter md-filter-input me-2"
            placeholder="Buscar por nombre"
            type="text"
          />
          <div class="table-instructor md-layout md-gutter">
            <div class="md-layout-item p-0">
              <md-field class="sort-box">
                <md-select
                  v-model="sortType"
                  class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                >
                  <md-option hidden selected value="">Filtrar por</md-option>
                  <md-option value="newest">Nuevo</md-option>
                  <md-option value="oldest">Antiguo</md-option>
                  <md-option value="asc">A-Z</md-option>
                  <md-option value="desc">Z-A</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </form>
      </div>
      <div
        class="col-xs-instructor col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 pr-0"
      >
        <div class="float-right button-instructor">
          <base-button
            class="btn-course"
            @click="openCreateCourseInstructorDialog()"
          >
            AGREGAR INSTRUCTOR
          </base-button>
        </div>
      </div>
    </div>

    <div class="container-instructors__body">
      <div class="container-instructors">
        <md-table>
          <md-table-row class="container-instructors__head">
            <md-table-head>Nombre</md-table-head>
            <md-table-head>Cursos</md-table-head>
            <md-table-head>Clases</md-table-head>
            <md-table-head>País</md-table-head>
            <md-table-head></md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>

          <template v-if="isLoadingInstructors">
            <skeleton-course-instructor v-for="index in 4" :key="index" />
          </template>
          <template v-else>
            <template
              v-if="getInstructors.items && getInstructors.items.length"
            >
              <md-table-row
                v-for="item in getInstructors.items"
                :key="item.id"
                class="container-instructors__cell"
              >
                <md-table-cell>
                  <div class="d-flex align-items-center">
                    <div
                      :style="{
                        backgroundImage: `url('${
                          item.avatar && item.avatar.url ? item.avatar.url : ''
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '0.25rem',
                        height: '50px',
                        width: '50px',
                      }"
                      class="rounded-circle h-50px"
                    />
                    <span class="pl-3">{{
                      `${item.firstname} ${item.lastname}`
                    }}</span>
                  </div>
                </md-table-cell>
                <md-table-cell>
                  <div class="d-flex flex-row bd-highlight">
                    <md-avatar
                      :class="[
                        (item.courses || []).length
                          ? 'coursesActives'
                          : 'bg-light',
                      ]"
                      class="md-avatar-icon ml-0 mr-2"
                    >
                      {{ (item.courses || []).length }}
                    </md-avatar>
                    <div v-if="(item.courses || []).length" class="py-1">
                      <i
                        class="fas fa-bars"
                        role="button"
                        @click="handleInstructorCourses(item)"
                      ></i>
                    </div>
                  </div>
                </md-table-cell>

                <md-table-cell>
                  <div class="d-flex flex-row bd-highlight">
                    <md-avatar
                      :class="[
                        (item.classes || []).length
                          ? 'coursesActives'
                          : 'bg-light',
                      ]"
                      class="md-avatar-icon ml-0 mr-2"
                    >
                      {{ (item.classes || []).length }}
                    </md-avatar>
                    <div v-if="(item.classes || []).length" class="py-1">
                      <i
                        class="fas fa-bars"
                        role="button"
                        @click="handleInstructorClasses(item)"
                      ></i>
                    </div>
                  </div>
                </md-table-cell>

                <md-table-cell
                  >{{ item.country ? item.country.name : 'Sin asignar' }}
                </md-table-cell>
                <md-table-cell>
                  <i
                    v-if="item.featured"
                    class="fas fa-star instructor-featured"
                    @click="changeInstructorFeatured(item)"
                  ></i>
                  <i
                    v-else
                    class="far fa-star instructor-featured"
                    @click="changeInstructorFeatured(item)"
                  ></i
                ></md-table-cell>

                <md-table-cell class="container-instructors__options">
                  <span class="float-right">
                    <md-menu
                      :md-offset-x="-147"
                      :md-offset-y="-10"
                      md-size="auto"
                    >
                      <md-button
class="button-center-options"
md-menu-trigger
                        >•••</md-button
                      >

                      <md-menu-content>
                        <md-menu-item
                          @click="openUpdateCourseInstructorDialog(item)"
                          >Editar</md-menu-item
                        >
                        <md-menu-item
                          @click="openDeleteCourseInstructorDialog(item)"
                          >Eliminar</md-menu-item
                        >
                      </md-menu-content>
                    </md-menu>
                  </span>
                </md-table-cell>
              </md-table-row>
            </template>
            <template v-else></template>
          </template>
        </md-table>
        <div
          v-if="totalPages > 1"
          class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
    </div>
    <create-course-instructor-dialog
      :is-open="isOpenCreateCourseInstructorDialog"
      @success="refetchIntructors"
      @close-modal="closeCreateCourseInstructorDialog"
    />
    <update-course-instructor-dialog
      :is-open="isOpenUpdateCourseInstructorDialog"
      :selected-course-instructor="selectedCourseInstructor"
      @success="refetchIntructors"
      @close-modal="closeUpdateCourseInstructorDialog"
    />
    <delete-dialog
      :is-open="isOpenDeleteCourseInstructorDialog"
      @deleted="deleteCourseInstructor"
      @close-modal="closeDeleteCourseInstructorDialog"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CreateCourseInstructorDialog from './components/CreateCourseInstructorDialog';
import UpdateCourseInstructorDialog from './components/UpdateCourseInstructorDialog';
import DeleteDialog from './components/DeleteDialog';
import SkeletonCourseInstructor from '../../Components/skeletons/SkeletonCourseInstructor';
import {
  DELETE_INSTRUCTOR,
  FETCH_INSTRUCTORS,
  UPDATE_INSTRUCTOR_FEATURE,
} from '../../../store/actions/instructor';
import BasePagination from '../../../components/BasePagination';

export default {
  name: 'CourseInstructors',
  components: {
    CreateCourseInstructorDialog,
    UpdateCourseInstructorDialog,
    DeleteDialog,
    SkeletonCourseInstructor,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data: function () {
    return {
      searchValue: '',
      sortType: '',
      isLoadingInstructors: true,
      isOpenCreateCourseInstructorDialog: false,
      isOpenUpdateCourseInstructorDialog: false,
      isOpenDeleteCourseInstructorDialog: false,
      selectedCourseInstructor: undefined,
      page: 1,
      showDialog: false,
      titleSeo: '',
      descriptionSeo: '',
    };
  },
  computed: {
    ...mapGetters(['getInstructors']),
    totalPages() {
      return Math.ceil(this.getInstructors.total / this.perPage);
    },
  },
  watch: {
    sortType: function () {},
  },
  mounted() {
    this.fetchInstructors();
  },
  methods: {
    fetchInstructors(
      filter = {
        search: this.searchValue,
        per_page: 20,
        order_by: this.sortType,
      },
    ) {
      this.isLoadingInstructors = true;
      this.$store.dispatch(FETCH_INSTRUCTORS, filter).then(() => {
        this.isLoadingInstructors = false;
      });
    },
    openCreateCourseInstructorDialog() {
      this.isOpenCreateCourseInstructorDialog = true;
    },
    closeCreateCourseInstructorDialog() {
      this.isOpenCreateCourseInstructorDialog = false;
    },

    openUpdateCourseInstructorDialog(courseInstructor) {
      this.selectedCourseInstructor = courseInstructor;
      this.isOpenUpdateCourseInstructorDialog = true;
    },
    closeUpdateCourseInstructorDialog() {
      this.selectedCourseInstructor = undefined;
      this.isOpenUpdateCourseInstructorDialog = false;
    },

    openDeleteCourseInstructorDialog(courseInstructor) {
      this.selectedCourseInstructor = courseInstructor;
      this.isOpenDeleteCourseInstructorDialog = true;
    },
    closeDeleteCourseInstructorDialog() {
      this.selectedCourseInstructor = undefined;
      this.isOpenDeleteCourseInstructorDialog = false;
    },

    deleteCourseInstructor() {
      this.$store
        .dispatch(DELETE_INSTRUCTOR, this.selectedCourseInstructor.id)
        .then(() => {
          this.notify('success', 'Se ha eliminado el instructor con éxito');
          this.fetchInstructors();
          this.closeDeleteCourseInstructorDialog();
        })
        .catch(() => {
          this.notify(
            'danger',
            'Tenemos problemas para eliminar el instructor',
          );
        });
    },

    refetchIntructors() {
      this.fetchInstructors();
    },

    handleInstructorCourses(courseInstructor) {
      this.$router.push(`instructor/instructor/${courseInstructor.id}`);
    },
    handleInstructorClasses(courseInstructor) {
      this.$router.push(`instructor/clases/${courseInstructor.id}`);
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },

    changeInstructorFeatured(item) {
      const featured = !item.featured;

      this.$store
        .dispatch(UPDATE_INSTRUCTOR_FEATURE, {
          id: item.id,
          featured: !item.featured,
        })
        .then(() => {
          console.log('featured completado');
          if (featured) {
            this.notify(
              'success',
              `Se ha asignado ${item.firstname} como destacado`,
            );
          } else {
            this.notify(
              'danger',
              `Se ha removido ${item.firstname} como destacado`,
            );
          }
          this.refetchIntructors();
        })
        .catch(() => {
          this.notify(
            'danger',
            'Tenemos problemas para asignar/remover el instructor como destacado',
          );
        });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_INSTRUCTORS, {
        page: this.page,
        per_page: this.perPage,
        featured: 1,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.instructor-featured {
  color: #f7d917;
  position: relative;
}
.button-center-options {
  text-align-last: center;
  align-self: center;
  padding-bottom: 6px;
  background: white;
  border: none;
  vertical-align: middle;
  margin: 0px !important;
  font-size: 25px;
}

.container-instructors {
  padding: 0px !important;
}
</style>
