var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid main-container__librery"},[(_vm.getCurrentCourse)?_c('div',{staticClass:"float-button pr-5"},[_c('a',{attrs:{"href":`/admin/cursos/curso/preview/${_vm.getCurrentCourse.id}`}},[_c('i',{staticClass:"far fa-eye"})])]):_vm._e(),_c('div',{staticClass:"container-styles p-3"},[_c('vue-tabs',[_c('v-tab',{attrs:{"title":"VIDEOS"}},[_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"row course-container"},[_c('div',{staticClass:"col-12 chapter-section"},[_c('div',{staticClass:"chapters-container"},[(_vm.isLoading)?_c('div',_vm._l((4),function(index){return _c('skeleton-course-video',{key:index})}),1):_c('draggable',_vm._b({attrs:{"group":{ name: 'courseVideos' },"handle":".handle","force-fallback":true},on:{"end":_vm.onEnd,"start":function($event){_vm.drag = true}}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"name":!_vm.drag ? 'flip-list' : null,"type":"transition"}},_vm._l((this.getCurrentCourseVideos),function(element){return _c('div',{key:element.position,staticClass:"single-chapter row mb-3 p-3",class:{
                        activebox:
                          _vm.getCourseVideo &&
                          element.position === _vm.getCourseVideo.position,
                      },attrs:{"data-box":element.position,"data-id":element.id}},[_c('div',{staticClass:"col-2 icon-section pl-0 chapter-title handle px-0"},[_c('i',{staticClass:"fas fa-grip-horizontal"})]),_c('div',{staticClass:"col-10 align-self-center"},[_c('div',{staticClass:"chapter-top"},[_c('h2',{staticClass:"mb-0 mx-3 tab-title-video",on:{"click":function($event){$event.preventDefault();_vm.isOpenCourseVideoForm &&
                              _vm.getCourseVideo &&
                              element.position === _vm.getCourseVideo.position
                                ? _vm.closeCourseVideoForm(element)
                                : _vm.openCourseVideoForm(element)}}},[_vm._v(" "+_vm._s(element.title.toUpperCase() || 'Nuevo video')+" ")]),_c('div',{staticClass:"right-icons"},[_c('div',{staticClass:"fast-buttons mr-3"},[_c('i',{staticClass:"fas fa-chart-line",on:{"click":function($event){return _vm.openCourseVideoSeo(element)}}})]),_c('div',{staticClass:"fast-buttons mr-3"},[(element.featured)?_c('i',{staticClass:"fas fa-star color-yellow",on:{"click":function($event){return _vm.changeCourseVideoFeatured(element)}}}):_c('i',{staticClass:"far fa-star color-yellow",on:{"click":function($event){return _vm.changeCourseVideoFeatured(element)}}})]),_c('div',{staticClass:"fast-buttons mr-3",on:{"click":function($event){return _vm.openDeleteCourseModal(element)}}},[_c('i',{staticClass:"fas fa-trash"})]),_c('div',{staticClass:"fast-buttons mr-3",on:{"click":function($event){$event.stopPropagation();return _vm.duplicateCourseVideo(element)}}},[_c('i',{staticClass:"far fa-copy"})]),(
                                _vm.isOpenCourseVideoForm &&
                                _vm.getCourseVideo &&
                                element.position === _vm.getCourseVideo.position
                              )?_c('div',{staticClass:"fast-buttons",on:{"click":function($event){$event.preventDefault();return _vm.closeCourseVideoForm(element)}}},[_c('i',{staticClass:"fas fa-angle-up",attrs:{"data-id":element.position}})]):_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.openCourseVideoForm(element)}}},[_c('i',{staticClass:"fas fas fa-angle-down",attrs:{"data-id":element.position}})])])]),_c('div',{staticClass:"chapter-bot"},[(
                              _vm.getCourseVideo &&
                              _vm.getCourseVideo.position === element.position
                            )?_c('div',{staticClass:"col-12 edit-section mx-0"},[_c('video-form',{attrs:{"course-video":_vm.getCourseVideo},on:{"success":_vm.refetchCourse}})],1):_vm._e()])])])}),0)],1)],1),_c('base-button',{staticClass:"primary btn-maquillate py-2 px-3",attrs:{"loading":_vm.isCreateCourseVideo,"type":"primary"},on:{"click":_vm.createCourseVideo}},[_vm._v(" Agregar video ")])],1)])])]),_c('v-tab',{attrs:{"title":"Configuración"}},[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"course-configuration"},[_c('course-config-form')],1)])]),_c('v-tab',{attrs:{"title":"SEO"}},[_c('seo-view',{attrs:{"seo-data":_vm.seoCourse,"is-loading":_vm.isSeoLoading},on:{"create-seo":_vm.createSeo,"update-seo":_vm.updateSeo}})],1)],1)],1),_c('delete-dialog',{attrs:{"is-open":_vm.isOpenDeleteCourseModal},on:{"deleted":_vm.deleteCourseVideo,"close-modal":_vm.closeDeleteCourseModal}}),_c('seo-dialog',{attrs:{"is-open":_vm.isOpenCourseVideoSeoDialog,"seo-data":_vm.selectedCourseVideoSeo,"is-loading":_vm.isCourseVideoSeoLoading},on:{"update-seo":_vm.updateCourseVideoSeo,"create-seo":_vm.createCourseVideoSeo,"close-modal":_vm.closeCourseVideoSeo}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }