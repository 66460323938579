<template>
  <div class="container-fluid">
    <template v-if="isLoadingCategory">
      <div class="pb-2">
        <skeleton-loading>
          <skeleton-square
            :box-properties="{
              height: '16px',
              width: '350px',
            }"
            :count="1"
          />
        </skeleton-loading>
      </div>
    </template>
    <template v-else>
      <h3>Categoria: {{ this.getCategoryCourse.title }}</h3>
    </template>
    <div
      class="col-12 row justify-content-between container-instructors__search pr-0 pl-0 ml-0 mb-3"
    >
      <div class="col-7 pl-0">
        <i class="fas fa-search fa-flip-horizontal"></i>
        <input
          v-model="search"
          aria-label="Search"
          class="form-control me-2"
          placeholder="Buscar por nombre"
          type="text"
          @keydown="searchCourses"
        />
      </div>
    </div>
    <div class="container-instructors container-instructors__instructor">
      <div class="container-fluid container-category px-0">
        <md-table>
          <md-table-row class="container-instructors__head">
            <md-table-head>Nombre del curso</md-table-head>
            <md-table-head>Precio</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
          <template v-if="isLoadingCategory">
            <skeleton-category-course v-for="index in 4" :key="index" />
          </template>
          <template v-else>
            <template v-if="this.getCategoryCourse">
              <md-table-row
                v-for="{ course } in this.getCategoryCourse.courses"
                :key="course.id"
                class="container-instructors__cell"
              >
                <md-table-cell>
                  {{ course.name }}
                </md-table-cell>
                <md-table-cell>
                  {{ !course.free ? 'Gratis' : course.price }}
                </md-table-cell>
                <md-table-cell class="container-instructors__options">
                  <span class="float-right">
                    <md-menu
                      :md-offset-x="-147"
                      :md-offset-y="-10"
                      md-size="auto"
                    >
                      <md-button md-menu-trigger>•••</md-button>

                      <md-menu-content>
                        <md-menu-item>
                          <router-link
                            :to="`/admin/cursos/curso/${course.id}`"
                            class="dropdown-item p-0"
                          >
                            Editar
                          </router-link>
                        </md-menu-item>
                        <md-menu-item>
                          <div
                            class="dropdown-item p-0"
                            @click="openDeleteCategoryCourseDialog(course)"
                          >
                            Eliminar
                          </div>
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
                  </span>
                </md-table-cell>
              </md-table-row>
            </template>
            <template v-else>
              <md-table-row>
                <md-table-cell class="pt-4 text-center" colspan="4">
                  No hay cursos asignados
                </md-table-cell>
              </md-table-row>
            </template>
          </template>
        </md-table>
      </div>
    </div>
    <delete-dialog
      :is-open="isOpenDeleteCategoryCourseDialog"
      @deleted="deleteCategoryCourse"
      @close-modal="closeDeleteCategoryCourseDialog"
    />
  </div>
</template>

<script>
import {
  DELETE_CATEGORY_RELATION,
  FETCH_CATEGORY,
} from '../../../store/actions/categories';
import { mapGetters } from 'vuex';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import DeleteDialog from './components/DeleteDialog';
import SkeletonCategoryCourse from '../../Components/skeletons/SkeletonCategoryCourse.vue';

export default {
  components: {
    SkeletonLoading,
    DeleteDialog,
    SkeletonSquare,
    SkeletonCategoryCourse,
  },
  data: function () {
    return {
      selectedCourseCategoryCourse: undefined,
      isLoadingCategory: true,
      isOpenDeleteCategoryCourseDialog: false,
      search: '',
    };
  },
  computed: {
    ...mapGetters(['getCategoryCourse']),
  },
  mounted() {
    this.fetchCategory();
  },
  methods: {
    searchCourses(event) {
      if (event.key === 'Enter') {
        this.fetchCategory();
      }
    },
    fetchCategory(
      filter = {
        id: this.$route.params.id,
        search_course: this.search,
      },
    ) {
      this.$store
        .dispatch(FETCH_CATEGORY, filter)
        .then(() => (this.isLoadingCategory = false))
        .catch(() => {
          this.isLoadingCategory = false;
          console.log(filter);
          this.notify(
            'danger',
            'Tenemos problemas para cargar los datos de la categoria',
          );
        });
    },
    openDeleteCategoryCourseDialog(course) {
      this.selectedCourseCategoryCourse = course;
      this.isOpenDeleteCategoryCourseDialog = true;
    },
    closeDeleteCategoryCourseDialog() {
      this.selectedCourseCategoryCourse = undefined;
      this.isOpenDeleteCategoryCourseDialog = false;
    },
    async deleteCategoryCourse() {
      await this.$store
        .dispatch(DELETE_CATEGORY_RELATION, {
          categoryId: this.$route.params.id,
          courseId: this.selectedCourseCategoryCourse.id,
        })
        .then(() => {
          this.fetchCategory();
          this.notify('success', 'Curso eliminado correctamente');
          this.closeDeleteCategoryCourseDialog();
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para eliminar el curso '),
        );
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style scoped>
.categories {
  border: none !important;
}

.dropdown-item {
  color: #000;
}
a.dropdown-item-edit {
  font-size: 14px !important;
  color: #000 !important;
  text-decoration: none;
}
</style>
