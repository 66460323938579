<template>
  <div class="container-fluid main-container__listcoupon">
    <div>
      <div class="row">
        <div
          class="col-xs-courses col-sm-8 col-md-8 col-lg-8 col-lg-8 col-xl-8"
        >
          <div class="filter-flex">
            <i class="fas fa-search fa-flip-horizontal"></i>
            <input
              v-model="searchValue"
              aria-label="Search"
              class="form-control inputFilter md-filter-input me-2"
              placeholder="Buscar por nombre"
              type="text"
              @keydown="searchCoupon"
            />
            <div class="md-layout md-gutter">
              <div class="md-layout-item">
                <md-field class="sort-box">
                  <md-select
                    v-model="sortType"
                    class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                  >
                    <md-option hidden selected value="">Filtrar por</md-option>
                    <md-option value="DESC">Nuevo</md-option>
                    <md-option value="ASC">Antiguo</md-option>
                    <md-option value="ASC_code">A-Z</md-option>
                    <md-option value="DESC_code">Z-A</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xs-courses col-sm-4 col-md-4 col-lg-4 col-lg-4 col-xl-4 pl-3"
        >
          <button
            class="btn btn-course btn-course__librery m-0 float-right"
            @click="openCouponDialog()"
          >
            Crear nuevo cupon
          </button>
        </div>
      </div>

      <div class="coupons-list">
        <el-table
          :data="getCoupons"
          class="table-responsive table-flush coupon-single"
          header-row-class-name="thead-light"
        >
          <el-table-column
            label="Código"
            min-width="100px"
            prop="code"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    <div class="coupon-code">
                      <i class="fas fa-ticket-alt"></i>
                      <h3 class="mb-0 ml-2">{{ row.code }}</h3>
                    </div>
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Descuento" prop="discoun" sortable>
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    <div class="coupon-discount">{{ row.discount }}%</div>
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Expiración"
            min-width="150px"
            prop="expiration"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    <div class="coupon-expiration">
                      {{ getDateFormat(row.expiration) }}
                    </div>
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Estado" prop="enable" sortable>
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    <div class="coupon-enable">
                      <badge
                        v-if="
                          row.enable &&
                          (isValidDate(row.expiration) || !row.expiration)
                        "
                        class="bagde-publish"
                        >Activo</badge
                      >
                      <badge v-else class="bagde-draft">Inactivo</badge>
                    </div>
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            label="Aplica"
            min-width="100px"
            prop="enable"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    <div class="coupon-enable">
                      <span v-if="row.courses.length === 1">{{
                        row.courses[0].name
                      }}</span>
                      <span v-else-if="row.courses.length > 1"
                        >Ciertos cursos</span
                      >
                      <span v-else>Todos los cursos</span>
                    </div>
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label min-width="70px" prop="edit">
            <template #default="{ row }">
              <span class="float-right mt-1">
                <md-menu :md-offset-x="-127" :md-offset-y="-20" md-size="auto">
                  <md-button md-menu-trigger>•••</md-button>
                  <md-menu-content>
                    <md-menu-item>
                      <span
                        class="pr-5 cursor"
                        href
                        @click="openCouponDialog(row)"
                      >
                        Editar
                      </span>
                    </md-menu-item>
                    <md-menu-item>
                      <span
                        class="pr-5 cursor"
                        href
                        @click="openDeleteModal(row)"
                      >
                        Eliminar
                      </span>
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div
          v-if="totalPages > 1"
          class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
    </div>
    <coupon-dialog
      :is-open="isOpenCouponDialog"
      :selected-coupon="selectedCoupon"
      @success="refetchCoupons"
      @close-modal="closeCouponDialog"
    />
    <delete-dialog
      :is-open="isOpenDeleteCouponDialog"
      @deleted="deleteCoupon"
      @close-modal="closeDeleteCouponDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import 'moment-duration-format';
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';

moment.locale('es');
import { FETCH_COUPONS, DELETE_COUPON } from '../../../../store/actions/coupon';
import Badge from '../../../../components/Badge.vue';
import CouponDialog from './components/CouponDialog';
import BasePagination from '../../../../components/BasePagination';
import DeleteDialog from '../components/DeleteDialog.vue';

export default {
  components: {
    DeleteDialog,
    Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    CouponDialog,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data: function () {
    return {
      isOpenDeleteCouponDialog: false,
      isLoading: true,
      sortType: '',
      searchValue: null,
      isOpenCouponDialog: false,
      selectedCoupon: undefined,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getCoupons']),
    totalPages() {
      return Math.ceil(this.getCoupons.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.isLoading = true;
      this.fetchCoupons({
        search: this.searchValue,
        per_page: 20,
        order_by: newValue,
      });
    },
  },
  mounted() {
    this.fetchCoupons();
  },
  methods: {
    openCouponDialog(coupon = undefined) {
      this.isOpenCouponDialog = true;
      this.selectedCoupon = coupon;
    },
    closeCouponDialog() {
      this.isOpenCouponDialog = false;
      this.selectedCoupon = undefined;
    },
    refetchCoupons() {
      this.isLoading = true;
      this.fetchCoupons();
    },
    fetchCoupons(
      filter = {
        search: this.searchValue,
        per_page: 20,
        order_by: this.sortType,
      },
    ) {
      this.$store
        .dispatch(FETCH_COUPONS, filter)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false));
    },
    getDateFormat(date) {
      return date
        ? moment.tz(date, 'America/New_York').format('MMMM D YYYY, h:mm:ss a')
        : 'Sin expiración';
    },
    isValidDate(date) {
      let now = new Date();
      let expiration = new Date(date);
      return expiration > now;
    },
    searchCoupon(event) {
      if (event.key === 'Enter') {
        this.refetchCoupons();
      }
    },
    deleteCoupon() {
      this.$store
        .dispatch(DELETE_COUPON, this.selectedCoupon)
        .then(() => {
          this.notify('success', 'Se ha eliminado el cupon correctamente');

          this.fetchCoupons();
          this.closeDeleteCouponDialog();
        })
        .catch(() => {
          this.notify('error', 'Tenemos problemas para eliminar el cupon');
        });
    },
    closeDeleteCouponDialog() {
      this.selectedCoupon = undefined;
      this.isOpenDeleteCouponDialog = false;
    },
    openDeleteModal(coupon) {
      this.selectedCoupon = coupon;
      this.isOpenDeleteCouponDialog = true;
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_COUPONS, {
        page: this.page,
        per_page: this.perPage,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.coupon-single {
  .coupon-code {
    display: flex;
    align-items: center;

    h2 {
      margin: 0 5px;
    }
  }
}

.code-div {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .form-group {
    margin: 0;
  }
}

.nomargin .form-group {
  margin: 0;
}

.main-container__listcoupon {
  .fa-search {
    position: absolute;
    margin-top: 16px;
    margin-left: 12px;
    font-size: 20px;
    color: #b7227e;
  }

  input {
    padding-left: 45px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    width: 370px;
    border-radius: 3px;
  }

  .filter {
    width: 100px;
    border-radius: 3px !important;
  }

  .expand-more__filter {
    margin-top: 11px;
  }
}

.btn-course__librery {
  padding: 11px 14px;
  font-size: 13px;
  text-transform: uppercase;
}

.coupons-list {
  margin-top: 25px;
  background: white;
  border-radius: 3px;

  span,
  .coupon-expiration,
  .coupon-discount {
    font-size: 15px;
  }

  .badge-publish,
  .bagde-draft,
  .badge-default {
    font-size: 13px;
  }
}

.refresh,
.refresh:hover {
  position: relative;
  color: white;
  background: #b7227e;
  border: 1px solid #b7227e;
  display: flex;
  width: 100%;
  height: 100%;

  i {
    color: white;
  }
}

.noUi-connect {
  background: #b7227e;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  background: #000;
}

.noUi-target {
  margin-top: 20px;
}

.select-coupon {
  .multiselect__tags {
    padding: 10px 40px 3px 12px;
  }

  .multiselect__single {
    padding-left: 0px;
  }

  .multiselect__select {
    height: 46px;
  }
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #b7227e;
  border-color: #b7227e;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  border-color: #b7227e;
  border-radius: 5px;
}

.custom-control-label::before,
.custom-control-label::after {
  border: 1px solid #dee2e6;
  border-radius: 5px !important;
}

.vertical-text label {
  padding-top: 3px;
}

.multiselect__select::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f078';
  font-size: 17px;
  border: none;
  top: 27%;
  color: #c1c1c1;
}
</style>
