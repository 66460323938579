<template>
  <md-dialog :md-active="open" class="modalEdit__instructors modal-categories">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">MODIFICAR CUPON</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row">
          <div v-if="!form.id" class="col-md-10 mt-2">
            <base-input
              v-model="form.code"
              placeholder="Inserta o genera el código del cupon"
            ></base-input>
          </div>
          <div v-if="!form.id" class="col-md-2 mt-4 refresh-button">
            <el-tooltip content="Generar código aleatorio" placement="top">
              <base-button
                class="refresh"
                type="primary"
                @click="generateRandomCode"
              >
                <i class="fas fa-sync-alt"></i>
              </base-button>
            </el-tooltip>
          </div>
          <div v-if="!form.id" class="col-md-6 mt-2">
            <base-input
              v-model="form.discount"
              placeholder="Descuento"
            ></base-input>
          </div>
          <div v-if="!form.id" class="col-md-6 mt-4">
            <base-slider v-model="form.discount" :range="{ min: 0, max: 100 }">
            </base-slider>
          </div>
          <div class="col-12 mt-4 select-coupon">
            <multiselect
              v-model="form.courses"
              :loading="isLoadingCourses"
              :multiple="true"
              :option-height="104"
              :options="getAllCourses.items"
              :show-labels="false"
              :taggable="false"
              class="border-0 select-config"
              label="name"
              placeholder="Lista de cursos"
              track-by="id"
              @search-change="searchCourse"
            >
              <template #option="props">
                <div class="option__desc">
                  <span class="option__title">{{
                    `${props.option.name}`
                  }}</span>

                  <span
                    v-if="
                      props.values &&
                      props.values.length &&
                      !props.values.isOpen
                    "
                    class="multiselect__single"
                    >{{ props.values.length }} options selected</span
                  >
                </div>
              </template>
              <template #noResult>
                <div>Sin resultados</div>
              </template>
              <template #noOptions>
                <div>Sin resultados</div>
              </template>
            </multiselect>
          </div>
          <div class="col-12 mt-4">
            <div class="row">
              <div class="col-md-6">
                <label class="col-form-label form-control-label pt-0"
                  >Fecha de expiración:
                </label>
                <base-checkbox
                  v-model="isHaveExpiration"
                  class="mb-3 vertical-text"
                >
                  Habilitado
                </base-checkbox>
              </div>
              <div class="col-md-6">
                <label class="col-form-label form-control-label pt-0"
                  >Habilitar cupón:
                </label>
                <base-checkbox v-model="form.enable" class="mb-3 vertical-text">
                  Habilitado
                </base-checkbox>
              </div>
            </div>
          </div>
          <div v-if="isHaveExpiration" class="col-md-12 mt-4">
            <base-input
              id="expiration-date"
              v-model="form.expiration"
              type="datetime-local"
            />
          </div>
        </div>
        <md-dialog-actions
          class="modal-footer"
          style="flex-direction: inherit !important"
        >
          <base-button
class="md-primary w-auto"
@click="closeModal"
            >CANCELAR
          </base-button>
          <base-button
            :loading="isLoading"
            class="md-primary w-auto"
            @click="onSubmit"
            >{{ `${form && form.id ? 'ACTUALIZAR' : 'CREAR'}` }}
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import BaseButton from '../../../../../components/BaseButton';
import Multiselect from 'vue-multiselect';
import moment from 'moment-timezone';
import {
  CREATE_COUPON,
  UPDATE_COUPON,
} from '../../../../../store/actions/coupon';
import { FETCH_COURSES } from '../../../../../store/actions/course';
import { mapGetters } from 'vuex';
import BaseSlider from '@/components/BaseSlider';

export default {
  name: 'CouponDialog',
  components: {
    BaseButton,
    BaseSlider,
    Multiselect,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedCoupon: {
      type: Object,
      default: undefined,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      isLoading: false,
      isLoadingCourses: true,
      isHaveExpiration: false,
      form: {
        id: '',
        code: '',
        discount: 0,
        courses: null,
        expiration: '',
        description: '',
        enable: true,
      },
    };
  },
  computed: {
    ...mapGetters(['getAllCourses']),
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  watch: {
    selectedCoupon: function (coupon) {
      if (coupon && coupon.id) {
        this.form = {
          id: coupon.id,
          code: coupon.code,
          discount: coupon.discount,
          courses: coupon.courses,
          expiration: coupon.expiration,
          description: coupon.description,
          enable: coupon.enable,
        };
      }
    },
  },
  mounted() {
    this.fetchCourses();
  },
  methods: {
    searchCourse(searchCourseQuery) {
      this.fetchCourses({ search: searchCourseQuery });
    },
    onSubmit() {
      let data = {
        code: this.form.code,
        enable: this.form.enable,
        discount: this.form.discount,
        description: this.form.description,
        expiration: this.form.expiration
          ? moment
              .tz(this.form.expiration, 'America/New_York')
              .format('YYYY-MM-DD HH:mm:ss')
          : null,
        courses: this.form.courses,
      };

      this.isLoading = true;

      if (this.form && this.form.id) {
        data = {
          ...data,
          id: this.form.id,
        };
        this.updateCoupon(data);
      } else {
        this.createCoupon(data);
      }
    },
    reset() {
      this.form = {
        id: '',
        code: '',
        discount: 0,
        courses: null,
        expiration: '',
        description: '',
        enable: true,
      };
      this.isHaveExpiration = false;
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    fetchCourses(filter = {}) {
      return this.$store
        .dispatch(FETCH_COURSES, filter)
        .then(() => (this.isLoadingCourses = false));
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    updateCoupon(data) {
      this.$store
        .dispatch(UPDATE_COUPON, data)
        .then(() => {
          this.closeModal();
          this.isLoading = false;
          this.$emit('success');
          this.notify('success', 'Se ha actualizado el cupon correctamente');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar el cupon');
          this.isLoading = false;
        });
    },
    createCoupon(data) {
      this.$store
        .dispatch(CREATE_COUPON, data)
        .then(() => {
          this.closeModal();
          this.isLoading = false;
          this.$emit('success');
          this.notify('success', 'Se ha creado el cupon correctamente');
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para crear el cupon');
          this.isLoading = false;
        });
    },
    generateRandomCode() {
      let result = '';
      let characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (let i = 0; i < Math.random() * (8 - 6) + 6; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        );
      }
      this.form.code = result.toUpperCase();
    },
  },
};
</script>

<style scoped></style>
