<template>
  <div class="container-fluid">
    <div>
      <div
        class="col-12 row justify-content-between container-partners__search pr-0 pl-0 ml-0 mb-3"
      >
        <div
          class="col-xs-mobile col-6 col-sm-6 col-md-7 col-lg-7 col-xl-7 pl-0"
        >
          <form class="d-flex form-partner">
            <i
              class="fas fa-search inputFilter md-filter-input fa-flip-horizontal"
            ></i>
            <input
              class="form-control inputFilter md-filter-input me-2"
              type="text"
              placeholder="Buscar por nombre o correo electrónico"
              aria-label="Search"
              v-model="searchValue"
              @keyup="filterTable(getInvitations)"
            />

            <a>
              <span
                :class="
                  isFilter
                    ? 'clear-position clear-view material-icons close'
                    : 'clear-position clear-hide material-icons close'
                "
                @click="clearFilter"
                >close</span
              >
            </a>

            <div class="md-layout md-gutter">
              <div class="md-layout-item" style="padding: 0px !important">
                <md-field class="sort-box select-partners">
                  <md-select
                    v-model="sortType"
                    class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                  >
                    <md-option value="" selected hidden>Filtrar por</md-option>
                    <md-option value="newest">Nuevo</md-option>
                    <md-option value="oldest">Antiguo</md-option>
                    <md-option value="asc">A-Z</md-option>
                    <md-option value="desc">Z-A</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </form>
        </div>

        <!--        Modal-->
        <div
          class="col-xs-mobile col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 pr-0"
        >
          <div>
            <md-dialog :md-active.sync="active" class="modal-close">
              <span
                class="material-icons close text-right"
                @click="onCancelIntivation()"
                >close</span
              >
              <div class="body-modal-course">
                <md-dialog-title>INVITAR SOCIO</md-dialog-title>

                <md-dialog-content class="mt-2">
                  <span
                    >Esto le dara a un nuevo usuario acceso al panel de
                    maquillate como socio.</span
                  ></md-dialog-content
                >

                <md-dialog-content>
                  <md-field>
                    <div
                      class="md-field md-theme-default md-has-placeholder pt-0"
                    >
                      <md-input
                        v-model="email"
                        placeholder="Correo electrónico del usuario"
                      ></md-input>
                    </div>
                  </md-field>
                </md-dialog-content>

                <md-dialog-actions>
                  <md-button
                    class="md-button md-primary md-theme-default"
                    @click="onCancelIntivation()"
                    >CANCELAR</md-button
                  >
                  <md-button
                    class="md-button md-primary md-theme-default"
                    @click="onConfirm(email)"
                    >INVITAR USUARIO</md-button
                  >
                </md-dialog-actions>
              </div>
            </md-dialog>

            <button class="btn btn-course float-right" @click="active = true">
              INVITAR SOCIO
            </button>
          </div>
        </div>
        <!--      END MODAL-->
      </div>
      <!-- Email Section  codigo eliminado de validation-observer=> (v-slot="{ handleSubmit }")-->
      <validation-observer ref="formValidator">
        <div class="container-partners">
          <md-table>
            <md-table-row class="container-partners__head">
              <md-table-head>Nombre</md-table-head>
              <md-table-head>Estado</md-table-head>
              <md-table-head>País</md-table-head>
              <md-table-head>Cursos</md-table-head>
              <md-table-head>Correo electrónico</md-table-head>
              <md-table-head></md-table-head>
            </md-table-row>

            <md-table-row
              v-for="partner in filterTable(getInvitations)"
              :key="partner.id"
              class="container-partners__cell"
            >
              <md-table-cell>{{ partner.name }}</md-table-cell>
              <md-table-cell>
                <!-- registrado = activo -->
                <badge
                  v-if="partner.registered_at"
                  type="success"
                  class="status-active"
                  >activo</badge
                >
                <badge v-else type="secondary" class="status-pending"
                  >Pendiente</badge
                >
              </md-table-cell>
              <md-table-cell>{{ partner.country }}</md-table-cell>
              <md-table-cell>
                <div class="d-flex flex-row bd-highlight">
                  <div
                    class="rounded-circle px-2 py-1 mr-1"
                    v-bind:class="[
                      partner.courses >= 1 ? 'coursesActives' : 'bg-light',
                    ]"
                    v-if="partner.courses >= 1"
                  >
                    {{ partner.courses }}
                  </div>
                  <div v-if="partner.courses >= 1" class="py-1">
                    <i
                      class="fas fa-bars"
                      role="button"
                      @click="handlePartnerCourse(partner.user_id)"
                    ></i>
                  </div>
                </div>
              </md-table-cell>
              <md-table-cell>{{ partner.email }}</md-table-cell>
              <md-table-cell class="container-partners__options">
                <span class="float-right">
                  <md-menu
                    md-size="auto"
                    :md-offset-x="-147"
                    :md-offset-y="-10"
                  >
                    <md-button md-menu-trigger>•••</md-button>

                    <md-menu-content v-if="!partner.registered_at">
                      <md-menu-item
                        :disabled="dateIsValid(partner.expiration)"
                        @click="resendPartnerInvitation($event, partner.email)"
                        type="primary"
                        >Renovar Invitación</md-menu-item
                      >
                      <md-menu-item
                        @click="removeInvitation($event, partner.id)"
                        type="primary"
                        >Remover Invitación</md-menu-item
                      >
                    </md-menu-content>
                    <md-menu-content v-else>
                      <md-menu-item
                        type="primary"
                        @click="updatePartner(partner)"
                        >Editar</md-menu-item
                      >
                      <md-menu-item
                        type="primary"
                        @click="deletePartner(partner)"
                        >Eliminar</md-menu-item
                      >
                    </md-menu-content>
                  </md-menu>
                </span>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </validation-observer>
    </div>
    <md-dialog
      :md-active.sync="modals.updatePartner"
      @close="handleClear"
      class="modalEdit__instructors"
    >
      <div class="w-100 position-fixed mobile-cancel">
        <span class="material-icons close text-right" @click="handleClear"
          >close</span
        >
      </div>
      <div class="body-modal-instructor modal-body">
        <div class="md-dialog-title text-left">
          <h3 class="text-bold">EDITAR SOCIO</h3>
        </div>
        <div class="form-instructor dialog-body">
          <div class="row">
            <div class="col-12">
              <base-input
                type="text"
                name="nombre"
                required
                placeholder="Nombre"
                :value="partner.name"
                v-model="partner.name"
              ></base-input>
            </div>
            <div class="col-12">
              <base-input
                type="text"
                name="email"
                required
                placeholder="Email"
                :value="partner.email"
                v-model="partner.email"
              ></base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <md-input
                v-model="model.password"
                type="password"
                @input="passwordValidation"
                id="model.passwords"
                placeholder="Contraseña"
                class="w-100 form-control mt-3"
                :strength="model.validPassword.strong"
              ></md-input>
            </div>
            <div class="col-12">
              <VuePassword
                v-model="model.c_password"
                class="control has-icons-left md-input"
                id="model.password"
                :strength="model.validPassword.strong"
                placeholder="Contraseña"
                type="password"
                @input="passwordValidation"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-2">
              <select
                class="form-control py-2 my-2"
                required
                name="pais"
                v-model="partner.countries_id"
                aria-label="Default select example"
              >
                <option disabled selected>Selecciona un pais</option>
                <option
                  v-for="value in getAllCountries"
                  :key="value.id"
                  :value="value.id"
                >
                  ({{ value.code }}) &nbsp; {{ value.name }}
                </option>
              </select>
              <div class="col-12 dropdwon-icon-instructor">
                <i class="fas fa-chevron-down instructor float-right"></i>
              </div>
            </div>
          </div>
          <h4 class="mt-4 text-bold text-left">
            FOTO DEL ENCABEZADO DEL SOCIO
          </h4>
          <p class="text-muted">
            Para el mejor resultado, usar imagen rectangular 6720 x 2801 px.
          </p>
          <div class="dropzone-header-instructor">
            <md-field class="pt-0">
              <span
                v-if="!preview.thumbnail_header.view"
                class="material-icons add-photo"
              >
                add_a_photo
              </span>
              <md-file
                :style="{
                  backgroundImage: `url('${imgInstructorHead}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                  borderRadius: '0.25rem',
                }"
                @md-change="changeImg($event, 'thumbnail_header')"
                accept="image/*"
                class="design-dropzone"
              />
            </md-field>
          </div>

          <h4 class="mt-4 text-bold text-left">FOTO VERTICAL DEL SOCIO</h4>
          <p class="text-muted">
            Para el mejor resultado, usar imagen vertical 260 x 444 px.
          </p>
          <div class="dropzone-header-instructor-vertical">
            <md-field class="pt-0">
              <span
                v-if="!preview.thumbnail_vertical.view"
                class="material-icons add-photo"
              >
                add_a_photo
              </span>
              <md-file
                :style="{
                  backgroundImage: `url('${imgInstructorVertical}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                  borderRadius: '0.25rem',
                }"
                @md-change="changeImg($event, 'thumbnail_vertical')"
                accept="image/*"
                class="design-dropzone"
              />
            </md-field>
          </div>

          <h4 class="mt-4 text-bold text-left">FOTO DEL PERFIL DEL SOCIO</h4>
          <p class="text-muted">
            Para el mejor resultado, usar imagen cuadrada máxima de 200 x 200
            px.
          </p>
          <div class="dropzone-profile-instructor">
            <md-field class="pt-0 d-flex justify-content-center">
              <span
                v-if="!preview.avatar.view"
                class="material-icons add-photo"
              >
                add_a_photo
              </span>
              <md-file
                :style="{
                  backgroundImage: `url('${imgInstructorProfile}')`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                  borderRadius: '0.25rem',
                }"
                @md-change="changeImg($event, 'avatar')"
                accept="image/*"
                class="design-dropzone"
              />
            </md-field>
          </div>
        </div>
        <md-dialog-actions class="modal-footer">
          <base-button
            type="secondary"
            class="md-primary w-auto"
            @click="handleClear"
            >CANCELAR</base-button
          >
          <base-button
            @click="handleUpdatePartner"
            type="primary"
            class="md-primary w-auto"
            >ACTUALIZAR</base-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
    <!-- <modal :show.sync="modals.updatePartner" size="lg" @close="handleClear">
      <div class="text-center">
        <p class="h3"><b> Editar Socio:</b> {{ partner.name }}</p>
      </div>
      <div class="row">
        <div class="col-12">
          <base-input
            type="text"
            name="nombre"
            required
            placeholder="Nombre"
            :value="partner.name"
            v-model="partner.name"
          ></base-input>
        </div>
        <div class="col-12">
          <base-input
            type="text"
            name="email"
            required
            placeholder="Email"
            :value="partner.email"
            v-model="partner.email"
          ></base-input>
        </div>

      </div>
        <div class="row">
          <div class="col-12">
            <md-input
              v-model="model.password"
              type="password"
              @input="passwordValidation"
              id="model.passwords"
              :strength="model.validPassword.strong"
            ></md-input>
          </div>
           <div class="col-12">
            <VuePassword
          v-model="model.c_password"
          class="control has-icons-left md-input"
          id="model.password"
          :strength="model.validPassword.strong"
          placeholder="Contraseña"
          type="password"
          @input="passwordValidation"
        />
          </div>
        </div>


      <div class="row">
        <div class="col-md-12">
          <select
            class="form-control py-2 my-2"
            required
            name="pais"
            v-model="partner.countries_id"

            aria-label="Default select example"
          >
            <option selected value="0" disabled>Selecciona un pais</option>
            <option
              v-for="value in getAllCountries"
              :key="value.id"
              :value="value.id"
            >
              ({{ value.code }}) &nbsp; {{ value.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="handleUpdatePartner">
          Actualizar
        </button>
        <button class="btn btn-secondary" @click="handleClear">Cancelar</button>
      </div>
    </modal> -->
    <md-dialog
      :md-active.sync="modals.deletePartner"
      class="modal-close"
      @close="handleClear"
    >
      <span class="material-icons close text-right" @click="handleClear"
        >close</span
      >
      <div class="body-modal-course">
        <md-dialog-content class="mt-2">
          <span
            >¿Esta seguro de eliminar a {{ partner.name }}?</span
          ></md-dialog-content
        >

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handleClear"
            >CANCELAR</md-button
          >
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handleDeletePartner"
            >Eliminar</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
//import Modal from "@/components/Modal.vue";
import {
  USER_INVITE_PARTNER,
  USER_REMOVE_INVITE_PARTNER,
} from '@/store/actions/register';
import {
  USER_GET_PARTNERS,
  USER_INVITATION_STATUS,
} from '@/store/actions/user';

import { UPDATE_PARTNER, DELETE_PARTNER } from '@/store/actions/partner';
import VuePassword from 'vue-password';
import { mapGetters } from 'vuex';

export default {
  name: 'ListPartner',
  // name: 'DialogPrompt',
  components: {
    //Modal,
    VuePassword,
  },
  watch: {
    sortType: function () {
      this.sortTable();
    },
    searchValue: function () {
      if (this.searchValue == '' || this.searchValue == null) {
        this.isFilter = false;
      } else {
        this.isFilter = true;
      }
    },
  },
  data: () => ({
    searchValue: null,
    sortType: '',
    isFilter: false,
    active: false,
    value: null,
    modals: {
      updatePartner: false,
      deletePartner: false,
    },
    email: null,
    partner: {},
    model: {
      name: '',
      email: '',
      password: '',
      c_password: '',
      agree: false,
      country: '',
      rules: [
        { message: 'One lowercase letter required.', regex: /[a-z]+/ },
        { message: 'One uppercase letter required.', regex: /[A-Z]+/ },
        { message: 'One number required.', regex: /[0-9]+/ },
        { message: '8 characters minimum.', regex: /.{8,}/ },
        { message: '11 characters minimum.', regex: /.{11,}/ },
      ],
      validPassword: {
        strong: 0,
        message: '',
        alert: '',
      },
      errorMessage: null,
    },
    preview: {
      avatar: {
        view: false,
        img: '',
      },
      thumbnail_header: {
        view: false,
        img: '',
      },
      thumbnail_vertical: {
        view: false,
        img: '',
      },
    },
  }),
  methods: {
    async load() {
      await this.$store.dispatch(USER_INVITATION_STATUS);
    },
    filterTable(array) {
      console.log(array);
      let table = null;

      if (this.searchValue) {
        table = array.filter((obj) => {
          if (obj.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
            return true;
          }
          if (
            obj.email.toLowerCase().includes(this.searchValue.toLowerCase())
          ) {
            return true;
          }
        });
      } else {
        table = array;
      }

      return table;
    },
    clearFilter() {
      this.searchValue = '';
    },
    sortTable() {
      let table = null;
      let array = this.getInvitations;
      let type = this.sortType;

      if (type == 'newest' || type == null) {
        table = array.sort((a, b) => {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          return 0;
        });
      }

      if (type == 'oldest') {
        table = array.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });
      }

      if (type == 'desc') {
        table = array.sort((a, b) => {
          let tituloA = a.name.toUpperCase();
          let tituloB = b.name.toUpperCase();
          if (tituloA < tituloB) {
            return 1;
          }
          if (tituloA > tituloB) {
            return -1;
          }
          return 0;
        });
      }

      if (type == 'asc') {
        table = array.sort((a, b) => {
          let tituloA = a.name.toUpperCase();
          let tituloB = b.name.toUpperCase();
          if (tituloA > tituloB) {
            return 1;
          }
          if (tituloA < tituloB) {
            return -1;
          }
          return 0;
        });
      }
    },
    handleClear() {
      this.modals.updatePartner = false;
      this.modals.deletePartner = false;
      this.model.password = '';
      this.model.c_password = '';
      this.partner = {};
      this.preview = {
        avatar: {
          view: false,
          img: '',
        },
        thumbnail_header: {
          view: false,
          img: '',
        },
        thumbnail_vertical: {
          view: false,
          img: '',
        },
      };
    },
    handlePartnerCourse(id) {
      this.$router.push(`partners/courses/${id}`);
    },
    async handleUpdatePartner() {
      const formData = new FormData();
      //e.target.setAttribute('disabled', true)
      const { thumbnail_header, thumbnail_vertical, avatar, ...data } =
        this.partner;
      if (thumbnail_header)
        formData.append('thumbnail_header', thumbnail_header);
      if (thumbnail_vertical)
        formData.append('thumbnail_vertical', thumbnail_vertical);
      if (avatar) formData.append('avatar', avatar);
      formData.append('name', data.name);
      formData.append('email', data.email);
      formData.append(
        'countries_id',
        data.countries_id ? data.countries_id : 1,
      );
      formData.append('password', this.model.password);
      formData.append('user_id', data.user_id);
      // console.log(formData)
      // return
      // console.log(this.partner.imgProfile)
      // return
      await this.$store
        .dispatch(UPDATE_PARTNER, {
          // name: this.partner.name,
          // email:this.partner.email,
          // countries_id: this.partner.countries_id,
          // password: this.model.password,
          // user_id: this.partner.user_id,
          // avatar: this.partner.imgProfile ? this.partner.imgProfile : null,
          // thumbnail_header: this.partner.imgHead ? this.partner.imgHead : null
          formData,
        })
        .then((resp) => {
          this.handleClear();

          this.$store.dispatch(USER_INVITATION_STATUS);
          this.$notify({
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Actualizado con éxito',
          });
        })
        .catch((err) => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'El socio no pudo ser actualizado',
          });
        });
    },
    async handleDeletePartner() {
      /*console.log(this.partner.id)
        return*/
      await this.$store
        .dispatch(DELETE_PARTNER, {
          id: this.partner.user_id,
        })
        .then((resp) => {
          this.handleClear();
          this.$store.dispatch(USER_INVITATION_STATUS);
          this.$notify({
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Eliminado con éxito',
          });
        })
        .catch((err) => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'El socio no pudo ser eliminado',
          });
        });
    },
    deletePartner(value) {
      this.modals.deletePartner = true;
      this.partner = value;
    },
    updatePartner(value) {
      console.log('partner', value);
      this.modals.updatePartner = true;
      this.partner = value;
      this.preview = {
        avatar: {
          view: !!value.avatar,
          img: value.avatar || null,
        },
        thumbnail_header: {
          view: !!value.thumbnail_header,
          img: value.thumbnail_header || null,
        },
        thumbnail_vertical: {
          view: !!value.thumbnail_vertical,
          img: value.thumbnail_vertical || null,
        },
      };
    },
    onCancelIntivation() {
      this.active = false;
      this.email = '';
    },
    onConfirm(val) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val))
        this.sendPartnerInvitation(val);
      else {
        this.$notify({
          type: 'danger',
          verticalAlign: 'top',
          horizontalAlign: 'right',
          message: 'Email inválido',
        });
      }
    },
    onCancel() {
      this.value = 'Disagreed';
    },
    resendPartnerInvitation(e, email) {
      let data = {
        email: email,
      };
      e.target.setAttribute('disabled', true);
      this.$store
        .dispatch(USER_INVITE_PARTNER, data)
        .then((resp) => {
          this.$notify({
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: resp.data.message,
          });
        })
        .catch((err) => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: err.response.data.message,
          });
        })
        .finally(() => {
          e.target.removeAttribute('disabled');
        });
    },
    sendPartnerInvitation(email) {
      let data = { email };
      // e.target.setAttribute("disabled", true);
      this.$store
        .dispatch(USER_INVITE_PARTNER, data)
        .then((resp) => {
          this.$notify({
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: resp.data.message,
          });
        })
        .catch((err) => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: err.response.data.message,
          });
        });
    },
    removeInvitation(e, id) {
      let data = {
        id: id,
      };

      e.target.setAttribute('disabled', true);
      this.$store
        .dispatch(USER_REMOVE_INVITE_PARTNER, data)
        .then((resp) => {
          this.$notify({
            type: 'success',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: resp.data.message,
          });
        })
        .catch((err) => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Error al remover invitación',
          });
        })
        .finally(() => {
          e.target.removeAttribute('disabled');
        });
    },
    dateIsValid(date) {
      let now = new Date();
      let expiration = new Date(date);
      if (expiration > now) return true;
      return false;
    },
    passwordValidation() {
      let errors = [];
      let strongpassword =
        /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;

      this.model.c_password == this.model.password;

      for (let condition of this.model.rules) {
        if (!condition.regex.test(this.model.password)) {
          errors.push(condition.message);
        }
      }

      switch (errors.length) {
        case 0: {
          this.model.validPassword.strong = 3;
          this.model.validPassword.message = 'Segura';
          this.model.validPassword.alert = 'text-info';
          break;
        }
        case 1: {
          this.model.validPassword.strong = 3;
          this.model.validPassword.message = 'Segura';
          this.model.validPassword.alert = 'text-info';
          break;
        }
        case 2: {
          this.model.validPassword.strong = 2;
          this.model.validPassword.message = 'Medio';
          this.model.validPassword.alert = 'text-warning';
          break;
        }
        case 3: {
          this.model.validPassword.strong = 1;
          this.model.validPassword.message = 'Debil';
          this.model.validPassword.alert = 'text-warning';
          break;
        }
        default: {
          this.model.validPassword.strong = 0;
          break;
        }
      }

      // Strong password
      if (strongpassword.test(this.model.password)) {
        this.model.validPassword.strong = 4;
        this.model.validPassword.message = 'Muy segura';
        this.model.validPassword.alert = 'text-success';
      }
    },
    changeImg(e, type) {
      this.partner[type] = e[0];
      console.log(this.partner[type]);
      const reader = new FileReader();
      reader.onload = (ev) => {
        this.preview[type].img = ev.target.result;
        this.preview[type].view = true;
      };
      reader.readAsDataURL(e[0]);
    },
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters(['getInvitations', 'getAllCountries']),
    validateEmail: function () {
      return this.partner.email && this.partner.email.length < 0;
    },
    validateFields: function () {
      if (
        this.model.validPassword.strong > 2 &&
        this.model.validPassword.strong !== 0 &&
        this.model.password === this.model.c_password &&
        this.model.password.length > 7 &&
        this.partner.email.length != 0 &&
        this.partner.name.length != 0
      ) {
        return false;
      }
      return true;
    },
    imgInstructorHead: function () {
      return this.preview.thumbnail_header.img;
    },
    imgInstructorProfile: function () {
      return this.preview.avatar.img;
    },
    imgInstructorVertical: function () {
      return this.preview.thumbnail_vertical.img;
    },
  },
};
</script>
