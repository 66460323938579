var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8 mb-2 text-left px-3"},[_c('h2',{staticClass:"title-course-detail"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])]),_c('div',{staticClass:"col-4 mb-2 text-right share-mobile-center"},[_c('h2',{staticClass:"show-courses__share-fav"},[(_vm.data && _vm.data.id)?[_c('span',[_c('i',{staticClass:"pr-3 fav-icon-course",class:{
            'fas fa-heart': _vm.isFavorite,
            'far fa-heart': !_vm.isFavorite,
          },on:{"click":function($event){return _vm.addCourseFavorite()}}})])]:_vm._e(),_c('span',{staticClass:"sharebutton",on:{"click":function($event){return _vm.openShareCourseVideoDialog()}}},[_c('i',{staticClass:"fas fa-share"}),_c('span',{staticClass:"pl-2 share hide-in-mobile"},[_vm._v("COMPARTIR")])])],2)]),_c('div',{staticClass:"col-12 mb-2 text-left px-3"},[_c('p',{staticClass:"px-0 pt-2 text-left",domProps:{"innerHTML":_vm._s(_vm.data.content)}})]),_c('div',{staticClass:"col-12 row container-buttons-see-course"},[_c('div',{staticClass:"col-sm-12 mx-auto mx-lg-0 text-lg-left pl-lg-0 text-center"},_vm._l((_vm.data.files),function(file){return _c('a',{key:file.id,staticStyle:{"text-decoration":"none"},attrs:{"href":file.url,"target":"_blank"}},[(_vm.data.files.length)?_c('button',{staticClass:"btn show-courses__btn-products text-white mt-2 mb-3 font-button-product"},[_c('i',{staticClass:"far fa-arrow-alt-circle-down"}),_vm._v(" DESCARGAR PDF ")]):_vm._e()])}),0),_c('div',{staticClass:"col-sm-12 mx-auto mx-lg-0 text-lg-left pl-lg-0 text-center"},[(_vm.data.products.length)?_c('button',{staticClass:"btn show-courses__btn-products text-white mt-2 mb-3 font-button-product",on:{"click":function($event){_vm.showProducts = !_vm.showProducts}}},[_c('i',{staticClass:"fas fa-shopping-cart"}),_vm._v(" PRODUCTOS DESTACADOS ")]):_vm._e()])]),(_vm.showProducts)?_c('div',{staticClass:"col-12 mt-4 cards-instructors mb-2"},[_c('carousel',{attrs:{"navigation-enabled":true,"per-page":1,"per-page-custom":[
      [545, 2],
      [825, 3],
      [1200, 4],
    ],"scroll-per-page":true,"speed":500,"navigation-next-label":"<i class='fas fa-chevron-right'></i>","navigation-prev-label":"<i class='fas fa-chevron-left'></i>"}},_vm._l((_vm.products),function(product){return _c('slide',{key:_vm.products.id,staticClass:"d-flex flex-column flex-card-display",staticStyle:{"flex-basis":"inherit"}},[_c('card-product-course',{staticClass:"card-class-width mx-1 card-h-w",attrs:{"data":product}})],1)}),1)],1):_vm._e(),_c('share-course-video-dialog',{attrs:{"course":_vm.course,"is-open":_vm.isOpenShareCourseDialog},on:{"close-modal":_vm.closeShareCourseVideoDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }