<template>
  <md-dialog :md-active="open" class="modalEdit__instructors modal-categories">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">EDITAR CATEGORIA</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row">
          <div class="col-md-12">
            <base-input
              v-model="formData.title"
              name="name"
              placeholder="Coloca el nuevo nombre"
            ></base-input>
          </div>
        </div>

        <div class="col-md-12 p-0 mt-2">
          <multiselect
            v-model="formData.categoryParent"
            :loading="isLoading"
            :options="getProductsCategories.items"
            :show-labels="false"
            class="border-0 select-config"
            label="title"
            placeholder="Selecciona una Categoría padre"
            track-by="id"
          >
            <template #option="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.title }}</span>
                <span
                  v-if="
                    props.values && props.values.length && !props.values.isOpen
                  "
                  class="multiselect__single"
                  >{{ props.values.length }} options selected</span
                >
              </div>
            </template>
            <template #noOptions>La lista esta vacía</template>
            <template #noResult>
              <div>Sin resultados</div>
            </template>
          </multiselect>
        </div>
        <div class="col-md-12 px-0">
          <textarea
            v-model="formData.description"
            class="form-control mt-3"
            placeholder="Descripción"
            rows="10"
          ></textarea>
        </div>
        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia la imagen de la categoria</label>
            <input
              id="file-input"
              accept="image/*"
              type="file"
              @change="uploadCategoryImage"
            />
          </md-field>
        </div>

        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia la imagen principal de la categoria</label>
            <input
              id="file-input-seo"
              accept="image/*"
              type="file"
              @change="uploadSeoImage"
            />
          </md-field>
        </div>

        <div class="col-md-6">
          <md-field class="d-none">
            <label>Cambia el icono de la categoria</label>
            <input
              id="icon-input"
              accept="image/*"
              type="file"
              @change="uploadIcon"
            />
          </md-field>
        </div>

        <div class="row mt-2">
          <div class="col-sm-6 col-md-6">
            <label>Selecciona una imagen</label>
          </div>

          <div class="col-sm-6 col-md-6">
            <label>Selecciona un icono</label>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-sm-6 col-md-6">
            <div class="imageContainer">
              <img
                v-if="
                  this.formData.categoryFile && this.formData.categoryFile.url
                "
                :src="this.formData.categoryFile.url"
                alt="productImage"
                class="imageToUpload"
              />
              <img
                v-else
                alt="productImage"
                class="imageToUpload"
                src="@/assets/images/logo/placeholder.png"
              />
              <div class="textUploadImage" @click="openInputFile">
                <p>Sube la imagen del curso</p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-md-6">
            <div class="imageContainer bg-dark">
              <img
                v-if="
                  this.formData.categoryIconFile &&
                  this.formData.categoryIconFile.url
                "
                :src="this.formData.categoryIconFile.url"
                alt="productImage"
                class="imageToUpload"
              />
              <img
                v-else
                alt="productImage"
                class="imageToUpload"
                src="@/assets/images/logo/placeholder.png"
              />
              <div class="textUploadImage" @click="openInputIcon">
                <p>Sube un icono</p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left my-3">
          <h3 class="text-bold">SEO</h3>
          <h4 class="text-bold">FOTO PRINCIPAL</h4>
        </div>
        <div class="col-md-6 mt-2 pl-0">
          <div class="imageContainer">
            <img
              v-if="
                this.formData.categorySeoFile &&
                this.formData.categorySeoFile.url
              "
              :src="this.formData.categorySeoFile.url"
              alt="productImage"
              class="imageToUpload"
            />
            <img
              v-else
              alt="productImage"
              class="imageToUpload"
              src="@/assets/images/logo/placeholder.png"
            />
            <div class="textUploadImage" @click="openInputFileSeo">
              <p>Sube la imagen principal</p>
            </div>
          </div>
        </div>
        <div class="text-left mt-3">
          <h4 class="text-bold mb-0">TITULO</h4>
        </div>
        <div class="col-md-12 px-0">
          <base-input
            v-model="formData.seoTitle"
            name="name"
            placeholder="Coloca el Titulo"
          ></base-input>
        </div>
        <div class="text-left mt-3">
          <h4 class="text-bold mb-0">DESCRIPCIÓN</h4>
        </div>
        <div class="col-md-12 px-0">
          <textarea
            v-model="formData.seoDescription"
            class="form-control mt-3"
            placeholder="Descripción"
            rows="10"
          ></textarea>
        </div>
        <md-dialog-actions class="modal-footer">
          <md-button
class="md-primary w-auto"
@click="closeModal"
            >CANCELAR
          </md-button>
          <base-button
            :loading="isLoading"
            class="md-primary w-auto"
            type="submit"
            @click="updateProductCategory"
            >ACTUALIZAR
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_PRODUCT_CATEGORY } from '../../../../store/actions/products';
import BaseButton from '../../../../components/BaseButton';
import Multiselect from 'vue-multiselect';

export default {
  name: 'UpdateProductCategoryDialog',
  components: {
    BaseButton,
    Multiselect,
  },
  props: {
    selectedProductCategory: {
      type: Object,
      default: undefined,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      formData: {
        title: '',
        description: '',
        categoryFile: {
          url: '',
        },
        categoryIconFile: {
          url: '',
        },
        categorySeoFile: {
          url: '',
        },
        seoTitle: '',
        seoDescription: '',
        categoryParent: '',
      },
      isLoading: false,
      isLoadingCourses: true,
      categoryIconFile: undefined,
      categoryImageFile: undefined,
      categorySeoImageFile: undefined,
      categoryParents: [],
    };
  },
  computed: {
    ...mapGetters(['getProductsCategories']),
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  watch: {
    selectedProductCategory: function (newValue) {
      if (newValue) {
        this.formData = {
          id: newValue.id,
          title: newValue.title,
          description: newValue.description,
          categoryFile: newValue.productCategoryFile
            ? newValue.productCategoryFile
            : { url: '' },
          categoryIconFile: newValue.productCategoryIconFile
            ? newValue.productCategoryIconFile
            : { url: '' },
          categorySeoFile: newValue.productCategorySeoFile
            ? newValue.productCategorySeoFile
            : { url: '' },
          seoTitle: newValue.seoTitle ?? '',
          seoDescription: newValue.seoDescription ?? '',
          categoryParent: newValue.category_parent
            ? newValue.category_parent
            : { id: '' },
        };
      } else this.reset();
    },
  },
  methods: {
    validateCategoryProductForm() {
      const errors = [];

      if (!this.formData.title) errors.push('El titulo es requerido');
      if (!this.formData.description)
        errors.push('La Descripción es requerida');

      return errors;
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    reset() {
      this.formData = {
        title: '',
        description: '',
        categoryFile: {
          url: '',
        },
        categoryIconFile: {
          url: '',
        },
        categorySeoFile: {
          url: '',
        },
        seoTitle: '',
        seoDescription: '',
        categoryParent: '',
      };
      this.categoryIconFile = undefined;
      this.categoryImageFile = undefined;
      this.categorySeoImageFile = undefined;
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    updateProductCategory() {
      const errors = this.validateCategoryProductForm();
      if (errors.length) return this.notify('danger', errors.join('<br/>'));

      const data = new FormData();
      data.append('id', this.formData.id ? this.formData.id : '');
      data.append('title', this.formData.title ? this.formData.title : '');
      data.append(
        'description',
        this.formData.description ? this.formData.description : '',
      );
      data.append(
        'seo_title',
        this.formData.seoTitle ? this.formData.seoTitle : '',
      );
      data.append(
        'seo_description',
        this.formData.seoDescription ? this.formData.seoDescription : '',
      );
      data.append(
        'category_parent',
        this.formData.categoryParent.id ? this.formData.categoryParent.id : '',
      );

      if (this.iconFile) {
        data.append('product_category_icon_file', this.iconFile);
      }

      if (this.categoryImageFile) {
        data.append('product_category_file', this.categoryImageFile);
      }

      if (this.categorySeoImageFile) {
        data.append('product_category_seo_file', this.categorySeoImageFile);
      }

      this.isLoading = true;

      this.$store
        .dispatch(UPDATE_PRODUCT_CATEGORY, data)
        .then(() => {
          this.isLoading = false;
          this.$emit('success');
          this.notify(
            'success',
            'Se ha actualizado la categoria correctamente',
          );
          this.closeModal();
        })
        .catch(() => {
          this.notify(
            'danger',
            'Tenemos problemas para actualizar la categoria',
          );
          this.isLoading = false;
        });
    },
    uploadIcon(event) {
      const files = event.target.files;
      this.formData.categoryIconFile.url = URL.createObjectURL(files[0]);
      this.iconFile = files[0];
    },
    uploadCategoryImage(event) {
      const files = event.target.files;
      this.formData.categoryFile.url = URL.createObjectURL(files[0]);
      this.categoryImageFile = files[0];
    },
    uploadSeoImage(event) {
      const files = event.target.files;
      this.formData.categorySeoFile.url = URL.createObjectURL(files[0]);
      this.categorySeoImageFile = files[0];
    },
    openInputFile() {
      document.getElementById('file-input').click();
    },
    openInputFileSeo() {
      document.getElementById('file-input-seo').click();
    },
    openInputIcon() {
      document.getElementById('icon-input').click();
    },
  },
};
</script>

<style>
.multiselect__option--highlight {
  background: #000;
  outline: none;
  outline: none;
  color: #fff !important;
}
.multiselect__input,
.multiselect__single {
  margin-bottom: 0px;
  min-height: 30px;
}
.multiselect {
  outline: none;
  border: 0px;
  border-radius: 5px;
}

.multiselect__option--highlight {
  background: #000 !important;
  outline: none;
  color: #fff;
}
</style>
