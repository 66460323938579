var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-table-row',{staticClass:"container-instructors__cell pt-2 pb-2"},[_c('md-table-cell',{staticClass:"skeleton-instructor-name-cell"},[_c('skeleton-loading',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '50px',
              width:'50px'
              },"count":1}})],1),_c('div',{staticClass:"mt-3"},[_c('skeleton-square',{attrs:{"box-properties":{
              height: '20px',
              width:'200px'
              },"count":1}})],1)])])],1),_c('md-table-cell',[_c('skeleton-loading',[_c('skeleton-square',{attrs:{"box-properties":{
              height: '20px',
              width:'60px'
              },"count":1}})],1)],1),_c('md-table-cell',[_c('skeleton-loading',[_c('skeleton-square',{attrs:{"box-properties":{
              height: '20px',
              width:'100px'
              },"count":1}})],1)],1),_c('md-table-cell',{staticClass:"skeleton-instructor-action-cell"},[_c('skeleton-loading',[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '20px',
              width:'20px'
              },"count":1}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }