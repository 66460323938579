<template>
  <video-player
    ref='video-player'
    :options='videoOptions'
    :video='data'
    @start-video='startVideo'
    @init-video='initVideo'
    @end-video='endVideo'
    @current-time-video='currentTimeVideo'
  />
</template>

<script>
import VideoPlayer from '../../../../components/VideoPlayer';
import { CHANGE_COURSE_VIDEO_USER } from '../../../../store/actions/course';
import { mapGetters } from 'vuex';
import SocketIO from '../../../../socket/SocketIO';

export default {
  name: 'CourseVideo',
  components: {
    VideoPlayer,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          id: '',
          title: '',
          content: '',
          files: [],
          products: [],
          courseVideoUser: {
            id: '',
            isComplete: 0,
            currentTime: 0,
          },
        };
      },
    },
  },
  emits: [
    'current-time-video',
  ],

  data () {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        poster: require(`@/assets/images/logo/logo-maquillate-white.png`),
        //playbackRates: [1, 1.5, 2],
        fluid: true,
        aspectRatio: '16:9',
        hls: true,
        plugins: {
          airPlay: {
            addButtonToControlBar: true, // defaults to `true`
          },
        },
      },
      currentTime: 0,
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'getCurrentCourseUser']),
  },
  watch: {
    data: function (newValue) {
      if (newValue && newValue.courseVideoUser) {
        this.currentTime = newValue.courseVideoUser.course_video_current_time + 1;
      }
    },
  },
  mounted () {
    SocketIO.connect(this.getProfile);
  },
  beforeUnmount () {
    SocketIO.disconnect();
  },

  methods: {
    currentTimeVideo (currentTime) {
      const courseVideoId = this.data.id;
      SocketIO.emit(`reproduce_time`, {
        currentTime,
        courseVideoId,
      });
      this.$emit('current-time-video', currentTime);
    },
    startVideo () {
      console.log('is started');
    },
    initVideo () {
      if (!this.data.courseVideoUser?.id) {
        this.$store.dispatch(CHANGE_COURSE_VIDEO_USER, {
          user_id: this.getProfile.id,
          course_video_id: this.data.id,
        });
      }
    },
    endVideo (currentTime) {
      this.currentTime = 1;
      if (this.data.courseVideoUser.id) {
        this.$store
          .dispatch(CHANGE_COURSE_VIDEO_USER, {
            user_id: this.getProfile.id,
            course_video_id: this.data.id,
            is_completed: 1,
            course_video_current_time: currentTime,
          })
          .then(() => {
            this.nextCourseVideo();
          });
      }
    },
    nextCourseVideo () {
      const nextCourseVideoIndex = (
        this.getCurrentCourseUser.course_videos || []
      ).findIndex((courseVideo) => courseVideo.id === this.data.id);
      if (
        nextCourseVideoIndex >= 0 &&
        this.getCurrentCourseUser.course_videos[nextCourseVideoIndex + 1]
      ) {
        const nextCourseVideo =
          this.getCurrentCourseUser.course_videos[nextCourseVideoIndex + 1];
        this.$router.push(
          `/curso/${this.getCurrentCourseUser.id}/${this.getCurrentCourseUser.slug_name}/video/${nextCourseVideo.id}`,
        );
      }
    },
  },
};
</script>

<style scoped></style>
