var render = function render(){var _vm=this,_c=_vm._self._c;return _c('skeleton-loading',[_c('div',{staticClass:"single-chapter row mb-3 p-3"},[_c('div',{staticClass:"col-12 chapter-title handle px-0"},[_c('div',{staticClass:"col-2 icon-section pl-0"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '20px',
              width:'20px'
              },"count":1}})],1),_c('div',{staticClass:"col-10 px-0"},[_c('div',{staticClass:"chapter-top"},[_c('skeleton-square',{attrs:{"box-properties":{
              height: '20px',
              width:'350px'
              },"count":1}}),_c('div',{staticClass:"right-icons"},[_c('div',{staticClass:"fast-buttons mr-3"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '20px',
              width:'20px'
              },"count":1}})],1),_c('div',{staticClass:"fast-buttons mr-3"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '20px',
              width:'20px'
              },"count":1}})],1),_c('div',{staticClass:"fast-buttons mr-3"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '20px',
              width:'20px'
              },"count":1}})],1),_c('div',{staticClass:"fast-buttons mr-3"},[_c('skeleton-circle',{attrs:{"box-properties":{
              height: '20px',
              width:'20px'
              },"count":1}})],1)])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }