<template>
  <div class="main-container__librery">
    <div class="col-12 mb-3 pr-0">
      <div class="row">
        <div class="col-xs-courses col-12 col-md-8 col-lg-10 col-xl-10 pl-0">
          <form class="filter-flex">
            <i class="fas fa-search fa-flip-horizontal"></i>

            <input
              v-model="searchValue"
              aria-label="Search"
              class="form-control inputFilter md-filter-input me-2 inputLibrery"
              placeholder="Buscar por nombre"
              type="text"
              @keyup="searchProduct"
            />
            <div class="md-layout md-gutter">
              <div class="md-layout-item p-0">
                <md-field class="sort-box border-0">
                  <md-select
                    v-model="sortType"
                    class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                  >
                    <md-option hidden selected value="">Filtrar por</md-option>
                    <md-option value="DESC">Nuevo</md-option>
                    <md-option value="ASC">Antiguo</md-option>
                    <md-option value="ASC_title">A-Z</md-option>
                    <md-option value="DESC_title">Z-A</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xs-courses col-12 col-md-4 col-lg-2 col-xl-2">
          <button
            class="btn btn-course d-block btn-course__librery md-filter-input m- p-2 w-100 btn d-block float-right"
            @click="openCreateProductDialog"
          >
            crear producto
          </button>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="row">
        <div
          v-for="product in getProducts.items"
          :key="product.id"
          class="col-lg-3 col-md-6 col-12 pl-0"
        >
          <div class="card">
            <img
              :src="product.poster.url"
              class="card-image"
              alt="card-image"
            />
            <div class="px-2 py-2">
              <h3 class="product-title">{{ product.title }}</h3>

              <div class="d-flex row">
                <div class="col-6">
                  <h3>${{ product.price }}</h3>
                </div>
                <div class="col-6">
                  <span class="float-right">
                    <md-menu
                      :md-offset-x="-127"
                      :md-offset-y="-20"
                      md-size="auto"
                    >
                      <md-button md-menu-trigger>•••</md-button>

                      <md-menu-content>
                        <md-menu-item>
                          <span
                            class="pr-5 cursor"
                            href
                            @click="openUpdateProductDialog(product)"
                          >
                            Editar
                          </span>
                        </md-menu-item>
                        <md-menu-item>
                          <a
                            class="pr-5 cursor text-decoration-none"
                            :href="product.url"
                            target="_blank"
                          >
                            Ver Enlace
                          </a>
                        </md-menu-item>
                        <md-menu-item>
                          <span
                            class="pr-5 cursor"
                            href
                            @click="openDeleteProductDialog(product)"
                          >
                            Eliminar
                          </span>
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="totalPages > 1"
      class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
    >
      <base-pagination
        :value="page"
        class="pagination-style"
        :page-count="totalPages"
        @input="paginationChange"
      ></base-pagination>
    </div>
    <create-product-dialog
      :is-open="isOpenCreateProductDialog"
      @success="refetchProducts"
      @close-modal="closeCreateProductDialog"
    />
    <update-product-dialog
      :is-open="isOpenUpdateProductDialog"
      :selected-product="selectedProduct"
      @success="refetchProducts"
      @close-modal="closeUpdateProductDialog"
    />
    <delete-dialog
      :is-open="isOpenDeleteProductDialog"
      @deleted="deleteProduct"
      @close-modal="closeDeleteProductDialog"
    />
  </div>
</template>

<script>
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { mapGetters } from 'vuex';

import {
  DELETE_PRODUCT,
  FETCH_PRODUCT_CATEGORIES,
  FETCH_PRODUCTS,
} from '../../../store/actions/products';
import CreateProductDialog from './components/CreateProductDialog';
import UpdateProductDialog from './components/UpdateProductDialog';
import DeleteDialog from './components/DeleteDialog';
import BasePagination from '../../../components/BasePagination';

export default {
  name: 'ProductsView',
  components: {
    CreateProductDialog,
    UpdateProductDialog,
    DeleteDialog,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data: function () {
    return {
      isLoading: true,
      selectedProduct: undefined,
      isOpenCreateProductDialog: false,
      isOpenUpdateProductDialog: false,
      isOpenDeleteProductDialog: false,
      searchValue: '',
      sortType: '',
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getProducts', 'getProductsCategories']),
    totalPages() {
      return Math.ceil(this.getProducts.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.fetchProducts({
        search: this.searchValue,
        per_page: 12,
        order_by: newValue,
      });
    },
  },
  async mounted() {
    await this.fetchProducts();
    await this.fetchProductCategories();
  },
  methods: {
    searchProduct(e) {
      if (e.key === 'Enter') {
        this.fetchProducts();
      }
    },
    async fetchProducts(
      filter = {
        search: this.searchValue,
        per_page: 20,
        order_by: this.sortType,
      },
    ) {
      await this.$store.dispatch(FETCH_PRODUCTS, filter);
      this.isLoading = false;
    },
    async fetchProductCategories(
      filter = {
        per_page: 20,
        order_by: this.sortType,
        search: this.searchValue,
      },
    ) {
      await this.$store.dispatch(FETCH_PRODUCT_CATEGORIES, filter);
      this.isLoading = false;
    },
    async refetchProducts() {
      this.fetchProducts();
    },
    openCreateProductDialog() {
      this.isOpenCreateProductDialog = true;
    },
    closeCreateProductDialog() {
      this.isOpenCreateProductDialog = false;
    },
    openUpdateProductDialog(product) {
      this.selectedProduct = product;
      this.isOpenUpdateProductDialog = true;
    },

    closeUpdateProductDialog() {
      this.selectedProduct = undefined;
      this.isOpenUpdateProductDialog = false;
    },

    openDeleteProductDialog(product) {
      this.selectedProduct = product;
      this.isOpenDeleteProductDialog = true;
    },
    closeDeleteProductDialog() {
      this.selectedProduct = undefined;
      this.isOpenDeleteProductDialog = false;
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_PRODUCTS, {
        page: this.page,
        per_page: this.perPage,
        featured: 1,
      });
      this.isLoading = false;
    },

    deleteProduct() {
      this.$store
        .dispatch(DELETE_PRODUCT, this.selectedProduct.id)
        .then(() => {
          this.notify('success', 'El producto se ha eliminado con éxito');
          this.closeDeleteProductDialog();
          this.refetchProducts();
        })
        .catch(() => {
          this.notify(
            'danger',
            'Tenemos un problema para eliminar el producto',
          );
        })
        .finally(() => (this.productToDelete = null));
    },
  },
};
</script>

<style lang="scss" scoped>
.product-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;

    &:hover {
      background-color: #d0cfcf;
    }
  }

  .vtl-disabled {
    background-color: #d0cfcf;
  }
}

.icon {
  &:hover {
    cursor: pointer;
  }
}

.muted {
  color: gray;
  font-size: 80%;
}

.products {
  display: flex;
  flex-direction: column;
}

.productImage {
  max-height: 200px;
  max-width: 100%;
  object-fit: cover;
}

.imageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.imageToUpload {
  width: auto;
  object-fit: cover;
  border-radius: 3px;
  border: 1px solid #dee2e6;
  object-position: center;
}

.textUploadImage {
  width: 100%;
  height: 100%;
  background-color: rgba(53, 53, 53, 0.568);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
}

.textUploadImage {
  opacity: 0;
  transition: 0.7s opacity ease-in-out;
  color: aliceblue;
  text-shadow: 1px 1px 1px black;
  font-size: 2.5rem;
}

.imageContainer:hover .textUploadImage {
  opacity: 1;
  width: 100%;
  height: 100%;
  text-align: center;
}

@media (max-width: 370px) {
  .imageToUpload {
    width: 160px;
    height: 160px !important;
  }

  .imageContainer:hover .textUploadImage {
    width: 160px;
    height: 160px;
  }
}

.main-container {
  &__librery {
    padding: 0px 30px;
    @media (max-width: 767px) {
      padding: 0px 16px;
    }
  }
}

.btn-course__librery {
  padding: 11px 14px;
  font-size: 13px;
  text-transform: uppercase;
}

.cards-products {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  box-shadow: none;
  padding: 1rem;

  .md-card-content {
    padding: 0px;

    .md-subhead {
      padding-bottom: 4px;
      color: #000;
      opacity: 1;
      font-size: 15px;
      font-weight: 700;
    }
  }

  .md-card-header {
    padding: 10px 0px 10px;
  }
}

.btn-buy {
  background-color: #10e778ef !important;
  color: white;
  text-transform: uppercase;
}

.btn-buy:hover {
  background-color: #10e778ef !important;
  color: white;
  text-transform: uppercase;
}

.product-layout {
  padding: 0px 15px;

  .md-card {
    box-shadow: none;
  }
}

.main-container__librery {
  .fa-search {
    position: absolute;
    margin-top: 16px;
    margin-left: 12px;
    font-size: 20px;
    color: #b7227e;
  }

  input {
    padding-left: 45px;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    width: 370px;
    border-radius: 3px;
  }

  .filter {
    width: 100px;
    border-radius: 3px !important;
  }

  .expand-more__filter {
    margin-top: 11px;
  }
}

.main-container__librery__header {
  .md-title {
    color: #000;
    padding: 0px;
    font-weight: 700;
  }

  .md-subhead {
    width: max-content;
    padding: 3px 8px;
    color: white;
    background: #b7227e;
    opacity: 1;
    border-radius: 3px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-top: 6px;
  }

  .md-card-expand {
    padding: 0px;
  }
}

.vue-treeselect__multi-value-item {
  color: white;
  background: #b7227e;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.7px;
  padding: 3px;
  border-radius: 4px;
}

.vue-treeselect__value-remove {
  color: white;
  font-size: 15px;
  border-left: none;
}

.vue-treeselect__value-remove > svg {
  width: 10px;
  height: 10px;
}

.vue-treeselect__x {
  width: 12px;
  height: 12px;
}

.vue-treeselect__control {
  padding: 7px 9px;
  outline: none;
}

@media screen and (max-width: 1150px) {
  .product-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .card {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 714px) {
  .product-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 520px) {
  .product-layout {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 993px) {
  .card {
    width: 100% !important;
    max-width: 100% !important;
    height: 400px !important;
    min-height: 400px !important;
    max-height: 400px !important;
  }
  .card-image {
    height: 300px !important;
    width: 100%;
    max-width: 100% !important;
    max-height: 300px !important;
    min-height: 300px !important;
    object-fit: cover !important;
  }
}

.vue-treeselect__value-container {
  padding: 5px 9px;
}

.vue-treeselect__control-arrow-container::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f078';
  font-size: 17px;
  color: #c1c1c1;
}

.vue-treeselect__control-arrow-container svg {
  display: none;
}

//product card

.card-image {
  height: 200px;
  width: 100%;
  max-width: 340px;
  max-height: 100% !important;
  border: none !important;
  border-radius: 5px 5px 0px 0px !important;
  margin-top: 0px !important;
  object-fit: cover !important;
}

.card-text {
  font-size: 14px;
  line-height: 13px;
}
.border-card {
  border: 1px solid #f1f1f1;
  padding: 1rem;
  height: 280px;
}

.card-description {
  text-justify: justify;
  max-width: 100%;
  word-wrap: break-word;
}

.btn-course {
  background: #b7227e;
  color: #fff;
  font-weight: 500;
  margin-left: 5px;
  margin-bottom: 8px;
  padding: 5px 12px;
  font-size: 12px;
  letter-spacing: 0.7px;
}

.md-menu-content {
  z-index: 1 !important;
  margin-top: 20px !important;
}

.card {
  height: 300px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  margin-right: 0px;
  max-width: 340px;
  min-width: 180px !important;
}
.product-title {
  font-size: 1rem;
  text-transform: uppercase;
}
</style>
