<template>
  <div class="container-fluid">
    <template v-if="isLoadingInstructor">
      <div class="pb-2">
        <skeleton-loading>
          <skeleton-square
            :box-properties="{
              height: '16px',
              width: '350px',
            }"
            :count="1"
          />
        </skeleton-loading>
      </div>
    </template>
    <template v-else>
      <h3>
        Cursos asignados a
        {{ `${this.getInstructor.firstname} ${this.getInstructor.lastname}` }}
      </h3>
    </template>
    <div
      class="col-12 row justify-content-between container-instructors__search pr-0 pl-0 ml-0 mb-3"
    >
      <div class="col-7 pl-0">
        <i class="fas fa-search fa-flip-horizontal"></i>
        <input
          v-model="search"
          aria-label="Search"
          class="form-control me-2"
          placeholder="Buscar por nombre"
          type="text"
          @keydown="searchCourses"
        />
      </div>
    </div>
    <div class="container-instructors container-instructors__instructor">
      <div class="container-fluid container-category px-0">
        <md-table>
          <md-table-row class="container-instructors__head">
            <md-table-head>Nombre del curso</md-table-head>
            <md-table-head>Precio</md-table-head>
            <md-table-head>Categoria</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
          <template v-if="isLoadingInstructor">
            <skeleton-course-instructor-course
              v-for="index in 4"
              :key="index"
            />
          </template>
          <template v-else>
            <template v-if="this.getInstructor.courses.length">
              <md-table-row
                v-for="{ course } in this.getInstructor.courses"
                :key="course.id"
                class="container-instructors__cell"
              >
                <md-table-cell>
                  {{ course.name }}
                </md-table-cell>
                <md-table-cell>
                  {{ course.free ? 'Gratis' : `$${course.price}` }}
                </md-table-cell>
                <md-table-cell>
                  <span>
                    {{
                      course.course_categories
                        .map((item) => item.course_category.title)
                        .join(', ')
                    }}</span
                  >
                </md-table-cell>
                <md-table-cell class="container-instructors__options">
                  <span class="float-right">
                    <md-menu
                      :md-offset-x="-147"
                      :md-offset-y="-10"
                      md-size="auto"
                    >
                      <md-button md-menu-trigger class="pb-1">•••</md-button>

                      <md-menu-content v-if="course">
                        <md-menu-item>
                          <router-link
                            :to="`/admin/cursos/curso/${course.id}`"
                            class="dropdown-item-edit p-0"
                          >
                            Editar
                          </router-link>
                        </md-menu-item>
                        <md-menu-item>
                          <div
                            class="dropdown-item p-0"
                            @click="openDeleteInstructorCourseDialog(course)"
                          >
                            Eliminar
                          </div>
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
                  </span>
                </md-table-cell>
              </md-table-row>
            </template>
            <template v-else>
              <md-table-row>
                <md-table-cell class="pt-4 text-center" colspan="4">
                  No hay cursos asignados
                </md-table-cell>
              </md-table-row>
            </template>
          </template>
        </md-table>
      </div>
    </div>
    <delete-dialog
      :is-open="isOpenDeleteInstructorCourseDialog"
      @deleted="deleteInstructorCourse"
      @close-modal="closeDeleteInstructorCourseDialog"
    />
  </div>
</template>

<script>
import {
  DELETE_INSTRUCTOR_COURSE,
  FETCH_INSTRUCTOR,
} from '../../../store/actions/instructor';
import { mapGetters } from 'vuex';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import DeleteDialog from './components/DeleteDialog';
import SkeletonCourseInstructorCourse from '../../Components/skeletons/SkeletonCourseInstructorCourse';

export default {
  components: {
    SkeletonLoading,
    DeleteDialog,
    SkeletonSquare,
    SkeletonCourseInstructorCourse,
  },
  data: function () {
    return {
      selectedCourseInstructorCourse: undefined,
      isLoadingInstructor: true,
      isOpenDeleteInstructorCourseDialog: false,
      search: '',
    };
  },
  computed: {
    ...mapGetters(['getInstructor']),
  },
  mounted() {
    this.fetchInstructor();
  },
  methods: {
    searchCourses(event) {
      if (event.key === 'Enter') {
        this.fetchInstructor();
      }
    },
    fetchInstructor(
      filter = {
        id: this.$route.params.id,
        search_course: this.search,
      },
    ) {
      this.$store
        .dispatch(FETCH_INSTRUCTOR, filter)
        .then(() => (this.isLoadingInstructor = false))
        .catch(() => {
          this.isLoadingInstructor = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar los datos del instructor',
          );
        });
    },
    openDeleteInstructorCourseDialog(course) {
      this.selectedCourseInstructorCourse = course;
      this.isOpenDeleteInstructorCourseDialog = true;
    },
    closeDeleteInstructorCourseDialog() {
      this.selectedCourseInstructorCourse = undefined;
      this.isOpenDeleteInstructorCourseDialog = false;
    },
    async deleteInstructorCourse() {
      await this.$store
        .dispatch(DELETE_INSTRUCTOR_COURSE, {
          instructorId: this.$route.params.id,
          courseId: this.selectedCourseInstructorCourse.id,
        })
        .then(() => {
          this.fetchInstructor();
          this.notify('success', 'Curso eliminado correctamente');
          this.closeDeleteInstructorCourseDialog();
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para eliminar el curso '),
        );
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style scoped>
.categories {
  border: none !important;
}
.dropdown-item {
  color: #000;
}
a.dropdown-item-edit {
  font-size: 14px !important;
  color: #000 !important;
  text-decoration: none;
}
</style>
