<template>
  <div class="container-videos">
    <div
      class="col-12 row justify-content-between container-instructors__search pr-0 pl-0 ml-0 mb-3"
    >
      <div
        class="col-xs-courses col-sm-6 col-md-6 col-lg-6 col-lg-6 col-xl-6"
        style="padding-left: 0"
      >
        <form class="filter-flex">
          <i class="fas fa-search fa-flip-horizontal"></i>
          <input
            v-model="searchValue"
            aria-label="Search"
            class="form-control inputFilter md-filter-input me-2"
            placeholder="Buscar por nombre"
            type="text"
            @keydown="searchVideos"
          />
          <div class="md-layout md-gutter">
            <div class="md-layout-item" style="padding: 0 !important">
              <md-field class="sort-box">
                <md-select
                  v-model="sortType"
                  class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                >
                  <md-option hidden selected value="">Filtrar por</md-option>
                  <md-option value="DESC">Nuevo</md-option>
                  <md-option value="ASC">Antiguo</md-option>
                  <md-option value="ASC_title">A-Z</md-option>
                  <md-option value="DESC_title">Z-A</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="container-videos__body">
      <div class="row">
        <div class="col-sm-12">
          <p v-if="processFiles.length" style="color: black">
            Subiendo {{ this.processFile }}/{{ this.processFiles.length }}
            archivos
          </p>
        </div>
        <div class="col-sm-12">
          <vue-dropzone
            id="videoDropzone"
            ref="videoDropzone"
            :options="dropzoneOptions"
            @vdropzone-success-multiple="completeUpload"
            @vdropzone-file-added="fileAdded"
            @vdropzone-removed-file="removedFile"
          ></vue-dropzone>
        </div>
      </div>

      <!-- template de Handlebars -->

      <div class="video-gallery">
        <el-table
          v-if="!isLoadingVideos"
          :data="getVideos"
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
        >
          <el-table-column max-width="100px" prop="thumbnail">
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body thumbnail-body">
                  <div class="thumbnail-container">
                    <img
                      :id="'img-' + row.id"
                      :alt="row.title"
                      :src="row.thumbnail"
                      class="thumbnail-img"
                      @click="openPreviewVideoDialog(row)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Nombre"
            min-width="150px"
            prop="title"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-600 name mb-0 text-sm">
                    {{ row.title }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Tamaño" min-width="100px" prop="title">
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm"
                    >{{ (row.size / 1024).toFixed(2) }} MB</span
                  >
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Duración"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    {{ formatSeconds(row.duration) }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Subido"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    {{ formatDate(row.created_at) }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Modificado"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    {{ formatDate(row.updated_at) }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Estado"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <span class="font-weight-500 name mb-0 text-sm">
                    <div
                      v-if="
                        courseVideoOptions.COURSE_VIDEO_STATUS_QUEUED ===
                        row.status
                      "
                      class="badge status-pending"
                    >
                      EN COLA
                    </div>
                    <div
                      v-else-if="
                        courseVideoOptions.COURSE_VIDEO_STATUS_PROCESSING ===
                        row.status
                      "
                      class="badge status-pending"
                    >
                      PROCESANDO
                    </div>
                    <div
                      v-else-if="
                        courseVideoOptions.COURSE_VIDEO_STATUS_FINISHED ===
                        row.status
                      "
                      class="badge status-active"
                    >
                      COMPLETADO
                    </div>
                    <div v-else class="badge status-active">DISPONIBLE</div>
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="150px" prop="edit">
            <template #default="{ row }">
              <div class="media align-items-center">
                <div class="media-body">
                  <div class="button-video-container float-right">
                    <md-menu
                      :md-offset-x="-147"
                      :md-offset-y="-10"
                      md-size="auto"
                    >
                      <md-button md-menu-trigger>•••</md-button>

                      <md-menu-content>
                        <md-menu-item @click="openUpdateVideoDialog(row)"
                          >Editar
                        </md-menu-item>
                        <md-menu-item @click="openDeleteVideoDialog(row)"
                          >Eliminar
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-else
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          :data="[1, 2, 3, 4]"
        >
          <el-table-column max-width="100px" prop="thumbnail">
            <template #default>
              <skeleton-square
                :box-properties="{
                  height: '80px',
                  width: '100px',
                  top: '10px',
                }"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="Nombre"
            min-width="150px"
            prop="title"
            sortable
          >
            <template #default>
              <skeleton-square
                :box-properties="{
                  height: '16px',
                  top: '10px',
                }"
              />
            </template>
          </el-table-column>
          <el-table-column label="Tamaño" min-width="100px" prop="title">
            <template #default>
              <skeleton-square
                :box-properties="{
                  height: '16px',
                  top: '10px',
                }"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="Duración"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default>
              <skeleton-square
                :box-properties="{
                  height: '16px',
                  top: '10px',
                }"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="Subido"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default>
              <skeleton-square
                :box-properties="{
                  height: '16px',
                  top: '10px',
                }"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="Modificado"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default>
              <skeleton-square
                :box-properties="{
                  height: '16px',
                  top: '10px',
                }"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="Estado"
            min-width="100px"
            prop="title"
            sortable
          >
            <template #default>
              <skeleton-square
                :box-properties="{
                  height: '16px',
                  top: '10px',
                }"
              />
            </template>
          </el-table-column>
          <el-table-column min-width="150px" prop="edit" class-name="option">
            <template #default>
              <div>
                <skeleton-square
                  :box-properties="{
                    height: '16px',
                    top: '10px',
                    width: '40px',
                  }"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <update-video-dialog
      v-if="selectedVideo.id"
      :is-open="isOpenUpdateVideoDialog"
      :selected-video="selectedVideo"
      @success="refetchVideos"
      @close-dialog="closeUpdateVideoDialog"
    />
    <delete-dialog
      :is-open="isOpenDeleteVideoDialog"
      @deleted="deleteVideo"
      @close-modal="closeDeleteVideoDialog"
    />
    <preview-video-dialog
      :is-open="isOpenPreviewVideoDialog"
      :selected-video="selectedVideo"
      @close-modal="closePreviewVideoDialog"
    />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';

import 'video.js/dist/video-js.css';
import config from '@/config/config';
import moment from 'moment-timezone';
import 'moment-duration-format';
import UpdateVideoDialog from './components/UpdateVideoDialog';
import PreviewVideoDialog from './components/PreviewVideoDialog';
import DeleteDialog from './components/DeleteDialog';

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';

import { mapGetters } from 'vuex';
import { DELETE_VIDEO, FETCH_VIDEOS } from '../../../store/actions/video';
import {
  COURSE_VIDEO_STATUS_FAILED,
  COURSE_VIDEO_STATUS_FINISHED,
  COURSE_VIDEO_STATUS_PROCESSING,
  COURSE_VIDEO_STATUS_QUEUED,
  DEFAULT_VIDEO_VALUES,
} from '../../../util/constants';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare.vue';

export default {
  name: 'UploadMedia',
  components: {
    UpdateVideoDialog,
    vueDropzone: vue2Dropzone,
    PreviewVideoDialog,
    //gallery: VueGallery,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    DeleteDialog,
    SkeletonSquare,
  },
  data: function () {
    return {
      isOpenUpdateVideoDialog: false,
      selectedVideo: DEFAULT_VIDEO_VALUES,
      progress: 0,
      isOpenDeleteVideoDialog: false,
      isOpenPreviewVideoDialog: false,
      courseVideoOptions: {
        COURSE_VIDEO_STATUS_QUEUED,
        COURSE_VIDEO_STATUS_PROCESSING,
        COURSE_VIDEO_STATUS_FINISHED,
        COURSE_VIDEO_STATUS_FAILED,
      },

      sortType: 'DESC',
      isFilter: false,
      searchValue: '',
      isLoadingVideos: true,
      dropzoneOptions: {
        url: `${config.data.apiUrl}/admin/video/upload`,
        thumbnailWidth: 150,
        maxFilesize: 9999999,
        timeout: 9999999,
        addRemoveLinks: true,
        uploadMultiple: true,
        acceptedFiles: 'video/mp4,video/x-m4v,video/*',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
        },
      },
      processFiles: [],
      processFile: 0,
      fileCount: 0,
    };
  },
  computed: {
    ...mapGetters(['getVideos']),
  },
  watch: {
    sortType: function () {
      this.fetchVideos();
    },
    searchValue: function () {
      this.isFilter = !(this.searchValue === '' || this.searchValue == null);
    },
    processFile: function (newValue) {
      if (newValue && newValue === this.processFiles.length) {
        setTimeout(() => {
          this.reset();
          this.notify('success', 'Se han procesado todos los archivos');
        }, 1000);
      }
    },
  },

  mounted() {
    this.fetchVideos();
    setInterval(() => this.fetchVideos(), 20000);
  },
  methods: {
    reset() {
      this.$refs.videoDropzone.enable();
      this.refetchVideos();
      this.removeAllFiles();
      this.processFiles = [];
      this.processFile = 0;
    },
    searchVideos(e) {
      if (e.key === 'Enter') {
        this.fetchVideos();
      }
    },
    fetchVideos(
      filter = {
        per_page: 12,
        order_by: this.sortType,
        search: this.searchValue,
      },
    ) {
      this.$store.dispatch(FETCH_VIDEOS, filter).then(() => {
        this.isLoadingVideos = false;
      });
    },
    refetchVideos() {
      this.fetchVideos();
    },
    fileAdded() {
      this.fileCount++;
    },
    removedFile() {
      this.fileCount--;
    },
    completeUpload(files) {
      this.processFile += Array.isArray(files) ? files.length : 0;
      if (this.processFile === this.fileCount) {
        this.$refs.videoDropzone.removeAllFiles();
        this.refetchVideos();
      }
    },

    openDeleteVideoDialog(video) {
      this.selectedVideo = video;
      this.isOpenDeleteVideoDialog = true;
    },
    closeDeleteVideoDialog() {
      this.selectedVideo = DEFAULT_VIDEO_VALUES;
      this.isOpenDeleteVideoDialog = false;
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    removeAllFiles() {
      this.$refs.videoDropzone.removeAllFiles(true);
    },
    openUpdateVideoDialog(video) {
      this.selectedVideo = video;
      this.isOpenUpdateVideoDialog = true;
    },
    closeUpdateVideoDialog() {
      this.selectedVideo = DEFAULT_VIDEO_VALUES;
      this.isOpenUpdateVideoDialog = false;
    },
    deleteVideo() {
      this.$store
        .dispatch(DELETE_VIDEO, this.selectedVideo.id)
        .then(() => {
          this.notify('success', 'Video eliminado correctamente');
          this.closeDeleteVideoDialog();
          this.refetchVideos();
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para eliminar el video'),
        );
    },
    formatSeconds(seconds) {
      return moment.utc(seconds * 1000).format('HH:mm:ss');
    },
    formatDate(date) {
      let localtime = moment.tz(date, 'America/New_York').local();
      return localtime.format('MMM DD, YYYY');
    },

    openPreviewVideoDialog(video) {
      if (
        video &&
        video.status !== this.courseVideoOptions.COURSE_VIDEO_STATUS_FINISHED
      ) {
        this.notify('warning', 'No tenemos vista previa del video');
        return;
      }

      this.selectedVideo = video;
      this.isOpenPreviewVideoDialog = true;
    },
    closePreviewVideoDialog() {
      this.selectedVideo = DEFAULT_VIDEO_VALUES;
      this.isOpenPreviewVideoDialog = false;
    },
  },
};
</script>

<style>
.option > .cell {
  display: flex;
  justify-content: center;
}
</style>
