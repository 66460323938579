var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"highlight-body showClasses-rut"},[_c('div',{staticClass:"page-container md-layout-column"},[_c('div',[_c('div',{staticClass:"view-home__part5 h-100 show-courses__body show-classes_video"},[_c('div',{staticClass:"container-fluid size"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-lg-8 col-md-12 col-12 p-0"},[_c('course-video',{staticStyle:{"background-size":"cover"},attrs:{"data":_vm.getCurrentCourseVideoUser},on:{"current-time-video":_vm.currentTimeVideo}})],1),(_vm.isLoading)?_c('div',{staticClass:"col-lg-4 col-md-12 col-12 d-flex"},[_c('loading-panel',{staticStyle:{"width":"100%","background":"transparent !important","height":"100% !important"}})],1):_c('div',{staticClass:"col-lg-4 col-md-12 col-12 col-refactoring-boxs show-courses__info-instructor px-0 card-instructor-info"},[_c('div',{staticClass:"row show-courses__info-descrip-instructor mx-1 hide-in-mobile"},[_c('div',{staticClass:"col-lg-3 col-md-12 col-12 px-0"},[_c('div',{staticClass:"p-2 justify-content-center text-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"img-fluid rounded-circle object-fit-cover",staticStyle:{"width":"70px","height":"70px","object-fit":"cover"},attrs:{"src":_vm.getInstructorAvatar(_vm.getCurrentCourseUser)
                            ? _vm.getInstructorAvatar(_vm.getCurrentCourseUser)
                            : '@/assets/images/logo/placeholder.png',"alt":"Card image cap"}})])])]),_c('div',{staticClass:"col-lg-9 col-sm-12 pl-2 pr-2"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"d-flex text-left text-uppercase show-courses__text pl-0",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.getCurrentCourseUser.name)+" ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(
                              _vm.getCurrentCourseUser &&
                              _vm.getCurrentCourseUser.instructors
                            )?_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" Por "),_vm._l((_vm.getCurrentCourseUser.instructors),function(instructor,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/instructores/instructor/${
                                  instructor.instructor.id
                                }/${_vm.getInstructorSlug(
                                  instructor.instructor,
                                )}`}},[_vm._v(" "+_vm._s(instructor.instructor.firstname)+" "+_vm._s(instructor.instructor.lastname)+" ")]),(
                                  index !==
                                  _vm.getCurrentCourseUser.instructors.length - 1
                                )?_c('span',[_vm._v(", ")]):_vm._e()],1)})],2):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},_vm._l((_vm.getCurrentCourseUser.course_categories),function(category,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/explorar/categoria/${category.course_category.slug}`}},[_vm._v(" "+_vm._s(category.course_category.title)+" ")]),(
                                  index !==
                                  _vm.getCurrentCourseUser.course_categories
                                    .length -
                                    1
                                )?_c('span',[_vm._v(", ")]):_vm._e()],1)}),0)])])])])])]),_c('div',{staticClass:"padding-videos"},[_c('div',{staticClass:"show-courses__videos my-2"},[_c('badger-accordion',{ref:"badgerAccordion",staticClass:"accordion course-container__nav__chapter-collapse pt-4",attrs:{"icons":{
                      opened: `<i class='fas fa-angle-up'></i>`,
                      closed: `<i class='fas fa-angle-down'></i>`,
                    }}},[(_vm.curseVideos.length)?_c('div',{staticClass:"mb-4"},_vm._l((_vm.curseVideos),function(video,index){return _c('router-link',{key:index,attrs:{"to":`/curso/${_vm.getCurrentCourseUser.id}/${_vm.getCurrentCourseUser.slug_name}/video/${video.id}`}},[_c('div',{staticClass:"card",staticStyle:{"cursor":"pointer","background":"black"},attrs:{"id":index}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-mobile-img col-5 col-md-mobile col-md-5 pr-0",staticStyle:{"background":"#000"}},[_c('div',{staticStyle:{"border-radius":"6px"}},[_c('img',{staticClass:"img-see-course",staticStyle:{"padding":"0","object-fit":"cover","max-height":"90px","width":"100%"},attrs:{"src":video.video?.thumbnail ??
                                    _vm.getCurrentCourseUser.thumbnail_url,"alt":"Card image cap"}}),_c('div',{staticClass:"card-img-overlay timeLesson d-flex justify-content-between"},[_c('div',{staticClass:"details-play",attrs:{"id":'video-' + index}}),_c('a',{staticClass:"btn duration-style btn-sm text-right",attrs:{"id":'idVideo-' + video.id}},[_vm._v(_vm._s(_vm.formatTime(video)))])]),_c('div',{staticClass:"progress progress-show-courses"},[_c('div',{staticClass:"progress-bar",style:({
                                      width: `${_vm.getCourseVideoCurrentTimePercent(
                                        video
                                      )}%`,
                                    }),attrs:{"aria-valuenow":_vm.getCourseVideoCurrentTimePercent(
                                        video
                                      ),"aria-valuemax":"100","aria-valuemin":"0","role":"progressbar"}})])])]),_c('div',{staticClass:"col-mobile-title col-7 col-md-7 show-courses__info-videos"},[_c('h5',{staticClass:"text-left show-courses__text text-mobile-descript"},[_vm._v(" "+_vm._s(video.title)+" ")])])])])])}),1):_vm._e()])],1)])])])])]),_c('div',{staticClass:"pt-5 show-courses__mobile-container text-description-class"},[_c('div',{staticClass:"container-fluid size"},[(_vm.isLoadingCourseVideo)?_c('skeleton-course-video-details'):_c('course-video-details',{attrs:{"course":_vm.getCurrentCourseUser,"data":_vm.getCurrentCourseVideoUser},on:{"change-course-user-favorite-success":_vm.refetchCourseVideo}}),_c('div',{staticClass:"row show-courses__info-descrip-instructor mx-1 hide-in-desktop"},[_c('div',{staticClass:"col-lg-3 col-md-12 col-12 px-0"},[_c('div',{staticClass:"p-2 justify-content-center text-center"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{staticClass:"img-fluid rounded-circle object-fit-cover",staticStyle:{"width":"70px","height":"70px","object-fit":"cover"},attrs:{"src":_vm.getInstructorAvatar(_vm.getCurrentCourseUser)
                        ? _vm.getInstructorAvatar(_vm.getCurrentCourseUser)
                        : '@/assets/images/logo/placeholder.png',"alt":"Card image cap"}})])])]),_c('div',{staticClass:"col-lg-9 col-sm-12 pl-2 pr-2"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"d-flex text-left text-uppercase show-courses__text pl-0",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.getCurrentCourseUser.name)+" ")]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(
                          _vm.getCurrentCourseUser &&
                          _vm.getCurrentCourseUser.instructors
                        )?_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" Por "),_vm._l((_vm.getCurrentCourseUser.instructors),function(instructor,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/instructores/instructor/${
                              instructor.instructor.id
                            }/${_vm.getInstructorSlug(instructor.instructor)}`}},[_vm._v(" "+_vm._s(instructor.instructor.firstname)+" "+_vm._s(instructor.instructor.lastname)+" ")]),(
                              index !==
                              _vm.getCurrentCourseUser.instructors.length - 1
                            )?_c('span',[_vm._v(", ")]):_vm._e()],1)})],2):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"text-uppercase show-courses__text text-left",staticStyle:{"font-size":"12px"}},_vm._l((_vm.getCurrentCourseUser.course_categories),function(category,index){return _c('span',{key:index},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":`/explorar/categoria/${category.course_category.slug}`}},[_vm._v(" "+_vm._s(category.course_category.title)+" ")]),(
                              index !==
                              _vm.getCurrentCourseUser.course_categories.length -
                                1
                            )?_c('span',[_vm._v(", ")]):_vm._e()],1)}),0)])])])])])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }