<template>
  <div class="row">
    <div class="col-lg-12">
      <loading-panel
        v-if="isLoading"
        class="change-page-loading"
      ></loading-panel>

      <el-table
        v-if="this.getInvoicesList"
        id="invoice-table"
        class="table-responsive table-flush"
        header-row-class-name="thead-light"
        :data="
          this.getInvoicesList && this.getInvoicesList.items
            ? this.getInvoicesList.items
            : []
        "
        height="350px"
      >
        <el-table-column label="Fecha" min-width="65px" prop="date" sortable>
          <template #default="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-500 name mb-0 text-sm">{{
                  utcDate(row.created_at)
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="N° Factura"
          min-width="150px"
          prop="id"
          sortable
        >
          <template #default="{ row }">
            <div class="media align-items-center">
              <span class="status font-weight-500 name">{{
                row.transaction_invoice_id
              }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="N° Orden" min-width="120px" prop="id" sortable>
          <template #default="{ row }">
            <div class="media align-items-center">
              <span class="status font-weight-500 name">{{
                row.transaction_id
              }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Descripción"
          prop="description"
          min-width="150px"
          sortable
        >
          <template #default="{ row }">
            <div v-for="(order, index) in row.orders" :key="index">
              <router-link
                style="text-decoration: none; color: #000 !important"
                :to="`/${order.course.id}/${order.course.slug_name}`"
              >
                {{ order.course.name }}
              </router-link>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Monto" min-width="60px" prop="budget" sortable>
          <template #default="{ row }">
            <span class="status font-weight-500 name"
              >$ {{ getTotal(row).toFixed(2) }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="Estado" min-width="70px" prop="status" sortable>
          <template #default="{ row }">
            <badge class="badge-dot-paid mr-4 p-0" type>
              <i :class="`bg-${getStatusType(row.status)}`"></i>
              <span class="status">Pagado</span>
            </badge>
          </template>
        </el-table-column>

        <el-table-column label="Factura" prop="pdf" min-width="70px">
          <template #default="{ row }">
            <div class="avatar-group">
              <a
                :href="downloadInvoice(row.transaction_invoice_id)"
                target="_blank"
                class="style-avatar"
              >
                <i class="ni ni-single-copy-04"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        v-if="totalPages > 1"
        class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
      >
        <base-pagination
          :value="page"
          class="pagination-style"
          :page-count="totalPages"
          @input="paginationChange"
        ></base-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '../../../../../components/BasePagination';
import { Table, TableColumn } from 'element-ui';
import { mapGetters } from 'vuex';
import LoadingPanel from '../../../../../components/LoadingPanel';
import { FETCH_USER_INVOICES } from '../../../../../store/actions/invoice';
import config from '@/config/config';
import moment from 'moment-timezone';

export default {
  name: 'UserInvoices',
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LoadingPanel,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      perPage: 10,
    };
  },

  computed: {
    ...mapGetters(['getInvoicesList', 'getProfile']),
    totalPages() {
      return Math.ceil(this.getInvoicesList.total / this.perPage);
    },
  },
  async mounted() {
    if (this.getProfile) {
      await this.fetchUserInvoices();
    }
  },
  methods: {
    async fetchUserInvoices() {
      const user = this.getProfile;
      await this.$store.dispatch(FETCH_USER_INVOICES, {
        user_id: user.id,
        page: this.page,
        per_page: 10,
        order_by: 'DESC',
      });
      this.isLoading = false;
    },
    downloadInvoice(invoiceId) {
      return `${config.data.apiUrl}/invoice/download/${invoiceId}`;
    },
    getStatusType(status) {
      return status === 'COMPLETED' ? 'success' : 'warning';
    },

    utcDate(date) {
      return moment.utc(date).format('l');
    },

    getTotal(invoice) {
      return invoice.fee + invoice.profit;
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      const user = this.getProfile;
      await this.$store.dispatch(FETCH_USER_INVOICES, {
        user_id: user.id,
        page: page,
        per_page: 10,
        order_by: 'DESC',
      });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.change-page-loading {
  height: 100% !important;
  margin-right: 0 !important;
  position: absolute !important;
  width: 100% !important;
  opacity: 0.8 !important;
}
</style>
