<template>
  <div class="auth-layout-container bg-default">
    <div class="main-content login-app">
      <div class="auth-super-container container">
        <!-- Table -->
        <div class="row justify-content-center">
          <div>
            <div class="auth-container">
              <div class="auth-container__logo">
                <router-link :to="'/'">
                  <img
                    :src="
                      require(`@/assets/images/logo/logo-maquillate-white.png`)
                    "
                    alt="Maquillate logo"
                    class="auth-logo-white"
                  />
                  <img
                    :src="
                      require(`@/assets/images/logo/logo.png`)
                    "
                    alt="Maquillate logo"
                    class="auth-logo-black"
                  />
                </router-link>
              </div>

              <div class="auth-container__form bg-white mt-4 mb-0 rounded">
                <div class="card-body">
                  <div class="text-center text-muted mb-1">
                    <div class="auth-container__form__title">
                      <h2 class="text-default">CREA TU CUENTA</h2>
                    </div>
                    <div class="auth-container__form__description">
                      <h3 class="text-default">
                        Únete a miles de personas que aprenden a maquillarse
                        todos los días.
                      </h3>
                      <span class="text-default" style="font-weight: bold!important"
                        >No se requiere tarjeta de crédito</span
                      >
                    </div>
                  </div>
                  <validation-observer
                    ref="formValidator"
                    v-slot="{ handleSubmit }"
                  >
                    <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                      <md-field class="mb-0">
                        <label class="auth-container__label"
                          >Nombre completo</label
                        >
                        <md-input
                          v-model="model.name"
                          alternative
                          name="Name"
                        ></md-input>
                      </md-field>

                      <div v-if="errors.name" class="error-message-register">
                        <span>
                          {{ errors.name }}
                        </span>
                      </div>

                      <md-field class="mb-0 mt-0">
                        <label class="auth-container__label"
                          >Correo electrónico</label
                        >
                        <md-input
                          v-model="model.email"
                          :rules="emailRules"
                          alternative
                          name="Email"
                          @blur="validateEmail"
                        ></md-input>
                      </md-field>
                      <div
                        v-if="errorEmailExist"
                        class="error-message-register"
                      >
                        <span>
                          {{ errorEmailExist }}
                        </span>
                      </div>

                      <div v-if="errors.email" class="error-message-register">
                        <span>
                          {{ errors.email }}
                        </span>
                      </div>
                      <md-field class="mb-0 mt-0">
                        <label class="auth-container__label">Contraseña</label>
                        <md-input
                          id="password"
                          v-model="model.password"
                          :strength="model.validPassword.strong"
                          type="password"
                          @input="passwordValidation"
                        ></md-input>
                      </md-field>
                      <VuePassword
                        id="repeat-password"
                        v-model="model.repeatPassword"
                        :strength="model.validPassword.strong"
                        class="control has-icons-left md-input"
                        placeholder="repetir Contraseña"
                        type="password"
                      />
                      <div
                      v-if="model.password && errors.password"
                      class="error-message-register"
                    >
                      <span>
                        {{ errors.password }}
                      </span>
                    </div>

<!--                      <md-field class="mb-0 mt-0">-->
<!--                        <label class="auth-container__label"-->
<!--                          >Repetir Contraseña</label-->
<!--                        >-->
<!--                        <md-input-->
<!--                          v-model="model.repeatPassword"-->
<!--                          :strength="model.repeatPassword.strong"-->
<!--                          class="control has-icons-left md-input"-->
<!--                          type="password"-->
<!--                          @input="validateRepeatPassword"-->
<!--                        ></md-input>-->
<!--                      </md-field>-->

<!--                      <div-->
<!--                        v-if="model.repeatPassword && errors.repeatPassword"-->
<!--                        class="error-message-register"-->
<!--                      >-->
<!--                        <span>-->
<!--                          {{ errors.repeatPassword }}-->
<!--                        </span>-->
<!--                      </div>-->

<!--                      <md-field class="mb-0 mt-0">-->
<!--                        <vue-tel-input-->
<!--                          v-model="model.phone"-->
<!--                          :input-options="inputOptions"-->
<!--                          mode="international"-->
<!--                          class="tel-register-input"-->
<!--                          :auto-default-country="true"-->
<!--                          @input="validatePhone"-->
<!--                        ></vue-tel-input>-->
<!--                      </md-field>-->

<!--                      <div v-if="errors.phone" class="error-message-register">-->
<!--                        <span>-->
<!--                          {{ errors.phone }}-->
<!--                        </span>-->
<!--                      </div>-->

                      <md-field
                        class="mb-0 mt-0 multiselect-register-multiselect"
                      >
                        <multiselect
                          v-model="model.country"
                          :close-on-select="true"
                          :options="getAllCountries"
                          :searchable="true"
                          :show-labels="false"
                          class="select-danger details-country shadow__multiselect"
                          label="name"
                          placeholder="Seleccione un País"
                          track-by="id"
                          @input="countryValidation"
                        >
                          <template #option="props">
                            <div class="option__desc">
                              <span class="option__title">{{
                                `(${props.option.code}) ${props.option.name}`
                              }}</span>
                              <span
                                v-if="
                                  props.values &&
                                  props.values.length &&
                                  !props.values.isOpen
                                "
                                class="multiselect__single"
                                >{{ props.values.length }} options
                                selected</span
                              >
                            </div>
                          </template>
                          <template #noResult>
                            <div>Sin resultados</div>
                          </template>
                        </multiselect>
                      </md-field>

                      <div
                        v-if="errors.country_id"
                        class="error-message-register"
                      >
                        <span>
                          {{ errors.country_id }}
                        </span>
                      </div>

                      <div class="text-center">
                        <base-button
                          :disabled="validateFields"
                          :loading="isLoading"
                          block
                          class="my-3"
                          native-type="submit"
                          type="dark"
                          >Continuar
                        </base-button>
                      </div>
                    </form>
                  </validation-observer>

                  <p class="privacy-politics text-center mb-0">
                    <small class="text-defaul text-center">
                      Al registrarse, confirma que acepta los
                      <span
                        class="text-dark"
                        style="cursor: pointer"
                        @click="showDialogTerms = true"
                      >
                        términos y condiciones
                      </span>
                      , y la
                      <span
                        class="text-dark"
                        style="cursor: pointer"
                        @click="showDialogPolitics = true"
                      >
                        política de privacidad </span
                      >.
                    </small>
                  </p>
                </div>
              </div>

              <!-- **** MODAL TERMS **** -->
              <!-- eslint-disable -->
              <md-dialog
                :md-active.sync="showDialogTerms"
                class="modalEdit__instructors modal-categories"
              >
                <div
                  class="w-100 position-fixed mobile-cancel"
                  style="z-index: 10"
                >
                  <span
                    class="material-icons close text-right"
                    @click="showDialogTerms = false"
                    >close</span
                  >
                </div>
                <div class="body-modal-instructor modal-body">
                  <div class="md-dialog-title">
                    <h3 class="text-bold text-left">
                      TÉRMINOS Y CONDICIONES DE MAQUÍLLATE.COM
                    </h3>
                  </div>
                  <div class="dialog-body">
                    <div class="row">
                      <div class="col-md-12 mt-4 text-justify pb-6">
                        <h3 class="pt-4 pb-2">
                          ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES
                        </h3>
                        Al acceder y utilizar la plataforma de maquillaje de
                        cursos online, el usuario acepta los términos y
                        condiciones de uso que se establecen a continuación. Si
                        el usuario no está de acuerdo con los términos y
                        condiciones, no debe utilizar la plataforma.

                        <h3 class="pt-4 pb-2">PROPIEDAD INTELECTUAL</h3>

                        Todo el contenido de la plataforma, incluyendo pero no
                        limitado a textos, imágenes, videos, diseños gráficos,
                        programas de software, bases de datos, y cualquier otra
                        información disponible en la plataforma, es propiedad de
                        la empresa y/o de sus respectivos dueños y se encuentra
                        protegido por las leyes de propiedad intelectual.

                        <h3 class="pt-4 pb-2">USO DE LA PLATAFORMA</h3>

                        El usuario se compromete a utilizar la plataforma de
                        maquillaje de cursos online de manera legal, ética y
                        responsable. El usuario no debe utilizar la plataforma
                        para:
                        <br />
                        Subir, transmitir o compartir información o material que
                        infrinja los derechos de autor, marca registrada,
                        patente, secretos comerciales u otros derechos de
                        propiedad intelectual de terceros. Publicar información
                        falsa, difamatoria, obscena, ofensiva o ilegal. Utilizar
                        la plataforma de forma fraudulenta o engañosa. Acceder a
                        cuentas o información de otros usuarios sin su
                        autorización. Intentar obtener acceso no autorizado a
                        los sistemas o redes de la plataforma.

                        <h3 class="pt-4 pb-2">REGISTRO DE USUARIO</h3>

                        Para acceder a los cursos de la plataforma de maquillaje
                        de cursos online, el usuario deberá registrarse en la
                        plataforma proporcionando información precisa y
                        completa. El usuario será responsable de mantener la
                        confidencialidad de su nombre de usuario y contraseña, y
                        será responsable de todas las actividades que se
                        realicen bajo su cuenta.

                        <h3 class="pt-4 pb-2">PAGO Y CANCELACIONES</h3>

                        El usuario deberá pagar por los cursos pagos que desee
                        tomar a través de la plataforma. Los precios y los
                        términos de pago estarán disponibles en la plataforma.
                        La empresa se reserva el derecho de cancelar los cursos
                        y reembolsar al usuario en caso de cualquier situación.
                        El usuario podrá cancelar su pago en un curso hasta 7
                        días si no esta satisfecho y se le reembolsará el
                        importe pagado.

                        <h3 class="pt-4 pb-2">
                          MODIFICACIONES EN LA PLATAFORMA Y LOS CURSOS
                        </h3>

                        La empresa se reserva el derecho de modificar la
                        plataforma y los cursos en cualquier momento, incluyendo
                        el contenido de los cursos, el precio y la
                        disponibilidad de los mismos.

                        <h3 class="pt-4 pb-2">LIMITACIÓN DE RESPONSABILIDAD</h3>

                        La empresa no será responsable de cualquier daño
                        directo, indirecto, incidental, especial o consecuencial
                        que resulte del uso o la imposibilidad de usar la
                        plataforma de maquillaje de cursos online, incluyendo
                        pero no limitado a pérdida de datos, interrupción del
                        negocio o cualquier otra pérdida financiera, incluso si
                        la empresa ha sido informada de la posibilidad de tales
                        daños.

                        <h3 class="pt-4 pb-2">LEY APLICABLE Y JURISDICCIÓN</h3>

                        Estos términos y condiciones se regirán e interpretarán
                        de acuerdo con las leyes del país en el que se encuentra
                        la empresa. Cualquier disputa que surja en relación con
                        estos términos y condiciones será resuelta por los
                        tribunales competentes del país en el que se encuentra
                        la empresa.

                        <h3 class="pt-4 pb-2">
                          MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES
                        </h3>

                        La empresa se reserva el derecho de modificar estos
                        términos y condiciones en cualquier momento que vea
                        necesario.
                      </div>
                    </div>
                  </div>
                </div>
              </md-dialog>

              <!-- **** MODAL POLICY **** -->
              <md-dialog
                :md-active.sync="showDialogPolitics"
                class="modalEdit__instructors modal-categories"
              >
                <div
                  class="w-100 position-fixed mobile-cancel"
                  style="z-index: 10"
                >
                  <span
                    class="material-icons close text-right"
                    @click="showDialogPolitics = false"
                    >close</span
                  >
                </div>
                <div class="body-modal-instructor modal-body">
                  <div class="md-dialog-title">
                    <h3 class="text-bold text-left">
                      POLÍTICA DE PRIVACIDAD DE MAQUÍLLATE.COM
                    </h3>
                  </div>
                  <div class="dialog-body">
                    <div class="row">
                      <div class="col-md-12 mt-4 text-justify pb-6">
                        <h3 class="pt-4 pb-2">INFORMACIÓN QUE RECOPILAMOS</h3>
                        Recopilamos información personal que nos proporcionas
                        voluntariamente cuando te registras en nuestra
                        plataforma, realizas una compra, creas una cuenta de
                        usuario, publicas contenido, te comunicas con nosotros a
                        través de correo electrónico u otros canales de
                        comunicación, o de cualquier otra forma interaccionas
                        con nuestra plataforma. La información que recopilamos
                        puede incluir tu nombre, dirección de correo
                        electrónico, dirección física, número de teléfono,
                        información de pago, información de envío, información
                        de registro, preferencias de maquillaje y cualquier otra
                        información que nos proporciones.

                        <h3 class="pt-4 pb-2">USO DE LA INFORMACIÓN</h3>
                        Utilizamos la información que recopilamos para
                        proporcionarte una experiencia de usuario personalizada,
                        procesar tus pedidos, mejorar nuestra plataforma y
                        servicios, comunicarnos contigo sobre nuestros productos
                        y servicios, responder a tus preguntas y comentarios, y
                        proporcionarte información relevante y ofertas
                        especiales. También podemos utilizar la información para
                        fines estadísticos y de investigación.
                        <h3 class="pt-4 pb-2">PROTECCIÓN DE LA INFORMACIÓN</h3>
                        Tomamos medidas técnicas y organizativas adecuadas para
                        proteger la información que recopilamos y almacenamos.
                        Utilizamos medidas de seguridad físicas, electrónicas y
                        procedimentales para proteger tus datos personales
                        contra el acceso no autorizado, la alteración, la
                        divulgación o la destrucción.
                        <h3 class="pt-4 pb-2">COMPARTIR INFORMACIÓN</h3>
                        No compartimos información personal con terceros,
                        excepto en los casos en que sea necesario para procesar
                        tus pedidos, mejorar nuestra plataforma y servicios, o
                        cumplir con las leyes y regulaciones aplicables. También
                        podemos compartir información agregada y no
                        identificable con terceros para fines estadísticos y de
                        investigación.
                        <h3 class="pt-4 pb-2">
                          COOKIES Y TECNOLOGÍAS SIMILARES
                        </h3>
                        Utilizamos cookies y otras tecnologías similares para
                        recopilar información sobre tu actividad en nuestra
                        plataforma. Esto nos ayuda a mejorar nuestra plataforma
                        y servicios y a proporcionarte una experiencia de
                        usuario personalizada. Puedes desactivar las cookies en
                        tu navegador, pero esto puede afectar la funcionalidad
                        de nuestra plataforma.
                        <h3 class="pt-4 pb-2">
                          CAMBIOS EN LA POLÍTICA DE PRIVACIDAD
                        </h3>
                        Nos reservamos el derecho de actualizar esta política de
                        privacidad en cualquier momento y sin previo aviso. La
                        versión más reciente de la política de privacidad se
                        publicará en nuestra plataforma.
                        <h3 class="pt-4 pb-2">CONTÁCTANOS</h3>
                        Si tienes alguna pregunta sobre nuestra política de
                        privacidad o sobre cómo utilizamos tus datos personales,
                        contáctanos en ayuda@maquillate.com
                        <br />
                        <br />
                        Gracias por utilizar nuestra plataforma de maquillaje y
                        confiar en nosotros para proteger tu privacidad y datos
                        personales.
                      </div>
                    </div>
                  </div>
                </div>
              </md-dialog>

              <div class="already-account mt-3">
                <div class="col-12 text-center">
                  <router-link to="/login">
                    <small class="text-white"
                      >¿Ya tienes una cuenta? Inicia sesión
                    </small>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  REGISTER_REQUEST,
  USER_EMAIL_VERIFICATION,
} from '@/store/actions/register';
import { mapGetters } from 'vuex';
import VuePassword from 'vue-password';
import validator from 'validator';
import Multiselect from 'vue-multiselect';
import equals from 'validator/lib/equals';
import { VueTelInput } from 'vue-tel-input/dist/vue-tel-input.common';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: 'RegisterView',
  components: {
    VuePassword,
    Multiselect,
    VueTelInput,
  },
  metaInfo() {
    return {
      title:  'Registrate - Maquillate.com'
    }
  },
  data() {
    return {
      errors: {},
      isLoading: false,
      rules: [
        { message: 'Se requiere una letra minúscula.', regex: /[a-z]+/ },
        { message: 'Se requiere una letra mayúscula.', regex: /[A-Z]+/ },
        { message: 'Se requiere un número.', regex: /[0-9]+/ },
        { message: '8 caracteres como mínimo.', regex: /.{8,}/ },
      ],
      model: {
        name: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        agree: false,
        country: '',
        validPassword: {
          strong: 0,
          message: '',
          alert: '',
        },
        errorMessage: null,
      },
      showDialogTerms: false,
      showDialogPolitics: false,
      emailRules: {
        email: true,
      },
      EmailVerification: '',
      errorEmailExist: '',
      inputOptions: {
        placeholder: 'Teléfono (Opcional)',
      },
    };
  },

  computed: {
    ...mapGetters(['authStatusRegister', 'getAllCountries']),

    validateFields: function () {
      return !(
        this.model.validPassword.strong > 2 &&
        this.model.validPassword.strong !== 0 &&
        this.model.email.length !== 0 &&
        this.model.name.length !== 0
      );
    },
  },
  methods: {
    onSubmit() {
      let data = {
        name: this.model.name,
        email: this.model.email,
        password: this.model.password,
        // repeatPassword: this.model.repeatPassword,
        country_id: this.model.country ? this.model.country.id : '',
        phone: this.model.phone,
      };

      this.isLoading = true;
      this.$store
        .dispatch(REGISTER_REQUEST, data)
        .then(() => {
          this.isLoading = false;
          window.location.href = '/confirmar-correo';
        })

        .catch((error) => {
          this.isLoading = false;
          this.notify(error, 'danger');
        });
    },
    validatePassword() {
      if (this.model.password && !equals(this.model.password, this.model.repeatPassword)) {
        this.errors.password = 'Las contraseñas deben ser iguales';
      } else {
        delete this.errors.password;
      }
    },
    validateRepeatPassword() {
      if (this.model.repeatPassword !== this.model.password) {
        this.errors.repeatPassword = 'Las contraseñas deben ser iguales';
      } else {
        delete this.errors.repeatPassword;
      }
    },
    validateEmail() {
      let email = this.model.email;

      this.$store
        .dispatch(USER_EMAIL_VERIFICATION, email)
        .then((resp) => {
          this.EmailVerification = resp.data;
          if (this.EmailVerification) {
            this.errorEmailExist =
              '¡Ya existe una cuenta con ese correo electrónico!';
          } else {
            this.errorEmailExist = null;
          }
        })
        .catch(() => {});

      if (!validator.default.isEmail(email)) {
        this.errors.email = 'El formato del correo es incorrecto';
      } else {
        delete this.errors.email;
      }
    },
    validatePhone() {
      if (
        this.model.phone &&
        !validator.default.matches(this.model.phone, /^[0-9+\s]+$/)
      ) {
        this.errors.phone = 'El formato del teléfono es incorrecto';
      } else {
        delete this.errors.phone;
      }
    },
    passwordValidation() {
      let errors = [];
      let rules = [
        { message: 'Se requiere una letra minúscula.', regex: /[a-z]+/ },
        { message: 'Se requiere una letra mayúscula.', regex: /[A-Z]+/ },
        { message: 'Se requiere un número.', regex: /[0-9]+/ },
        { message: '8 caracteres como mínimo.', regex: /.{8,}/ },
      ];

       for (let condition of rules) {
        if (!condition.regex.test(this.model.password)) {
          errors.push(condition.message);
        }
      }
      this.errors.password = errors.length > 0 ? errors.join(' ') : null;


      switch (errors.length) {
        case 0: {
          this.model.validPassword.strong = 3;
          this.model.validPassword.message = 'Segura';
          this.model.validPassword.alert = 'text-info';
          break;
        }
        case 1: {
          this.model.validPassword.strong = 2;
          this.model.validPassword.message = 'Medio';
          this.model.validPassword.alert = 'text-warning';
          break;
        }
        case 2: {
          this.model.validPassword.strong = 2;
          this.model.validPassword.message = 'Medio';
          this.model.validPassword.alert = 'text-warning';
          break;
        }
        case 3: {
          this.model.validPassword.strong = 1;
          this.model.validPassword.message = 'Debil';
          this.model.validPassword.alert = 'text-warning';
          break;
        }
        case 4: {
          if (this.model.password.length < 2) {
            this.model.validPassword.strong = 1;
            this.model.validPassword.message = 'Debil';
            this.model.validPassword.alert = 'text-warning';
          }
          break;
        }
        default: {
          this.model.validPassword.strong = 0;
          break;
        }
      }
      let strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;

      if (strongPasswordRegex.test(this.model.password) && this.model.password.length > 11) {
        this.model.validPassword.strong = 4;
        this.model.validPassword.message = 'Muy Segura';
        this.model.validPassword.alert = 'text-success';
      }
    },
    countryValidation() {
      if (!this.model.country) {
        this.errors.country.id = 'El país es requerido';
      }
    },
    notify(message, type) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style>
.multiselect-register-multiselect .multiselect__option--highlight {
  background: #000 !important;
  outline: none;
  outline: none;
  border: 0px;
}

.multiselect-register-multiselect .multiselect__option--highlight span {
  color: #fff !important;
}

.multiselect-register-multiselect .shadow__multiselect {
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.356), 0 1px 0 rgb(0 0 0 / 2%);
}

.multiselect-register-multiselect .multiselect__select {
  padding: 14px 8px;
}

.multiselect-register-multiselect ::placeholder .multiselect__placeholder {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 500 !important;
}

.multiselect-register-multiselect .multiselect__placeholder {
  color: #0000008a !important;
}

.multiselect__select {
  -webkit-transition: none !important;
  transition: none !important;
  height: 30px;
  width: auto;
  margin-top: 0px;
  transform: none !important;
}

.multiselect-register-multiselect .shadow__multiselect {
  box-shadow: none !important;
}

.multiselect__placeholder {
  font-size: 16px !important;
  line-height: 20px;
}

.multiselect__input,
.multiselect__single,
.multiselect__placeholder {
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: 0.01em;
  padding-top: 5px !important;
}

.multiselect__input:focus,
.multiselect__single:focus {
  font-size: 16px !important;
  line-height: 20px;
  letter-spacing: 0.01em;
  box-shadow: none !important;
}

.md-field .md-input,
.md-field .md-textarea,
.select-country {
  box-shadow: none;
}
.md-field .md-input:focus,
.md-field .md-textarea:focus {
  box-shadow: none;
}
.auth-layout-container .md-field .md-input,
.auth-layout-container .md-field .md-textarea,
.auth-layout-container .select-country {
  box-shadow: none !important;
}

.multiselect__single {
  padding: 0px !important;
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}
.multiselect__placeholder {
  margin-bottom: 15px;
}
.multiselect__tags {
  padding: 0px 40px 0px 16px;
}
.multiselect__input {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 9px !important;
}

.multiselect__select::before {
  top: 35%;
}

.multiselect__select {
  height: 50px;
  width: 50px;
  margin-top: 0px !important;
  padding: 0px !important;
}

.multiselect--above .multiselect__content-wrapper {
  top: auto !important;
  bottom: 100% !important;
}

.auth-layout-container .md-field .md-input,
.auth-layout-container .md-field .md-textarea,
.auth-layout-container .select-country {
  border: 1px solid #ddd;
}

.auth-layout-container .md-field .md-input:focus,
.auth-layout-container .md-field .md-textarea:focus,
.auth-layout-container .select-country:focus {
  border: 1px solid #ddd !important;
}

.multiselect__option--selected {
  background: #b7227e !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}
.multiselect__option--selected span {
  color: #fff !important;
}

.error-message-register,
.error-message-register span {
  background-color: rgb(226, 4, 4);
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  margin-top: 14px !important;
  font-size: 14px !important;
}

.vue-tel-input.tel-register-input {
  width: 100%;
  height: 56px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.vue-tel-input.tel-register-input input::placeholder {
  color: #0000008a !important;
}

.tel-register-input.vti__input {
  font-weight: 400;
}

.tel-register-input.vti__input::placeholder {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}

.tel-register-input.vue-tel-input:focus-within {
  outline: none;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: none !important;
  border-top-color: none !important;
  border-right-color: none !important;
  border-bottom-color: none !important;
  border-left-color: none !important;
  border: 1px solid #ddd;
}

.tel-register-input .vti__dropdown-item strong {
  font-weight: 400;
}

.option__desc {
  padding-top: 0px !important;
}
</style>
