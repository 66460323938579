<template>
  <div>
    <div v-if="getCreditCardList && !getCreditCardList.length">
      <span>No tiene un método de pago registrado</span>
      <div class="mt-2">
        <base-button
          class="btn btn-maquillate py-2 px-3"
          @click="openCreditCardDialog"
          >agregar método de pago
        </base-button>
      </div>
    </div>

    <div v-if="getCreditCardList && getCreditCardList.length" class="mt-2">
      <base-button
        class="btn btn-maquillate py-2 px-3"
        @click="openCreditCardDialog"
        >agregar otro método de pago
      </base-button>
    </div>

    <div v-if="!isLoading">
      <div class="payments-methods pt-4">
        <div
          v-for="(creditCard, index) in getCreditCardList"
          :key="index"
          class="payments-methods__card"
        >
          <img
            :src="require(`@/assets/images/logo/master-card.png`)"
            alt="icon visa"
          />
          <div class="payments-methods__card__details">
            <strong class="pb-3"
              >Terminado en {{ creditCard.number.substr(-4) }}</strong
            >
            <p class="pt-3">Expiración</p>
            <p>{{ creditCard.expired_date }}</p>
            <br />
            <p>Nombre</p>
            <p>{{ creditCard.card_holder_name }}</p>
          </div>
          <div class="payments-methods__card__details">
            <div v-if="creditCard.primary">
              <i class="fas fa-check"></i
              ><strong class="pb-3">Pago predeterminado</strong>
            </div>

            <p class="pt-3">Código postal</p>
            <p>
              {{ creditCard.postal_code }}
            </p>
          </div>
          <div class="payments-methods__card__options">
            <div class="settings">
              <span class="float-right">
                <md-menu md-size="auto" :md-offset-x="-180" :md-offset-y="5">
                  <md-button md-menu-trigger>•••</md-button>

                  <md-menu-content>
                    <md-menu-item @click="openCreditCardDialog(creditCard)">
                      Editar
                    </md-menu-item>
                    <md-menu-item @click="selectedPrimary(creditCard)">
                      Hacer pago predeterminado
                    </md-menu-item>
                    <md-menu-item @click="deleteCreditCard(creditCard)">
                      Eliminar
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <loading-panel style="z-index: 6; height: 50vh; margin-right: 0" />
    </div>
    <create-credit-card-dialog @success="refetchCreditCardList" />
  </div>
</template>

<script>
import LoadingPanel from '../../../../../components/LoadingPanel';
import { mapGetters } from 'vuex';
import {
  ADD_PRIMARY_CARD,
  DELETE_CREDIT_CARD,
  GET_CREDIT_CARD_LIST,
  OPEN_CREDIT_CARD_DIALOG,
} from '../../../../../store/actions/card';
import CreateCreditCardDialog from '../../../Home/Layouts/components/CreateCreditCardDialog';

export default {
  name: 'CreditCartList',
  components: {
    LoadingPanel,
    CreateCreditCardDialog,
  },
  data() {
    return {
      creditCardList: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['getCreditCardList']),
  },
  mounted() {
    this.fetchCreditCardList();
  },

  methods: {
    selectedPrimary(creditCard) {
      console.log('creditCard', creditCard);
      this.notify('warning', 'Estamos procesando su solicitud');
      this.$store
        .dispatch(ADD_PRIMARY_CARD, creditCard)
        .then(() => {
          this.notify(
            'success',
            `La tajeta ${creditCard.number.substr(
              -4,
            )} ha sido seleccionada como predeterminada`,
          );
          this.refetchCreditCardList();
        })
        .catch((error) => {
          this.notify('danger', error.message);
        });
    },
    deleteCreditCard(creditCard) {
      this.$store
        .dispatch(DELETE_CREDIT_CARD, creditCard)
        .then(() => {
          this.notify(
            'success',
            `La tajeta ${creditCard.number.substr(-4)} ha sido eliminada`,
          );
          this.refetchCreditCardList();
        })
        .catch((error) => {
          this.notify('danger', error.message);
        });
    },
    refetchCreditCardList() {
      this.isLoading = true;
      this.fetchCreditCardList();
    },
    fetchCreditCardList() {
      this.$store
        .dispatch(GET_CREDIT_CARD_LIST)
        .then(() => (this.isLoading = false));
    },
    openCreditCardDialog(creditCard) {
      this.$store.dispatch(OPEN_CREDIT_CARD_DIALOG, creditCard);
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style>
.alert-warning {
  background-color: #ffcb33 !important;
  color: black !important;
  border-color: #ffcb33 !important;
}

.alert-warning span {
  color: black !important;
}
</style>
