var render = function render(){var _vm=this,_c=_vm._self._c;return _c('skeleton-loading',[_c('div',{staticClass:"border-card p-3 mb-3"},[_c('skeleton-square',{attrs:{"box-properties":{
          height: '160px',
        },"count":1}}),_c('skeleton-square',{attrs:{"box-properties":{
          height: '16px',
          top:'10px'
        },"count":2}}),_c('div',{staticClass:"d-flex mt-2 skeleton-course-container"},[_c('div',[_c('skeleton-square',{attrs:{"box-properties":{
          height: '16px',
          width: '70px',
        },"count":1}})],1),_c('div',[_c('skeleton-square',{attrs:{"box-properties":{
          height: '16px',
          width: '70px',
        },"count":1}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }