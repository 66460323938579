import { render, staticRenderFns } from "./CreateProductDialog.vue?vue&type=template&id=4af81dfe&scoped=true"
import script from "./CreateProductDialog.vue?vue&type=script&lang=js"
export * from "./CreateProductDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af81dfe",
  null
  
)

export default component.exports