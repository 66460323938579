<template>
  <div class='highlight-body showClasses-rut'>
    <div class='page-container md-layout-column'>
      <div>
        <div
          class='view-home__part5 h-100 show-courses__body show-classes_video'
        >
          <div class='container-fluid size'>
            <div class='row justify-content-between'>
              <div class='col-lg-8 col-md-12 col-12 p-0'>
                <course-video
                  :data='getCurrentCourseVideoUser'
                  style='background-size: cover; !important'
                  @current-time-video='currentTimeVideo'
                />
              </div>
              <div v-if='isLoading' class='col-lg-4 col-md-12 col-12 d-flex'>
                <loading-panel
                  style='
                    width: 100%;
                    background: transparent !important;
                    height: 100% !important;
                  '
                />
              </div>
              <div
                v-else
                class='col-lg-4 col-md-12 col-12 col-refactoring-boxs show-courses__info-instructor px-0 card-instructor-info'
              >
                <div
                  class='row show-courses__info-descrip-instructor mx-1 hide-in-mobile'
                >
                  <div class='col-lg-3 col-md-12 col-12 px-0'>
                    <div class='p-2 justify-content-center text-center'>
                      <div
                        class='d-flex align-items-center justify-content-center'
                      >
                        <img
                          :src="
                            getInstructorAvatar(getCurrentCourseUser)
                              ? getInstructorAvatar(getCurrentCourseUser)
                              : '@/assets/images/logo/placeholder.png'
                          "
                          alt='Card image cap'
                          class='img-fluid rounded-circle object-fit-cover'
                          style='width: 70px; height: 70px; object-fit: cover'
                        />
                      </div>
                    </div>
                  </div>
                  <div class='col-lg-9 col-sm-12 pl-2 pr-2'>
                    <div class='card-body'>
                      <h5
                        class='d-flex text-left text-uppercase show-courses__text pl-0'
                        style='font-weight: 700'
                      >
                        {{ getCurrentCourseUser.name }}
                      </h5>

                      <div class='d-flex flex-row' style='max-width: 300px'>
                        <div class='row'>
                          <div class='col-12'>
                            <h5
                              v-if='
                                getCurrentCourseUser &&
                                getCurrentCourseUser.instructors
                              '
                              class='text-uppercase show-courses__text text-left'
                              style='font-size: 12px'
                            >
                              Por
                              <span
                                v-for='(
                                  instructor, index
                                ) in getCurrentCourseUser.instructors'
                                :key='index'
                              >
                                <router-link
                                  :to='`/instructores/instructor/${
                                    instructor.instructor.id
                                  }/${getInstructorSlug(
                                    instructor.instructor,
                                  )}`'
                                  style='text-decoration: none'
                                >
                                  {{ instructor.instructor.firstname }}
                                  {{ instructor.instructor.lastname }}
                                </router-link>
                                <span
                                  v-if='
                                    index !==
                                    getCurrentCourseUser.instructors.length - 1
                                  '
                                >,
                                </span>
                              </span>
                            </h5>
                          </div>

                          <div class='col-12'>
                            <h5
                              class='text-uppercase show-courses__text text-left'
                              style='font-size: 12px'
                            >
                              <span
                                v-for='(
                                  category, index
                                ) in getCurrentCourseUser.course_categories'
                                :key='index'
                              >
                                <router-link
                                  :to='`/explorar/categoria/${category.course_category.slug}`'
                                  style='text-decoration: none'
                                >
                                  {{ category.course_category.title }}
                                </router-link>
                                <span
                                  v-if='
                                    index !==
                                    getCurrentCourseUser.course_categories
                                      .length -
                                      1
                                  '
                                >,
                                </span>
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='padding-videos'>
                  <div class='show-courses__videos my-2'>
                    <badger-accordion
                      ref='badgerAccordion'
                      :icons="{
                        opened: `<i class='fas fa-angle-up'></i>`,
                        closed: `<i class='fas fa-angle-down'></i>`,
                      }"
                      class='accordion course-container__nav__chapter-collapse pt-4'
                    >
                      <div
                        v-if='curseVideos.length'
                        class='mb-4'>
                        <router-link

                          v-for='(
                            video, index
                          ) in curseVideos'
                          :key='index'
                          :to='`/curso/${getCurrentCourseUser.id}/${getCurrentCourseUser.slug_name}/video/${video.id}`'
                        >
                          <div
                            :id='index'
                            class='card'
                            style='cursor: pointer; background: black'
                          >
                            <div class='row'>
                              <div
                                class='col-mobile-img col-5 col-md-mobile col-md-5 pr-0'
                                style='background: #000'
                              >
                                <div style='border-radius: 6px'>
                                  <img
                                    :src='
                                      video.video?.thumbnail ??
                                      getCurrentCourseUser.thumbnail_url
                                    '
                                    alt='Card image cap'
                                    class='img-see-course'
                                    style='
                                      padding: 0;
                                      object-fit: cover;
                                      max-height: 90px;
                                      width: 100%;
                                    '
                                  />

                                  <div
                                    class='card-img-overlay timeLesson d-flex justify-content-between'
                                  >
                                    <div
                                      :id="'video-' + index"
                                      class='details-play'
                                    ></div>
                                    <a
                                      :id="'idVideo-' + video.id"
                                      class='btn duration-style btn-sm text-right'
                                    >{{ formatTime(video) }}</a
                                    >
                                  </div>
                                  <div class='progress progress-show-courses'>
                                    <div
                                      :aria-valuenow='getCourseVideoCurrentTimePercent(
                                          video
                                        )'
                                      :style='{
                                        width: `${getCourseVideoCurrentTimePercent(
                                          video
                                        )}%`,
                                      }'
                                      aria-valuemax='100'
                                      aria-valuemin='0'
                                      class='progress-bar'
                                      role='progressbar'
                                    ></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class='col-mobile-title col-7 col-md-7 show-courses__info-videos'
                              >
                                <h5
                                  class='text-left show-courses__text text-mobile-descript'
                                >
                                  {{ video.title }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </badger-accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='pt-5 show-courses__mobile-container text-description-class'>
          <div class='container-fluid size'>
            <skeleton-course-video-details v-if='isLoadingCourseVideo' />

            <course-video-details
              v-else
              :course='getCurrentCourseUser'
              :data='getCurrentCourseVideoUser'
              @change-course-user-favorite-success='refetchCourseVideo'
            />

            <div
              class='row show-courses__info-descrip-instructor mx-1 hide-in-desktop'
            >
              <div class='col-lg-3 col-md-12 col-12 px-0'>
                <div class='p-2 justify-content-center text-center'>
                  <div class='d-flex align-items-center justify-content-center'>
                    <img
                      :src="
                        getInstructorAvatar(getCurrentCourseUser)
                          ? getInstructorAvatar(getCurrentCourseUser)
                          : '@/assets/images/logo/placeholder.png'
                      "
                      alt='Card image cap'
                      class='img-fluid rounded-circle object-fit-cover'
                      style='width: 70px; height: 70px; object-fit: cover'
                    />
                  </div>
                </div>
              </div>
              <div class='col-lg-9 col-sm-12 pl-2 pr-2'>
                <div class='card-body'>
                  <h5
                    class='d-flex text-left text-uppercase show-courses__text pl-0'
                    style='font-weight: 700'
                  >
                    {{ getCurrentCourseUser.name }}
                  </h5>

                  <div class='d-flex flex-row' style='max-width: 300px'>
                    <div class='row'>
                      <div class='col-12'>
                        <h5
                          v-if='
                            getCurrentCourseUser &&
                            getCurrentCourseUser.instructors
                          '
                          class='text-uppercase show-courses__text text-left'
                          style='font-size: 12px'
                        >
                          Por
                          <span
                            v-for='(
                              instructor, index
                            ) in getCurrentCourseUser.instructors'
                            :key='index'
                          >
                            <router-link
                              :to='`/instructores/instructor/${
                                instructor.instructor.id
                              }/${getInstructorSlug(instructor.instructor)}`'
                              style='text-decoration: none'
                            >
                              {{ instructor.instructor.firstname }}
                              {{ instructor.instructor.lastname }}
                            </router-link>
                            <span
                              v-if='
                                index !==
                                getCurrentCourseUser.instructors.length - 1
                              '
                            >,
                            </span>
                          </span>
                        </h5>
                      </div>

                      <div class='col-12'>
                        <h5
                          class='text-uppercase show-courses__text text-left'
                          style='font-size: 12px'
                        >
                          <span
                            v-for='(
                              category, index
                            ) in getCurrentCourseUser.course_categories'
                            :key='index'
                          >
                            <router-link
                              :to='`/explorar/categoria/${category.course_category.slug}`'
                              style='text-decoration: none'
                            >
                              {{ category.course_category.title }}
                            </router-link>
                            <span
                              v-if='
                                index !==
                                getCurrentCourseUser.course_categories.length -
                                  1
                              '
                            >,
                            </span>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'format-duration';
import CourseVideoDetails from './Courses/components/CourseVideoDetails';
import LoadingPanel from '../../components/LoadingPanel';
import { mapGetters } from 'vuex';
import SkeletonCourseVideoDetails from '../Components/skeletons/SkeletonCourseVideoDetails';
import CourseVideo from './Courses/components/CourseVideo';
import 'video.js/dist/video-js.css';
import { BadgerAccordion } from 'vue-badger-accordion';
import SocketIO from '../../socket/SocketIO';
import { FETCH_COURSE_USER, FETCH_COURSE_VIDEO_USER } from '../../store/actions/course';

export default {
  name: 'ShowCourse',
  components: {
    SkeletonCourseVideoDetails,
    LoadingPanel,
    CourseVideoDetails,
    CourseVideo,
    BadgerAccordion,
  },
  metaInfo () {
    console.log('metaInfo', this.getCurrentCourseVideoUser);
    const seoTitle = this.getCurrentCourseVideoUser && this.getCurrentCourseVideoUser.seo ? `${this.getCurrentCourseVideoUser.seo.title || ''}` : undefined;
    const seoDescription = this.getCurrentCourseVideoUser && this.getCurrentCourseVideoUser.seo ? `${this.getCurrentCourseVideoUser.seo.description || ''}` : undefined;
    return {
      title: seoTitle ?? 'Maquillate.com',
      meta: [{
        name: 'description',
        content: seoDescription ?? 'Maquillate.com'
      }],
    };
  },
  data () {
    return {
      isLoadingCourseVideo: true,
      isLoading: true,
      isError: null,
      currentTime: 0,
      currentTimePercent: 0,
      curseVideos: [],
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'getProfile',
      'getCurrentCourseUser',
      'getCurrentCourseVideoUser',
    ]),
  },
  watch: {
    getCurrentCourseUser: function (newValue) {
      if (newValue) {
        this.curseVideos = newValue.course_videos;
      }
    },
    $route: async function (to) {
      const { id, course_video_id } = to.params;
      await Promise.all([
        this.fetchCourse(id),
        this.fetchCourseVideo(course_video_id),
      ]);
    },
  },
  async created () {
    await this.fetchCourse(this.$route.params.id);
    await this.fetchCourseVideo(this.$route.params.course_video_id);
  },
  async mounted () {
    await this.fetchCourse(this.$route.params.id);
    await this.fetchCourseVideo(this.$route.params.course_video_id);
  },
  beforeUnmount () {
    SocketIO.disconnect();
  },
  methods: {
    async refetchCourseVideo () {
      await this.fetchCourseVideo(this.$route.params.course_video_id);
    },
    formatTime (video) {
      const duration = video.video ? video.video.duration : 0;
      return format(duration * 1000);
    },
    getCourseDuration (courseVideos = []) {
      const duration = courseVideos.reduce((acc, curr) => {
        const _duration = curr.video ? parseInt(curr.video.duration) : 0;
        return acc + _duration;
      }, 0);

      return format(duration * 1000);
    },
    async fetchCourse (id) {
      await this.$store.dispatch(FETCH_COURSE_USER, {
        course_id: id,
        user_id: this.getProfile.id,
      });
      this.isLoading = false;
    },
    async fetchCourseVideo (id) {
      await this.$store.dispatch(FETCH_COURSE_VIDEO_USER, {
        course_video_id: id,
        user_id: this.getProfile.id,
      });
      this.isLoadingCourseVideo = false;
    },

    getCourseVideoCurrentTimePercent (courseVideo) {
      if (!(courseVideo && courseVideo.course_video_user && courseVideo.course_video_user.course_video_current_time)) return 0;

      if (
        courseVideo.course_video_user.course_video_current_time >
        parseInt(courseVideo.video.duration)
      )
        return 0;

      const courseVideoCurrentTime =
        courseVideo.course_video_user.course_video_current_time;
      return (
        (courseVideoCurrentTime * 100) /
        parseInt(courseVideo.video.duration)
      ).toFixed(2);
    },
    currentTimeVideo (currentTime) {
      if (currentTime && this.curseVideos) {
        const newCourseVideos = this.curseVideos;
        const courseVideo = newCourseVideos.find((video) => video.id === parseInt(this.$route.params.course_video_id));
        if (courseVideo) {
          courseVideo.course_video_user.course_video_current_time = currentTime;
          this.curseVideos = newCourseVideos;
        }
      }

    },
    getInstructorAvatar (courseUser) {
      if (
        courseUser &&
        courseUser.instructors &&
        courseUser.instructors.length
      ) {
        return courseUser.instructors[0].instructor &&
        courseUser.instructors[0].instructor.avatar
          ? courseUser.instructors[0].instructor.avatar.url
          : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
      }
      return 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg';
    },
    getInstructorSlug (instructor) {
      const fullname = `${instructor.firstname ?? ''} ${
        instructor.lastname ?? ''
      }`;

      return fullname.toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
a:not(.md-button):hover {
  text-decoration: none;
}

.card-body {
  padding: 0.5rem;
}

.show-courses__info-descrip-instructor {
  border-radius: 8px !important;
}

.md-theme-default a:not(.md-button) {
  color: #fff;
}

.show-courses__videos {
  height: 100%;
}

.vjs-poster {
  background-size: 100px !important;
}

.hide-in-desktop {
  display: none;
}

.view-home__part5.show-courses__body {
  padding-top: 75px !important;
  background-color: #000 !important;
  padding-bottom: 10px !important;
}

@media screen and (max-width: 992px) {
  .hide-in-mobile {
    display: none;
  }

  .hide-in-desktop {
    display: block;
  }

  .show-courses__img-instructor img {
    width: 90px !important;
    margin-top: 12px;
    margin-bottom: 10px;
  }

  .show-classes_video {
    padding: 60px 0px 0px 0px !important;
  }
}
</style>
