<template>
  <div class="row">
    <div class="col-8 mb-2 text-left px-3">
      <h2 class="title-course-detail">
        {{ data.title }}
      </h2>
    </div>

    <div class="col-4 mb-2 text-right share-mobile-center">
      <h2 class="show-courses__share-fav">
        <template v-if="data && data.id">
          <span>
            <i :class="{
              'fas fa-heart': isFavorite,
              'far fa-heart': !isFavorite,
            }" class="pr-3 fav-icon-course" @click="addCourseFavorite()"></i>
          </span>
        </template>
        <span class="sharebutton" @click="openShareCourseVideoDialog()">
          <i class="fas fa-share"></i><span class="pl-2 share hide-in-mobile">COMPARTIR</span>
        </span>
      </h2>
    </div>

    <div class="col-12 mb-2 text-left px-3">
      <p class="px-0 pt-2 text-left" v-html="data.content"></p>
    </div>

    <div class="col-12 row container-buttons-see-course">
      <div class="col-sm-12 mx-auto mx-lg-0 text-lg-left pl-lg-0 text-center">
        <a v-for="file in data.files" :key="file.id" :href="file.url" style="text-decoration: none" target="_blank">
          <button v-if="data.files.length"
            class="btn show-courses__btn-products text-white mt-2 mb-3 font-button-product">
            <i class="far fa-arrow-alt-circle-down"></i>
            DESCARGAR PDF
          </button>
        </a>
      </div>

      <div class="col-sm-12 mx-auto mx-lg-0 text-lg-left pl-lg-0 text-center">
        <button v-if="data.products.length"
          class="btn show-courses__btn-products text-white mt-2 mb-3 font-button-product"
          @click="showProducts = !showProducts">
          <i class="fas fa-shopping-cart"></i> PRODUCTOS DESTACADOS
        </button>
      </div>
    </div>
    <div v-if="showProducts" class="col-12 mt-4 cards-instructors mb-2">
      <carousel :navigation-enabled="true" :per-page="1" :per-page-custom="[
        [545, 2],
        [825, 3],
        [1200, 4],
      ]" :scroll-per-page="true" :speed="500" navigation-next-label="<i class='fas fa-chevron-right'></i>"
        navigation-prev-label="<i class='fas fa-chevron-left'></i>">
        <slide v-for="product in products" :key="products.id" class="d-flex flex-column flex-card-display"
          style="flex-basis: inherit">
          <card-product-course class="card-class-width mx-1 card-h-w" :data="product" />
        </slide>
      </carousel>
    </div>
    <share-course-video-dialog :course="course" :is-open="isOpenShareCourseDialog"
      @close-modal="closeShareCourseVideoDialog" />
  </div>
</template>

<script>
import ShareCourseVideoDialog from './ShareCourseVideoDialog';
import {
  mapGetters
} from 'vuex';
import {
  Carousel,
  Slide
} from 'vue-carousel';
import {
  CHANGE_COURSE_FAVORITE
} from '../../../../store/actions/course';
import CardProductCourse from './CardProductCourse.vue';
export default {
  name: 'CourseVideoDetails',
  components: {
    Carousel,
    Slide,
    ShareCourseVideoDialog,
    CardProductCourse
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          title: '',
          content: '',
          files: [],
          products: [],
        };
      },
    },
    course: {
      type: Object,
      default: () => {
        return {
          thumbnail_url: '',
          id: '',
          name: '',
          slug_name: '',
        };
      },
    },
  },
  emits: ['change-course-user-favorite-success'],
  data() {
    return {
      showProducts: false,
      isOpenShareCourseDialog: false,
      isFavorite: 0,
    };
  },
  computed: {
    ...mapGetters(['getProfile']),
    products() {
      const { products } = this.data;
      return products.map(({ product }) => product);

    }
  },
  watch: {
    data: function (newValue) {
      if (newValue) this.isFavorite = newValue.isFavorite;
    },
  },
  methods: {
    handleShowProducts() {
      this.showProducts = !this.showProducts;
    },
    openShareCourseVideoDialog() {
      this.isOpenShareCourseDialog = true;
    },
    closeShareCourseVideoDialog() {
      this.isOpenShareCourseDialog = false;
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    addCourseFavorite() {
      console.log(this.isFavorite);
      this.$store
        .dispatch(CHANGE_COURSE_FAVORITE, {
          id: this.data.courseVideoUser.id,
          user_id: this.data.courseVideoUser.user_id,
          is_favorite: !this.isFavorite,
        })
        .then(() => {
          this.notify(
            'success',
            `${this.isFavorite
              ? 'El video se elimino de tus favoritos'
              : 'El video se agrego a tus favoritos'
            }`,
            (this.isFavorite = !this.isFavorite),
          );
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para agregar a favoritos'),
        );
    },

    getInstructorSlug(instructor) {
      const fullname = `${instructor.firstname ?? ''} ${instructor.lastname ?? ''
        }`;

      return fullname.toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style>
.title-course-detail {
  text-transform: uppercase !important;
  color: #b7227e;
}

.view-home__part5 p {
  padding: 0px !important;
}

.show-classes__body {
  background-color: #000 !important;
}

.show-courses__mobile-container {
  background-color: #fff !important;
}

a:hover {
  text-decoration: none !important;
}

.far.fa-arrow-alt-circle-down {
  font-size: 12px !important;
}

.font-button-product,
.fas.fa-shopping-cart {
  font-size: 12px !important;
}

.sharebutton i {
  font-size: 14px !important;
}

.far.fa-heart {
  font-size: 14px;
  cursor: pointer;
}

.show-courses__videos {
  height: 300px !important;
  overflow: auto;
}

.fav-icon-course {
  font-size: 14px !important;
  cursor: pointer;
}

.show-courses__img-products {
  border: none !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 0px !important;
  box-shadow: none !important;
}

.size-img-product {
  height: 200px !important;
  width: 100%;
  max-width: 250px !important;
  max-height: 200px !important;
  border: none !important;
  border-radius: 10px 10px 0px 0px !important;
  margin-top: 0px !important;
}

.card-product {
  height: 280px !important;
  width: 250px !important;
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 280px !important;
  margin-bottom: 0px;
  margin-right: 0px;
  max-width: 250px !important;
  min-width: 250px !important;
  max-height: 280px !important;
  min-height: 280px !important;
}

.product-title-text {
  max-width: 250px !important;
  display: relative;
}

.button-price-circle-product {
  background-color: #b7227e !important;
  width: 35px;
  min-width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: none !important;
}

.button-price-product-text {
  border-radius: 50%;
  color: #fff !important;
  padding: 0px;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01rem;
  border: none;
  padding-bottom: 1px;
}

@media screen and (max-width: 576px) {


  .card-h-w-product {
    width: 100%;
    padding-right: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .hide-in-mobile {
    display: none;
  }

  .w-product-badge {
    max-width: 100% !important;
    width: 100%;
  }
}

.card-border-product {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.button-price-circle-product {
  background-color: #b7227e !important;
  width: 35px;
  min-width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: none !important;
  margin-right: 10px;
}

.w-product-badge {
  max-width: 300px !important;
  width: 100%;
}

.button-price-product-text {
  border-radius: 50%;
  color: #fff !important;
  padding: 0px;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01rem;
  border: none;
  padding-bottom: 1px;
}

.product-title-text {
  max-width: 250px !important;
  display: relative;
}

.size-img-product {
  height: 200px;
  width: auto;
  border: none !important;
  border-radius: 10px 10px 0px 0px !important;
  margin-top: 0px !important;
}

.show-courses__img-products {
  border: none !important;
  border-radius: 10px 10px 10px 10px !important;
  margin-top: 0px !important;
}

.img-shadow-none {
  box-shadow: none !important;
}
</style>
